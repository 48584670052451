import React, { FC } from 'react'
import { INotification } from '@/types/notifiaction'
import { ErrorSnack, SuccessSnack } from './style'
import Info from '@/components/Icons/Info'
import { motion as m } from 'framer-motion'
import variants from './animation'
import { Text } from '@chakra-ui/react'

const SnackNotification: FC<INotification> = ({ message, type, id }) => {
  const animationProps = {
    variants: variants.item,
    initial: 'hidden',
    exit: 'exit',
    animate: 'visible',
  }

  switch (type) {
    case 'success':
      return (
        <SuccessSnack key={id} {...animationProps} as={m.div}>
          {message}
        </SuccessSnack>
      )
    case 'error':
      return (
        <ErrorSnack key={id} {...animationProps} as={m.div}>
          <Info />
          <Text maxWidth="90vw" textStyle="text4">
            {message}
          </Text>
        </ErrorSnack>
      )
    default:
      return null
  }
}

export default SnackNotification

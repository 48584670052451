import { ReactNode } from 'react'
import { ApiResponse } from '@/services/api'

export enum EnumOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type ITablePaginationMeta = Partial<TableStateSetter> & TableStateGetter

export type TableStateSetter = {
  orderBy: string
  order: EnumOrder
  currentPage: number
  perPage: number
}

export type TableStateGetter = {
  currentPage: number
  totalPages: number
  perPage: number
  count: number
}

export type TableFilterDate = {
  createdDateFrom: string
  createdDateTo: string
  updatedDateFrom: string
  updatedDateTo: string
}

export type TablePaginationMetaProps = ITablePaginationMeta & {
  setMeta: (meta: Partial<ITablePaginationMeta>) => void
}
// export type TablePaginationMetaProps = ITablePaginationMeta & {
//     setMeta: (meta: Partial<ITablePaginationMeta>) => void
// }

export type IPaginationDataResult<T> = {
  meta: TableStateGetter
  data: T
}

export type RequestFn<T, P> = (
  params: Partial<P & TableStateSetter>,
) => Promise<ApiResponse<IPaginationDataResult<T[]>>>

export interface TableMenuItems {
  label: string
  icon?: ReactNode
  onClick: () => void
  isHidden?: boolean
}

import { generatePath } from 'react-router-dom'
import { urlRootPath } from './urlPath'
import { Role } from '@/api/generated/qubyApiJava'

export type InAppContextType = 'ROOT' | 'CLIENT'

export abstract class NavIRoot {
  PATH = '/'
}

/**
 * Ветвь или лист дерева навигации
 * --
 * Общее для всех листов\веток поведение.
 *
 * Все они имеют родительскую ветвь и корень дерева.
 *
 */
export abstract class NavIBranchLeaf<PT = Record<string, string>> {
  /**
   * Корень дерева
   * --
   */
  root: NavIRoot

  abstract PATH_PART: string
  onlyForRoles?: Role | Role[]
  onlyForContext?: InAppContextType | InAppContextType[]

  #check = (k: 'onlyForRoles' | 'onlyForContext', v: string) => {
    if (!this[k] || !this[k]?.length) {
      return true
    }
    return this[k] && this[k]?.includes(v as never)
  }

  checkRole = (role: Role) => {
    return this.#check('onlyForRoles', role)
  }
  checkContext = (context: InAppContextType) => {
    return this.#check('onlyForContext', context)
  }

  constructor(public parent: NavIBranchLeaf | NavIRoot) {
    if (parent instanceof NavIBranchLeaf) {
      this.root = parent.root
    } else {
      this.root = parent
    }
  }

  /**
   * Путь роута, годный для Route компонента.
   *
   * Например:
   * `/agreements/:agreementId/act/:actId`
   */
  get PATH(): string {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return urlRootPath(this.parent.PATH, this.PATH_PART)
  }

  /**
   * Сгенерировать путь для ссылки
   * --
   *
   * Подставит в ссылку данные из параметров и например сделает из `{ agreementId: 1, actId: 2 }`
   * и пути роута `/agreements/:agreementId/act/:actId` путь для ссылки `/agreements/1/act/2`
   *
   * Для каждого листа в функцию прокидывается тип пропсов маршрута.
   *
   */
  getPathname = (props: PT): string =>
    generatePath(this.PATH, props as Record<string, string>)
}

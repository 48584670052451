import { tableAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { colors } from './colors'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tableAnatomy.keys)

const baseStyle = definePartsStyle({
  table: {},
  thead: {},
  tbody: {},
  th: {
    p: '3',
    fontSize: '12px',
    lineHeight: '16px',
    border: `1px solid ${colors.neutral[400]}`,
  },
  td: {
    p: '4',
    fontSize: '12px',
    lineHeight: '16px',
    border: `1px solid ${colors.neutral[400]}`,
  },
})
const simpleLined = definePartsStyle({
  table: {},
  thead: {},
  tbody: {},
  tr: {
    '&:last-child td': {
      border: 'none',
    },
  },
  th: {
    p: '3',
    fontSize: '12px',
    lineHeight: '16px',
    border: `1px solid ${colors.neutral[400]}`,
  },
  td: {
    p: '4',
    fontSize: '14px',
    lineHeight: '16px',
    border: 'none',
    borderBottom: `1px solid ${colors.neutral[400]}`,
    '&:last-child': {
      paddingRight: 0,
      textAlign: 'right',
    },
  },
})
export const tableTheme = defineMultiStyleConfig({
  baseStyle,
  variants: {
    simpleLined,
  },
})

// _focus: {
//   color: 'red',
// },

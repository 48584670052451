import React, { Suspense } from 'react'
import { matchPath, Route, Routes, useLocation } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'
import { FullWidthSpinner } from '@/components/FullWidthSpiner'
import ErrorBoundary from '@/components/ErrorBoundary/ErrorBoundary'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { Box, Text } from '@chakra-ui/react'
import ContentHeader from '@/components/Header/ContentHeader'
import {
  PageLayoutLazy,
  ProtectedRouteLazy,
  RedirectAuthComponentLazy,
} from '@/routes/lazy'
import { RUN_REQUESTS } from '@/constants/api'
import i18n from 'i18next'
import { useAppUserContext } from '@/_app/useAppUserContext'
import { mainPagesMotionAnimationSettings } from '@/_app/mainPagesMotionAnimationSettings'
import { publicRoutes } from './publicRoutes'
import { privateRoutes } from './privateRoutes'

const Router = () => {
  const { isSimpleUser } = useAppUserContext()
  const location = useLocation()

  const [t] = useTranslation()
  const title =
    i18n.exists(`header.title.${location.pathname}`) &&
    t(`header.title.${location.pathname}`)
  const fixedTitle =
    isSimpleUser && location.pathname === RUN_REQUESTS ?
      'Заявки на сбор'
    : title

  const merged = [...publicRoutes, ...privateRoutes]
  const found = merged.find(({ path }) => {
    if (!path) return false
    return matchPath(path, location.pathname)
  })
  return (
    <>
      {title && title !== `header.title.${location.pathname}` && (
        <Helmet>
          <title>{fixedTitle}</title>
        </Helmet>
      )}
      <ErrorBoundary>
        <AnimatePresence mode="popLayout">
          <Suspense
            fallback={
              <motion.div
                style={{ height: '100vh' }}
                {...mainPagesMotionAnimationSettings}
                key={'LoadingPageContainer'}
              >
                <FullWidthSpinner label={'Загрузка раздела…'} />
              </motion.div>
            }
          >
            <Routes location={location} key={found?.path || 'unfoundedRoute'}>
              {/* Public */}
              {publicRoutes.map(({ element, ...props }, i) => (
                <Route
                  key={`public-page-${i}`}
                  {...props}
                  element={
                    <PageLayoutLazy
                      key={'GuestLayoutContainer'}
                      layout="public"
                    >
                      {element}
                    </PageLayoutLazy>
                  }
                />
              ))}

              {/* Private */}
              <Route element={<ProtectedRouteLazy />}>
                {privateRoutes.map(({ element, ...props }, i) => (
                  <Route
                    key={`private-page-${i}`}
                    {...props}
                    element={
                      <PageLayoutLazy key={'UserLayoutContainer'} layout="user">
                        {element}
                      </PageLayoutLazy>
                    }
                  />
                ))}
                <Route
                  key={'i404'}
                  path={'*'}
                  element={
                    <motion.div {...mainPagesMotionAnimationSettings}>
                      <PageLayoutLazy layout="user">
                        <ContentHeader>
                          <Text fontSize={'2xl'}>Страница не найдена</Text>
                        </ContentHeader>
                        <Box mx={12} my={24}>
                          <p>
                            Адрес не найден. Возможно он изменился либо набран с
                            ошибкой.
                          </p>
                          <p>
                            Вы можете исправить адрес либо воспользоваться меню.
                          </p>
                          <p>&nbsp;</p>
                          <p>
                            Если адрес набран правильно, но все равно что-то
                            идёт не так, вы можете{' '}
                            <a
                              className={'link'}
                              href={'https://t.me/+rvpi4L1G6hs1YzQy'}
                              target={'_blank'}
                              rel="noreferrer"
                            >
                              связаться с командой разработки
                            </a>
                            .
                          </p>
                        </Box>
                      </PageLayoutLazy>
                    </motion.div>
                  }
                />
              </Route>
              <Route path="/" element={<RedirectAuthComponentLazy />} />
            </Routes>
          </Suspense>
        </AnimatePresence>
      </ErrorBoundary>
    </>
  )
}

export default observer(Router)

import { emptySplitDlvApi as api } from '../emptyApi'
export const addTagTypes = ['shipment-request-controller-v-2'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getEntities3: build.query<GetEntities3ApiResponse, GetEntities3ApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/shipments`,
          params: {
            page: queryArg.page,
            size: queryArg.size,
            sort: queryArg.sort,
            filter: queryArg.filter,
          },
        }),
        providesTags: ['shipment-request-controller-v-2'],
      }),
    }),
    overrideExisting: false,
  })
export { injectedRtkApi as qubyApiDlv }
export type GetEntities3ApiResponse =
  /** status 200 OK */ PageResultShipmentRequest
export type GetEntities3ApiArg = {
  page?: number
  size?: number
  sort?: string
  filter?: string
}
export type DlvError = {
  code?:
    | 'WRONG_JSON_FORMAT'
    | 'WRONG_PARAMETER'
    | 'NO_SERVICE'
    | 'UNKNOWN_ERROR'
    | 'DUPLICATE'
    | 'PERMISSION_DENIED'
    | 'PICK_UP_TIME'
    | 'AUTHENTICATION_ERROR'
    | 'COURIER_ERROR'
    | 'TIMEOUT'
    | 'NOT_FOUND'
    | 'BUSINESS_ERROR'
  message?: string
}
export type Pagination = {
  totalElements?: number
  currentPage?: number
  totalPages?: number
}
export type ShipmentRequestSync = {
  id?: string
  createTs?: string
  updateTs?: string
  num?: string
  courierNum?: string
  tempCourierNum?: string
  postalApiId?: string
  parcelNum?: string
  returningCourierNum?: string
  syncState?:
    | 'ACCEPTED'
    | 'UPDATING'
    | 'CANCELING'
    | 'ABORTED'
    | 'UPDATING_SUSPENDED'
  syncMessage?: string
  cancelReason?: string
  status?:
    | 'SENDER'
    | 'ACCEPTED_BY_COURIER'
    | 'COURIER'
    | 'SORT'
    | 'COURIER2'
    | 'PROBLEM'
    | 'DELIVERED'
    | 'RETURNED'
    | 'AT_TAKEOUT_POINT'
    | 'RETURNING'
    | 'LOST'
    | 'PARTIALLY_DELIVERED'
    | 'CANCELED'
  cancellationStatus?: 'ERROR' | 'IN_PROCESS' | 'CANCELLED' | 'NOT_CANCELLED'
  cancellationStatusTs?: string
  cancellationStateError?: string
  currentCity?: string
  lastRequestTs?: string
  registeredTs?: string
  docReturn?: boolean
  statusDate?: string
  dlvLogin?: string
  actualDeliveryDate?: string
  courierData?: string
  registerActId?: string
  registeredActTs?: string
  commitTry?: boolean
  idAsString?: string
}
export type Customer = {
  name?: string
  company?: string
  phone?: string
  phone2?: string
  email?: string
  inn?: string
  warehouse?: string
  firstName?: string
  lastName?: string
  middleName?: string
}
export type Receiver = {
  name?: string
  company?: string
  phone?: string
  phone2?: string
  email?: string
  inn?: string
  warehouse?: string
  documentSeries?: string
  documentNumber?: string
  documentDate?: string
  firstName?: string
  lastName?: string
  middleName?: string
}
export type Address = {
  zipcode?: string
  country?: string
  region?: string
  city?: string
  area?: string
  settlement?: string
  street?: string
  house?: string
  block?: string
  building?: string
  flat?: string
  replacement?: string
  fiasCode?: string
  kladrCode?: string
  cityFiasCode?: string
  regionFiasCode?: string
  areaFiasCode?: string
  settlementFiasCode?: string
}
export type SupplierInfo = {
  supplierINN?: string
  supplierPhone?: string
}
export type YandexParams = {
  warehouseFromId?: string
  requisiteId?: string
  senderId?: string
  intervalId?: string
  deliveryName?: string
  direction?: string
  tariffId?: string
  pickuppoint?: string
  ydSystem?: string
  deliveryUniqueName?: string
  cargoIntervalId?: string
}
export type CodParams = {
  name?: string
  accountNumber?: string
  bik?: string
  correspondAccount?: string
  inn?: string
  kpp?: string
  bankName?: string
}
export type Dimension = {
  weight?: number
  width?: number
  height?: number
  depth?: number
}
export type CustomData = {
  paramName: string
  paramValue: string
}
export type SubPiece = {
  id?: string
  createTs?: string
  updateTs?: string
  description?: string
  internationalDescription?: string
  price?: number
  quantity?: number
  vatSum?: number
  vatType?:
    | 'VAT18'
    | 'VAT20'
    | 'VAT10'
    | 'VAT_CALC_20_120'
    | 'VAT_CALC_18_118'
    | 'VAT_CALC_10_110'
    | 'VAT0'
    | 'NO_VAT'
    | 'VATX'
  tnvedCode?: string
  vendorCode?: string
  marketPlaceCode?: string
  received?: boolean
  receivedQuantity?: number
  barcode?: string
  boxNeeded?: boolean
  countryOfOrigin?: string
  weight?: number
  marketplaceSkuId?: string
  controlIdMarks?: string
  controlIdMarksMask?: string
  jwUin?: string
  customData?: CustomData[]
  index?: number
  priceNoVat?: number
  summaryPrice?: number
  summaryPriceNoVat?: number
  vatPrice?: number
  skuCode?: string
  summaryVatPrice?: number
  externalId?: string
}
export type Place = {
  id?: string
  description?: string
  barcode?: string
  fboBarcode?: string
  dimension?: Dimension
  index?: string
  giveToCourierDate?: string
  stickerValue?: string
  stickerPdf?: string[]
  stickerZpl?: string
  litePacked?: boolean
  externalId?: string
  lpNumber?: string
  solutionCode?: string
  subPieces?: SubPiece[]
}
export type Pallet = {
  id?: string
  description?: string
  barcode?: string
  fboBarcode?: string
  dimension?: Dimension
  index?: string
  giveToCourierDate?: string
  stickerValue?: string
  stickerPdf?: string[]
  stickerZpl?: string
  placeList?: Place[]
}
export type ServiceParam = {
  courierServices?:
    | 'TRYING_PRICE'
    | 'PARTIAL_DELIVERY_PRICE'
    | 'CHECK_CONTENT_PRICE'
    | 'PERSONALLY_IN_HANDS_PRICE'
    | 'VERIFY_PRICE'
    | 'REVERSE_PRICE'
    | 'DAY_OFF_DELIVERY_PRICE'
    | 'LIFT_TO_DOOR_PRICE'
    | 'LIFT_TO_DOOR_MANUAL_PRICE'
    | 'BOX_NEEDED_PRICE'
    | 'TRYING'
    | 'PARTIAL_DELIVERY'
    | 'CHECK_CONTENT'
    | 'PERSONALLY_IN_HANDS'
    | 'VERIFY'
    | 'REVERSE'
    | 'DAY_OFF_DELIVERY'
    | 'LIFT_TO_DOOR'
    | 'LIFT_TO_DOOR_MANUAL'
    | 'BOX_NEEDED'
    | 'WAITING_ON_ADDRESS'
    | 'TEMPERATURE_REGIME'
    | 'DELIVERY_DATE_APPROVE'
    | 'CONTACTLESS_DELIVERY'
    | 'DANGEROUS_CARGO'
  courierServiceCode?: string
}
export type MegamarketDataDto = {
  merchantName?: string
  merchantId?: string
  warehouseId?: string
  deliveryId?: string
  fullData?: boolean
}
export type ShipmentRequest = {
  id?: string
  syncRecord?: ShipmentRequestSync
  sender?: Customer
  receiver?: Receiver
  senderAddress?: Address
  receiverAddress?: Address
  supplierInfo?: SupplierInfo
  yandexParams?: YandexParams
  cashOnDeliveryType?: 'CASH' | 'CARD' | 'CASH_AND_CARD' | 'NONE'
  description?: string
  comment?: string
  docReturn?: boolean
  byHand?: boolean
  deliveryVATType?:
    | 'VAT18'
    | 'VAT20'
    | 'VAT10'
    | 'VAT_CALC_20_120'
    | 'VAT_CALC_18_118'
    | 'VAT_CALC_10_110'
    | 'VAT0'
    | 'NO_VAT'
    | 'VATX'
  deliveryType?: 'FAST' | 'CHEAP' | 'CONTRACT' | 'COURIER'
  startPickUp?: string
  endPickUp?: string
  factShipmentDate?: string
  estimatedDeliveryDate?: string
  insuranceSum?: number
  deliveryPrice?: number
  cashOnDelivery?: number
  freeDeliveryFromSum?: number
  baseTariffPrice?: number
  baseTariffDiscountPrice?: number
  contractId?: string
  courierCode?:
    | 'PickPoint'
    | 'SPSR'
    | 'CityExpress'
    | 'IML'
    | 'Hermes'
    | 'CDEK'
    | 'CDEKV2'
    | 'Grastin'
    | 'Boxberry'
    | 'GD'
    | 'DPD'
    | 'DPD_LATVIA'
    | 'MaxiPost'
    | 'PickUp'
    | 'PonyExpress'
    | 'YandexDelivery'
    | 'YandexDeliveryV2'
    | 'CSE'
    | 'DOSTAVISTA'
    | 'PEK'
    | 'RussianPost'
    | 'TEMPOLINE_RUSSIAN_POST'
    | 'STRIZH'
    | 'SelfPickup'
    | 'Logsis'
    | 'A1'
    | 'RussianPostApiEcom'
    | 'OZON'
    | 'OZON_DELIVERY'
    | 'DalliService'
    | 'PbmLatvia'
    | 'RussianPostApi'
    | 'Goods'
    | 'Dellin'
    | 'Novaposhta'
    | 'Kak2cCourier'
    | 'UPS_Latvia'
    | 'Wildberries'
    | 'X5_Post'
    | 'Kaspi'
    | 'DHL'
    | 'Cainiao'
    | 'YandexDeliveryV3'
    | 'LeroyMerlin'
    | 'Lamoda'
    | 'Kholodilnik'
    | 'YANDEX_MARKET'
    | 'QUBY'
    | 'PODRYGKA_CDEK'
    | 'PODRYGKA_CSE'
    | 'EBULKY'
    | 'HALVA'
    | 'HALVAV2'
    | 'BAIKAL'
  courierCode2?:
    | 'PickPoint'
    | 'SPSR'
    | 'CityExpress'
    | 'IML'
    | 'Hermes'
    | 'CDEK'
    | 'CDEKV2'
    | 'Grastin'
    | 'Boxberry'
    | 'GD'
    | 'DPD'
    | 'DPD_LATVIA'
    | 'MaxiPost'
    | 'PickUp'
    | 'PonyExpress'
    | 'YandexDelivery'
    | 'YandexDeliveryV2'
    | 'CSE'
    | 'DOSTAVISTA'
    | 'PEK'
    | 'RussianPost'
    | 'TEMPOLINE_RUSSIAN_POST'
    | 'STRIZH'
    | 'SelfPickup'
    | 'Logsis'
    | 'A1'
    | 'RussianPostApiEcom'
    | 'OZON'
    | 'OZON_DELIVERY'
    | 'DalliService'
    | 'PbmLatvia'
    | 'RussianPostApi'
    | 'Goods'
    | 'Dellin'
    | 'Novaposhta'
    | 'Kak2cCourier'
    | 'UPS_Latvia'
    | 'Wildberries'
    | 'X5_Post'
    | 'Kaspi'
    | 'DHL'
    | 'Cainiao'
    | 'YandexDeliveryV3'
    | 'LeroyMerlin'
    | 'Lamoda'
    | 'Kholodilnik'
    | 'YANDEX_MARKET'
    | 'QUBY'
    | 'PODRYGKA_CDEK'
    | 'PODRYGKA_CSE'
    | 'EBULKY'
    | 'HALVA'
    | 'HALVAV2'
    | 'BAIKAL'
  litePacked?: boolean
  courierProduct?: string
  courierProductChangeHistory?: string
  postamatNum?: string
  postamatNumChangeHistory?: string
  codParams?: CodParams
  registerNum?: string
  additionalInfo?: string
  places?: Place[]
  pallets?: Pallet[]
  serviceParams?: ServiceParam[]
  planDeliveryDate?: string
  planDeliveryDateChangeHistory?: string
  planDeliveryTimeStart?: string
  planDeliveryTimeEnd?: string
  planDeliveryDateChangeReason?: string
  cargoPickUpDate?: string
  primaryContractId?: string
  liteShipment?: 'LITE_SHIPMENT_1' | 'LITE_SHIPMENT_2'
  prevShipment?: string
  payerType?: 'SENDER' | 'RECEIVER'
  floor?: number
  customData?: {
    [key: string]: string
  }
  hourOfDeliveryTimeStart?: string
  hourOfDeliveryTimeEnd?: string
  paymentType?: 'CASH' | 'CARD' | 'CASH_AND_CARD' | 'NONE'
  receiverAll?: Receiver
  megamarketData?: MegamarketDataDto
  servicesWithPrices?: {
    [key: string]: number
  }
  placesDimension?: Dimension[]
}
export type PageResultShipmentRequest = {
  success?: boolean
  errors?: DlvError[]
  pagination?: Pagination
  list?: ShipmentRequest[]
}

import React from 'react'

import { AuthStore } from './auth.store'
import { NotificationsStore } from './notifications.store'
import { AppStore } from './app.store'
import { autorun } from 'mobx'
import { PartnerStore } from './partners.store'
import { LocalStorageStore } from './shered/localStorageStore'

export const notificationsStore = new NotificationsStore()
export const authStore = new AuthStore()
export const partnerStore = new PartnerStore()
export const appStore = new AppStore()
export const localStorageStore = new LocalStorageStore()

autorun(() => {
  const { partnerId, isAuth } = authStore

  if (isAuth) {
    if (partnerId) {
      partnerStore.getPartner(partnerId).then()
    }
  }
})

const StoreContext = React.createContext({
  authStore,
  notificationsStore,
  appStore,
  partnerStore,
})

export const useGlobalStores = () => React.useContext(StoreContext)

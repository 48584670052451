export const colors = {
  neutral: {
    100: '#FFFFFF',
    200: '#FCFCFD',
    300: '#F2F4F7',
    400: '#EAECF0',
    500: '#98A2B3',
    600: '#667085',
    700: '#475467',
    800: '#344054',
    900: '#1D2939',
    1000: '#000000',
  },
  accent: {
    100: '#F8FCFF',
    200: '#EAF6FF',
    300: '#BFE5FF',
    400: '#7AC8FF',
    500: '#51AAE8',
    600: '#1188D9',
    700: '#076CB1',
    800: '#075183',
    900: '#003D66',
    1000: '#05304E',
  },
  success: {
    100: '#F6FEF9',
    200: '#ECFDF3',
    300: '#D1FADF',
    400: '#A6F4C5',
    500: '#6CE9A6',
    600: '#32D583',
    700: '#12B76A',
    800: '#039855',
    900: '#05603A',
    1000: '#054F31',
  },
  attention: {
    100: '#FFFCF5',
    200: '#FFFAEB',
    300: '#FEEFC6',
    400: '#FEDF89',
    500: '#FEC84B',
    600: '#FDB022',
    700: '#F79009',
    800: '#DC6903',
    900: '#B54708',
    1000: '#93370D',
  },
  error: {
    100: '#FFFAFA',
    200: '#FEF3F2',
    300: '#FEE4E2',
    400: '#FECDC9',
    500: '#FDA19B',
    600: '#F97066',
    700: '#F04438',
    800: '#D92D20',
    900: '#B32318',
    1000: '#912018',
  },
}

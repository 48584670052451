import { ILocalStorage } from '@/types/local-storage'
import { action, makeObservable, observable, runInAction } from 'mobx'
import {
  EnumOrder,
  ITablePaginationMeta,
  RequestFn,
  TableStateSetter,
} from '@/types/table'
import errorHandler from '../../utils/errorHandler'
import { localStorageStore } from '../global.store'
import { AxiosError } from 'axios'

const initialPaginationMeta: ITablePaginationMeta = {
  order: EnumOrder.ASC,
  // orderBy: 'UPDATED_DATE',
  // numPage: 0,
  currentPage: 1,
  perPage: 100,
  totalPages: 0,
  count: 0,
}

type TableStoreCtor<T, TFilter> = {
  entity?: keyof ILocalStorage
  initialMeta?: Partial<TableStateSetter>
  initialFilter?: TFilter | Record<string, unknown>
  initialData?: T | []
  getDataRequest: RequestFn<T, TFilter>
}

export class TableStore<T, TFilter extends Record<string, unknown>> {
  @observable meta: ITablePaginationMeta = { ...initialPaginationMeta }
  @observable metaSetter: Partial<TableStateSetter> = {
    perPage: initialPaginationMeta.perPage,
    currentPage: initialPaginationMeta.currentPage,
  }

  @observable filter: TFilter | Record<string, unknown> = {}
  @observable data: T[] = []
  @observable search = ''

  @observable loading = true
  entity?: keyof ILocalStorage
  getDataRequest: RequestFn<T, TFilter>
  constructor({
    entity,
    getDataRequest,
    initialMeta = {},
    initialFilter = {},
  }: TableStoreCtor<T, TFilter>) {
    this.getDataRequest = getDataRequest
    this.metaSetter = {
      ...this.metaSetter,
      ...initialMeta,
    }
    this.filter = {
      ...this.filter,
      ...initialFilter,
    }
    this.entity = entity

    makeObservable(this)
  }

  @action refetch = async (loader = true) => {
    return this.getData(undefined, loader)
  }

  @action getData = async (
    params?: Partial<TableStateSetter & TFilter>,
    loader = true,
  ) => {
    runInAction(() => {
      this.loading = loader
    })

    const _params: Partial<TableStateSetter & TFilter> =
      params ?
        {
          ...this.metaSetter,
          ...this.filter,
          ...(this.search === '' ? {} : { query: this.search }),
          ...params,
        }
      : ({
          ...this.metaSetter,
          ...this.filter,
          ...(this.search === '' ? {} : { query: this.search }),
        } as Partial<TableStateSetter & TFilter>)
    try {
      const { data } = await this.getDataRequest(_params)

      if (!data) return []
      runInAction(() => {
        const { meta } = data
        this.data = [...data.data]
        if (meta) {
          this.meta = {
            orderBy: this.metaSetter.orderBy,
            ...meta,
            order: this.metaSetter.order || initialPaginationMeta.order,
          }
          this.metaSetter = {
            currentPage: meta.currentPage > 0 ? meta.currentPage : 1,
            perPage:
              [5, 20, 50, 100, 500, 1000].includes(meta.perPage) ?
                meta.perPage
              : initialPaginationMeta.perPage,
            orderBy: this.metaSetter.orderBy,
            order: this.metaSetter.order,
          }
        }

        this.loading = false
      })

      return data.data
    } catch (error) {
      errorHandler(error as AxiosError)
      runInAction(() => {
        this.loading = false
      })
    }
  }

  @action setMeta = (meta: Partial<TableStateSetter>, isLoadData = true) => {
    runInAction(() => {
      this.metaSetter = {
        ...this.metaSetter,
        ...meta,
      }
      if (isLoadData) {
        this.getData()
      }
    })
  }

  @action setFilter = (filter: TFilter) => {
    if (this.entity) {
      const isEmpty = Object.keys(filter).length === 0
      localStorageStore.setDateToLocalStorage(
        this.entity,
        'filter',
        isEmpty ? null : filter,
      )
    }
    runInAction(() => {
      this.filter = filter
    })
    this.getData()
  }

  @action setSearch = (serchStr: string) => {
    runInAction(() => {
      this.search = serchStr
      this.getData()
    })
  }

  @action updateDataWithoutLoader = async (
    filter?: Partial<TableStateSetter & TFilter>,
  ) => {
    this.getData(filter, false)
  }
}

import { AxiosInstance, AxiosRequestConfig, AxiosStatic } from 'axios'
import qs from 'qs'
import { ApiDownload } from './download'
import { LegacyApi } from '@/services/api/legacyApi'

class Api {
  download = new ApiDownload(this)
  axios: AxiosInstance

  legacy = new LegacyApi(this)

  constructor(staticAxios: AxiosStatic, axiosConfig: AxiosRequestConfig) {
    this.axios = staticAxios.create({
      ...axiosConfig,
      paramsSerializer: {
        serialize: (params) => {
          return qs.stringify(params, {
            indices: false,
            arrayFormat: 'repeat',
          })
        },
      },
    })
    this.axios.interceptors.response.use(
      (response) => {
        return response
      },
      (err) => {
        if (err.response?.status === 403) {
          console.error(err)
        }
        return Promise.reject(err)
      },
    )
  }

  deleteAuthToken = () => {
    delete this.axios.defaults.headers.Authorization
  }

  setAuthToken = (token: string) => {
    if (token) {
      this.axios.defaults.headers.Authorization = `Bearer ${token}`
    } else {
      this.deleteAuthToken()
    }
  }
}

export default Api

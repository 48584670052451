import { ILocalStorage } from '@/types/local-storage'
import { action, observable, runInAction } from 'mobx'

export class LocalStorageStore {
  @observable localStorage: ILocalStorage = {
    shipments: {
      filter: undefined,
    },
    runRequests: {
      filter: undefined,
    },
  }

  constructor() {
    this.getDataFormLocalStorage().then()
  }

  @action getDataFormLocalStorage = async () => {
    const items = Object.entries(this.localStorage).map(
      ([entity, _fieldes]) => {
        const fields = Object.entries(_fieldes).map(([field]) => {
          const item = localStorage.getItem(`${entity}.${field}`)

          return [field, item ? JSON.parse(item) : undefined]
        })
        return [entity, Object.fromEntries(fields)]
      },
    )
    runInAction(() => {
      this.localStorage = Object.fromEntries(items)
    })
  }

  @action setDateToLocalStorage = async (
    entity: keyof ILocalStorage,
    field: keyof ILocalStorage[keyof ILocalStorage],
    data:
      | ILocalStorage[keyof ILocalStorage][keyof ILocalStorage[keyof ILocalStorage]]
      | null,
  ) => {
    if (data) {
      localStorage.setItem(`${entity}.${field}`, JSON.stringify(data))
    } else {
      localStorage.removeItem(`${entity}.${field}`)
    }
  }
}

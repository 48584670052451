export const DEFAULT_POLLING_INTERVAL = 6 * 60 * 1000

// user
export const REGISTER = '/register' as const
export const RESET_PASSWORD = '/restore-password' as const
export const SET_PASSWORD = '/set-password' as const

// shipments
export const SHIPMENTS = '/shipments' as const
export const SHIPMENT_SINGLE = '/shipment' as const
export const SHIPMENT = `${SHIPMENT_SINGLE}/:id` as const
export const SHIPMENT_UPLOAD_FILE_MULTIPLE_CREATION = `${SHIPMENTS}/upload-file-multiple-creation`

// receivers
export const RECEIVERS = '/receivers'
export const RECEIVER = '/receiver'
export const RECEIVER_SINGLE = `${RECEIVER}/:id`

// warehouses
export const WAREHOUSES = '/warehouses'

// goods
export const GOODS = '/goods'

export const DELIVERY_SERVICES = '/delivery-services'

// partner info (ex. settings)
export const PARTNERS = '/partners'
export const PARTNER = '/partner'

//  marketplace warehouses
export const MARKETPLACE_WAREHOUSES = '/marketplace-warehouses'

// Quby warehouses
export const QUBY_WAREHOUSES = '/quby-warehouses'

// dadata
export const DADATA_ADDRESS = '/dadata/suggest/address'

// attachments
export const ATTACHMENTS = '/attachments/:model'

// run request
export const RUN_REQUESTS = '/run-requests' as const
export const RUN_REQUEST_SINGLE = '/run-request' as const
export const RUN_REQUEST = '/run-request/:id' as const
export const RUN_REQUESTS_DELIVERY_LIST = `${RUN_REQUESTS}/export/delivery-list`

import { extendTheme } from '@chakra-ui/react'
import { buttonTheme } from './buttonTheme'
import { checkboxTheme } from './checkboxTheme'
import { colors } from './colors'
import { inputTheme } from './inputsTheme'
import { numberInputsTheme } from './numberInputsTheme'
import { radioTheme } from './radioTheme'
import { tableTheme } from './table'
import { textStyles } from './textStyles'
import { tooltipTheme } from './tooltipTheme'
import { linkTheme } from '@/theme/link'

const theme = extendTheme({
  colors,
  textStyles,
  fonts: {
    body: "'Inter', sans-serif",
  },
  components: {
    Link: linkTheme,
    Button: buttonTheme,
    Input: inputTheme,
    NumberInput: numberInputsTheme,
    Checkbox: checkboxTheme,
    Table: tableTheme,
    Tooltip: tooltipTheme,
    Radio: radioTheme,
  },
})

export default theme

import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { AppUserContext } from '@/_app/AppUser.context'
import {
  qubyApiJava,
  Role,
  UserLoginRequest,
} from '@/api/generated/qubyApiJava'
import api from '@/services/api'
import errorHandler from '@/utils/errorHandler'
import { ApiTokensInUserContextType } from '@/_app/AppUser.types'
import { FullWidthSpinner } from '@/components/FullWidthSpiner'
import { parseJwt } from '@/utils/auth'
import {
  appUserDoRedirectToFromPage,
  appUserDoRedirectToLogin,
} from '@/_app/appUserDoRedirect'
import { AbsoluteCenter } from '@chakra-ui/react'
import { RTKQUseQueryResult } from '@/types/rtkq.types'

function keep(...keeps: unknown[]) {
  // eslint-disable-next-line no-void
  void keeps
}

const sessionRefreshTimeSeconds = 300
const refetchTimeoutMs = 5000

const TOKENS_STORAGE_NAME = 'apiTokens' as const
const TOKENS_UPDATE_IN_PROGRESS_NAME = 'apiTokensUpdateInProgress' as const

const checkTokenIsValid = (token: string | undefined) => {
  if (token) {
    const parsedToken = parseJwt(token)
    return Boolean(
      parsedToken.exp && parsedToken.exp > Math.floor(Date.now() / 1000),
    )
  }
  return false
}

const checkIsExpiredLaterThenAfterSeconds = (
  token: string | undefined,
  seconds: number,
) => {
  if (token) {
    const parsedToken = parseJwt(token)
    return Boolean(
      parsedToken.exp &&
        parsedToken.exp > Math.floor(Date.now() / 1000 + seconds),
    )
  }
  return false
}

const useQueryRefetch = (options: {
  query: RTKQUseQueryResult<unknown, unknown>
  removeTokens: () => void
  refetchTimeoutMs: number
}): { attemptsCount: number } => {
  const { query, removeTokens, refetchTimeoutMs } = options
  const queryError = 'error' in query && query.error
  const currentRefetchRef = useRef(false)
  const refetchTimeoutRef = useRef<NodeJS.Timeout>()
  const [attemptsCount, setAttemptsCount] = useState(1)
  useEffect(() => {
    keep(attemptsCount)
    if (queryError) {
      if (queryError && 'response' in queryError) {
        if (
          queryError.response?.status &&
          queryError.response?.status === 403
        ) {
          removeTokens()
          setTimeout(() => {
            appUserDoRedirectToLogin()
          }, 108)
        }
      }
      if (!currentRefetchRef.current) {
        clearTimeout(refetchTimeoutRef.current)
        currentRefetchRef.current = true
        refetchTimeoutRef.current = setTimeout(() => {
          query?.refetch()?.finally(() => {
            currentRefetchRef.current = false
            setAttemptsCount((s) => s + 1)
          })
        }, refetchTimeoutMs)
      }
    }
    return () => {
      currentRefetchRef.current = false
      clearTimeout(refetchTimeoutRef.current)
    }
  }, [queryError, query, removeTokens, refetchTimeoutMs, attemptsCount])

  return {
    attemptsCount,
  }
}

export const AppUserProvider: FC<PropsWithChildren> = ({ children }) => {
  const [tokens, setTokensState] = useState<ApiTokensInUserContextType>(() => {
    const storageValue = localStorage.getItem(TOKENS_STORAGE_NAME)
    if (storageValue) {
      try {
        const parsed = JSON.parse(storageValue) as ApiTokensInUserContextType
        if (checkTokenIsValid(parsed.refreshToken)) {
          if (parsed.accessToken) {
            api.setAuthToken(parsed.accessToken)
          }
          return parsed
        }
      } catch (error) {
        console.error(error)
      }
    }
    return {}
  })
  const { refreshToken, accessToken } = tokens
  const loginMutation = qubyApiJava.endpoints.login.useMutation()
  const [loginMutationTrigger, loginMutationMeta] = loginMutation
  const [refreshLoginMutation, refreshLoginMeta] =
    qubyApiJava.endpoints.getAccessTokenByRefreshToken.useMutation()
  const meQuery = qubyApiJava.endpoints.getInfoAboutMe.useQuery(undefined, {
    skip: !checkTokenIsValid(accessToken),
  })

  const isUserHaveValidToken =
    checkTokenIsValid(refreshToken) || checkTokenIsValid(accessToken)

  const setTokens = useCallback((tokensData: ApiTokensInUserContextType) => {
    if (tokensData.accessToken) {
      api.setAuthToken(tokensData.accessToken)
      setTokensState(tokensData)
      const stringifyData = JSON.stringify(tokensData)
      if (stringifyData !== localStorage.getItem(TOKENS_STORAGE_NAME)) {
        localStorage.setItem(TOKENS_STORAGE_NAME, stringifyData)
      }
    } else {
      api.deleteAuthToken()
      localStorage.removeItem(TOKENS_STORAGE_NAME)
      setTokensState({})
    }
  }, [])

  const removeTokens = useCallback(() => {
    setTokens({})
  }, [setTokens])

  const onStoreChanged = useCallback(
    ({ newValue, key }: StorageEvent) => {
      if (key === TOKENS_STORAGE_NAME) {
        try {
          setTokens(newValue ? JSON.parse(newValue) : {})
        } catch (error) {
          console.log('Tokens Error', error)
        }
      }
      if (key === TOKENS_UPDATE_IN_PROGRESS_NAME) {
        refreshInProgressRef.current = !newValue
      }
    },
    [setTokens],
  )

  useEffect(() => {
    window.addEventListener('storage', onStoreChanged)
    return () => {
      window.removeEventListener('storage', onStoreChanged)
    }
  }, [onStoreChanged])

  const currentRefreshTokensRefetchRef = useRef(false)
  const refetchRefreshTokensTimeoutRef = useRef<NodeJS.Timeout>()
  const refreshInProgressRef = useRef(false)
  const [refreshTokensAttemptsCount, setRefreshTokensAttemptsCount] =
    useState(0)
  const refresh = useCallback(async () => {
    if (
      !refreshInProgressRef.current &&
      refreshToken &&
      checkTokenIsValid(refreshToken) &&
      (!checkTokenIsValid(accessToken) ||
        !checkIsExpiredLaterThenAfterSeconds(
          accessToken,
          sessionRefreshTimeSeconds,
        ))
    ) {
      refreshInProgressRef.current = true
      localStorage.setItem(TOKENS_UPDATE_IN_PROGRESS_NAME, '1')
      const result = await refreshLoginMutation({
        refreshTokenRequest: { refreshToken },
      })
      if ('error' in result) {
        errorHandler(result.error)
        if (result.error && 'response' in result.error) {
          if (
            result.error.response?.status === 401 ||
            result.error.response?.status === 403
          ) {
            removeTokens()
            setTimeout(() => {
              appUserDoRedirectToLogin()
            }, 108)
          } else {
            if (!currentRefreshTokensRefetchRef.current) {
              keep(refreshTokensAttemptsCount)
              clearTimeout(refetchRefreshTokensTimeoutRef.current)
              currentRefreshTokensRefetchRef.current = true
              refetchRefreshTokensTimeoutRef.current = setTimeout(() => {
                refresh().finally(() => {
                  currentRefreshTokensRefetchRef.current = false
                  setRefreshTokensAttemptsCount((s) => s + 1)
                })
              }, refetchTimeoutMs)
            }
          }
        }
      } else {
        setTokens(result.data)
      }
      refreshInProgressRef.current = false
      localStorage.removeItem(TOKENS_UPDATE_IN_PROGRESS_NAME)
    }
  }, [
    setRefreshTokensAttemptsCount,
    accessToken,
    refreshLoginMutation,
    refreshToken,
    removeTokens,
    setTokens,
    refreshTokensAttemptsCount,
  ])
  useEffect(() => {
    const interval = setInterval(
      refresh,
      (10 + Math.floor(Math.random() * 20)) * 1000,
    )
    refresh().then()
    return () => {
      clearInterval(interval)
    }
  }, [refresh])

  const login = useCallback(
    async (userLoginRequest: UserLoginRequest) => {
      const result = await loginMutationTrigger({ userLoginRequest })
      if ('error' in result) {
        errorHandler(result.error)
      } else {
        setTokens(result.data)
        setTimeout(() => {
          appUserDoRedirectToFromPage()
        }, 108)
      }
    },
    [loginMutationTrigger, setTokens],
  )

  const roles = meQuery.data?.roles
  const hasRole = useCallback(
    (role: Role) => {
      if (!roles) {
        return false
      }
      return roles.includes(role)
    },
    [roles],
  )
  const hasAnyOfRoles = useCallback(
    (...rolesForFound: Role[]): boolean => {
      if (!roles || !roles?.length || !rolesForFound || !rolesForFound.length) {
        return false
      }
      return rolesForFound.some((role) => roles.includes(role))
    },
    [roles],
  )
  const hasAllOfRoles = useCallback(
    (...rolesForFound: Role[]): boolean => {
      if (!roles || !roles?.length || !rolesForFound || !rolesForFound.length) {
        return false
      }
      return rolesForFound.every((role) => roles.includes(role))
    },
    [roles],
  )

  const [logoutMutation] = qubyApiJava.endpoints.logout.useMutation()

  const logout = useCallback(async () => {
    if (refreshToken) {
      const result = await logoutMutation()
      if ('error' in result) {
        errorHandler(result.error)
      }
    }
    removeTokens()
    setTimeout(() => {
      appUserDoRedirectToLogin()
    }, 108)
  }, [logoutMutation, refreshToken, removeTokens])

  const { attemptsCount: meAttemptsCount } = useQueryRefetch({
    query: meQuery,
    refetchTimeoutMs,
    removeTokens,
  })

  const isLoggedIn = Boolean(meQuery.data?.id)
  const isQubyEmployee =
    hasRole('QUBY_MANAGER') ||
    hasRole('QUBY_ADMIN') ||
    hasRole('QUBY_DISPATCHER')
  const isQubyEmployeeStatictics =
    hasRole('QUBY_MANAGER') || hasRole('QUBY_ADMIN')
  const isQubyAdmin = hasRole('QUBY_ADMIN')
  const isSupport = hasRole('QUBY_SUPPORT')

  const isSimpleUser = isLoggedIn && hasRole('USER') && !isQubyEmployee

  if (!refreshLoginMeta.isLoading) {
    if (meQuery.error && !meQuery.data) {
      const errorInResponse =
        meQuery.error && 'response' in meQuery.error ?
          meQuery.error.response
        : null
      return (
        <div style={{ height: '100vh' }}>
          <AbsoluteCenter>
            Ошибка получения профиля с API. <br />
            {errorInResponse?.status || null} |{' '}
            {meQuery?.error?.message || null}
            <br />
            {meQuery.isLoading ? '[идёт новая попытка загрузки…]' : null}
            <br />
            количество попыток: {meAttemptsCount}
          </AbsoluteCenter>
        </div>
      )
    }
  }

  if (isUserHaveValidToken) {
    if (meQuery.isLoading || !meQuery.isSuccess) {
      return (
        <div style={{ height: '100vh' }}>
          <FullWidthSpinner label={'Загрузка профиля пользователя…'} />
        </div>
      )
    }

    if (!meQuery.isSuccess && refreshLoginMeta.isLoading) {
      return (
        <div style={{ height: '100vh' }}>
          <FullWidthSpinner label={'Обновление токенов доступа…'} />
        </div>
      )
    }
  }

  if (loginMutationMeta.isLoading) {
    return (
      <div style={{ height: '100vh' }}>
        <FullWidthSpinner label={'Запрос на вход…'} />
      </div>
    )
  }

  if (loginMutationMeta.isSuccess) {
    return (
      <div style={{ height: '100vh' }}>
        <FullWidthSpinner label={'Вход удался'} />
      </div>
    )
  }

  if (
    !meQuery.isSuccess &&
    !checkTokenIsValid(accessToken) &&
    checkTokenIsValid(refreshToken)
  ) {
    return (
      <div style={{ height: '100vh' }}>
        <FullWidthSpinner
          label={
            refreshLoginMeta.isLoading ?
              'Обновление токенов доступа…'
            : 'Загрузка…'
          }
        />
      </div>
    )
  }

  return (
    <AppUserContext.Provider
      value={{
        ...(meQuery.data ? meQuery.data : {}),
        isGuest: !isLoggedIn,
        isLoggedIn,
        login,
        isLoginInProgress: loginMutationMeta.isLoading,
        hasRole,
        hasAllOfRoles,
        hasAnyOfRoles,
        isQubyEmployee,
        isSimpleUser,
        isQubyAdmin,
        isQubyEmployeeStatictics,
        isSupport,
        logout,
      }}
    >
      {children}
    </AppUserContext.Provider>
  )
}

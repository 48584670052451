const conf = {
  snackbar: {
    visible: {},
    hidden: {},
  },
  item: {
    visible: {
      y: 0,
      opacity: 1,
    },
    exit: {
      x: '-100%',
      opacity: 0,
    },
    hidden: {
      y: 100,
      opacity: 0,
    },
  },
}

export default conf

import { Flex, FlexProps, Spinner } from '@chakra-ui/react'
import React, { FC, ReactNode } from 'react'

export const FullWidthSpinner: FC<FlexProps & { label?: ReactNode }> = (
  props,
) => {
  const { label } = props
  return (
    <Flex
      direction="column"
      width="100%"
      justifyContent="center"
      alignItems="center"
      height="100%"
      {...props}
    >
      <div>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </div>
      {label ?
        <div>{label}</div>
      : null}
    </Flex>
  )
}

import { inputAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'
import { colors } from './colors'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys)

const baseStyle = definePartsStyle({
  field: {
    transition: '0.2s ease',
    color: colors.neutral['900'],
    display: 'block',
  },
})

const light = definePartsStyle({
  field: {
    border: '1px solid var(--400, rgba(152, 162, 179, 0.30))',
    background: 'var(--100, #FFF)',
  },
})

const outline = definePartsStyle({
  field: {
    border: `1px solid ${colors.neutral['400']}`,
    _placeholder: {
      color: colors.neutral['500'],
    },
    _hover: {
      border: `1px solid ${colors.accent['600']}`,
    },
  },
})

const error = definePartsStyle({
  field: {
    border: `2px solid ${colors.error['600']}`,
  },
})

const disabled = definePartsStyle({
  field: {
    border: `1px solid ${colors.neutral['500']}`,
  },
})

const md = defineStyle({
  py: '2',
  px: '3',
  fontSize: '14px',
  height: '32px',
  lineHeight: '16px',
})

const sizes = {
  md: definePartsStyle({
    field: md,
    addon: md,
  }),
}

export const inputTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants: {
    outline,
    error,
    disabled,
    light,
  },
})

export const inputThemeProps = {
  baseStyle,
  sizes,
  variants: {
    outline,
    error,
    disabled,
    light,
  },
}

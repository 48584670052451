import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQueryJava } from '@/api/axiosBaseQueryJava'
import api from '@/services/api'
import { axiosBaseQueryPhp } from '@/api/axiosBaseQueryPhp'
import { axiosBaseQueryDlv } from './axiosBaseQueryDlv'

export const emptySplitPhpApi = createApi({
  baseQuery: axiosBaseQueryPhp(api),
  endpoints: () => ({}),
  invalidationBehavior: 'delayed',
  reducerPath: 'apiPhp',
})

export const emptySplitJavaApi = createApi({
  baseQuery: axiosBaseQueryJava(api),
  endpoints: () => ({}),
  reducerPath: 'apiJava',
})
export const emptySplitDlvApi = createApi({
  baseQuery: axiosBaseQueryDlv(api),
  endpoints: () => ({}),
  reducerPath: 'apiDlv',
})

import { Provider } from 'react-redux'
import { PropsWithChildren } from 'react'
import { qubyApiPhp } from '@/api/generated/qubyApiPhp'
import { qubyApiJava } from '@/api/generated/qubyApiJava'
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { qubyApiDlv } from './api/generated/qubyApiDlv'

const store = configureStore({
  reducer: combineReducers({
    // Тут я добавляю свой редюсер видимо с названием апи
    [qubyApiPhp.reducerPath]: qubyApiPhp.reducer,
    [qubyApiJava.reducerPath]: qubyApiJava.reducer,
    [qubyApiDlv.reducerPath]: qubyApiDlv.reducer,
  }),
  middleware: (getDefaultMiddleware) => {
    const result = getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    })

    return result.concat(
      qubyApiPhp.middleware,
      qubyApiJava.middleware,
      qubyApiDlv.middleware,
    )
  },
})

export const AppApiProvider = ({ children }: PropsWithChildren) => {
  return <Provider store={store}>{children}</Provider>
}

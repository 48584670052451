/**
 * Parse JWT token and return object with expiration date and etc.
 *
 * @param {string} token
 *
 */
export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  return JSON.parse(window.atob(base64))
}

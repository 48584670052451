import { Alert, AlertIcon, Box, Button, Flex, Text } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { AppUpdateContext } from '@/_app/AppUpdate.context'

export const AppUpdateIndicator = () => {
  const { showReload, isUpdateAvailable, reloadPage } =
    useContext(AppUpdateContext)
  return (
    <div>
      <AnimatePresence>
        {(showReload || isUpdateAvailable) && (
          <motion.div
            style={{ overflow: 'hidden' }}
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            transition={{ duration: 0.5 }}
            exit={{ height: 0 }}
            key={'AppUpdateContainer'}
          >
            <Box>
              <Alert status="warning">
                <AlertIcon />
                <Flex
                  align={'center'}
                  width={'100%'}
                  justify={'space-between'}
                  paddingRight={'16px'}
                >
                  <div>
                    {showReload ?
                      <Text>
                        Приложение обновилось. Нужно обновить страницу, чтобы
                        изменения вступили в силу.
                      </Text>
                    : <Text>
                        Приложение обновляется. Не закрывайте и не
                        перезагружайте страницу.
                      </Text>
                    }
                  </div>
                  <div>
                    <Button
                      disabled={!showReload}
                      isLoading={!showReload && isUpdateAvailable}
                      colorScheme={'orange'}
                      onClick={reloadPage}
                    >
                      Обновить страницу
                    </Button>
                  </div>
                </Flex>
              </Alert>
            </Box>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

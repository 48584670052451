import { action, makeObservable, observable, runInAction } from 'mobx'
import { IPartner, IPartnerTableFilter } from '@/types/partner'
import api from '../services/api'
import errorHandler from '../utils/errorHandler'
import { TableStore } from './shered/table.store'
import { AxiosError } from 'axios'

export class PartnerStore {
  @observable partner: IPartner | undefined
  @observable partnerTable: TableStore<IPartner, IPartnerTableFilter>

  constructor() {
    this.partnerTable = new TableStore({
      getDataRequest: (params) => api.legacy.getPartnerList(params),
    })
    makeObservable(this)
  }

  @action getPartner = async (partnerId: string) => {
    try {
      const { data } = await api.legacy.getPartner(partnerId)
      runInAction(() => {
        this.partner = data
      })
      return data
    } catch (error) {
      errorHandler(error as AxiosError)
    }
  }

  @action setPartnerData = (partner: IPartner) => {
    runInAction(() => {
      this.partner = partner
    })
  }

  @action getPartnerOptions = async (search: string) => {
    const data = await this.partnerTable.getData({ query: search })
    return data || []
  }
}

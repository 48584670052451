import { useContext } from 'react'
import { AppUserContext } from '@/_app/AppUser.context'

export const useAppUserContext = () => {
  const context = useContext(AppUserContext)
  if (!context) {
    throw new Error('Обращение к контексту без провайдера')
  }
  return context
}

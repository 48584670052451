import { makeObservable, action, observable, runInAction } from 'mobx'
import { INotification, INotificationsList } from '@/types/notifiaction'

const removeTime = (miliseconds: number) => Date.now() + miliseconds

export class NotificationsStore {
  @observable list: INotificationsList = []
  @observable garbageIsWorking = false
  constructor() {
    makeObservable(this)
  }

  @action push = (notify: INotification | INotification[]) => {
    if (Array.isArray(notify)) {
      this.list = [...this.list, ...notify]
    } else {
      this.list.push(notify)
    }
    if (!this.garbageIsWorking) {
      this.garbageStart()
    }
  }

  @action pushSuccess = (
    notify:
      | Omit<INotification, 'type' | 'id'>
      | Omit<INotification, 'type' | 'id'>[],
  ) => {
    if (Array.isArray(notify)) {
      const notifications = notify.map(({ message }) => ({
        message,
        type: 'success' as const,
        id: removeTime(3000),
      }))
      this.push(notifications)
    } else {
      this.push({
        type: 'success',
        ...notify,
        id: removeTime(3000),
      })
    }
  }

  @action pushError = (
    notify:
      | Omit<INotification, 'type' | 'id'>
      | Omit<INotification, 'type' | 'id'>[],
  ) => {
    if (Array.isArray(notify)) {
      const notifications = notify.map(({ message }) => ({
        message,
        type: 'error' as const,
        id: removeTime(3000),
      }))
      this.push(notifications)
    } else {
      this.push({
        type: 'error',
        ...notify,
        id: removeTime(3000),
      })
    }
  }

  wait = (mls: number) =>
    new Promise((resolve) => {
      setTimeout(() => resolve(null), mls)
    })

  @action garbageStart = async () => {
    this.garbageIsWorking = true
    await this.wait(500)
    const now = Date.now()

    runInAction(() => {
      this.list = this.list.filter(({ id }) => now < id)
    })
    if (this.list.length > 0) {
      this.garbageStart()
    } else {
      this.garbageIsWorking = false
    }
  }
}

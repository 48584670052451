import { MotionProps } from 'framer-motion'

export const mainPagesMotionAnimationSettings: MotionProps = {
  initial: {
    opacity: 0,
    transform: 'scale(.996)',
  },
  animate: {
    opacity: 1,
    transform: 'scale(1)',
  },
  exit: {
    opacity: 0,
    transform: 'scale(2)',
  },
  transition: { duration: 0.32 },
}

import { checkboxAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'
import { colors } from './colors'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys)

const baseStyle = definePartsStyle({
  icon: {
    color: '#fff',
    width: '24px',
    height: '24px',
  },

  control: {
    borderRadius: '6px',
    width: '24px',
    height: '24px',
    _checked: {
      background: colors.accent[600],
      borderColor: colors.accent[600],
      color: '#fff',
    },
    _invalid: {
      borderColor: colors.error[600],
    },
  },
})

const sm = definePartsStyle({
  control: defineStyle({
    boxSize: '16px',
    borderRadius: '4px',
  }),
  label: defineStyle({
    fontSize: '',
  }),
  icon: defineStyle({
    color: '#fff',
    width: '16px',
    height: '16px',
  }),
})

export const checkboxTheme = defineMultiStyleConfig({
  baseStyle,
  sizes: { sm },
})

// _focus: {
//   color: 'red',
// },

import './extendYup'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { ChakraProvider } from '@chakra-ui/react'
import { BrowserRouter } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import { configure } from 'mobx'
import TimeAgo from 'javascript-time-ago'
import ru from 'javascript-time-ago/locale/ru.json'
import { HelmetProvider } from 'react-helmet-async'
import { i18n } from './locale/i18n'
import theme from './theme/theme'
import App from './_app/App'
import { AppApiProvider } from '@/AppApi.provider'

TimeAgo.addDefaultLocale(ru)
TimeAgo.addLocale(ru)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

configure({ enforceActions: 'observed' })

root.render(
  <HelmetProvider>
    <ChakraProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        <BrowserRouter>
          <AppApiProvider>
            <App />
          </AppApiProvider>
        </BrowserRouter>
      </I18nextProvider>
    </ChakraProvider>
  </HelmetProvider>,
)

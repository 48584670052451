/**
 * Этот файл используется скриптами, которые не поддерживают модули.
 * Написано в стиле CommonJS.
 */

const mode = process.env.REACT_APP_API_MODE

const javaApiUrl =
  mode === 'production' ? 'https://api.quby.ru/' : 'https://api-dev.quby.ru/'
const phpApiUrl =
  mode === 'production' ?
    'https://api-new.quby.ru/'
  : 'https://api-stage-new.quby.ru/'
const dlvApiUrl = `https://dlv-app.kak2c.ru/`

module.exports = {
  PHP_API_URL: phpApiUrl,
  JAVA_API_URL: javaApiUrl,
  DLV_API_URL: dlvApiUrl,
  BASE_JAVA_API_URL: `${javaApiUrl}v1/`,
  BASE_PHP_API_URL: `${phpApiUrl}v1.1/`,
  BASE_DLV_API_URL: dlvApiUrl,
}

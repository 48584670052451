export const textStyles = {
  h1: {
    fontSize: '24px',
    lineHeight: '24px',
    fontWeight: 400,
  },

  h2: {
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: 400,
  },

  h3: {
    fontSize: '18px',
    lineHeight: '20px',
    fontWeight: 400,
  },

  h4: {
    fontSize: '16px',
    lineHeight: '18px',
    fontWeight: 600,
    fontFamily: 'Inter',
  },

  h5: {
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 600,
    fontStyle: 'normal',
  },

  h6: {
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 700,
  },

  text1: {
    fontSize: '18px',
    lineHeight: '18px',
    fontWeight: 400,
  },

  text2: {
    fontSize: '16px',
    lineHeight: '26px',
    fontWeight: 400,
  },

  text3: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400,
  },

  text4: {
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 400,
  },

  text5: {
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 400,
  },

  text6: {
    fontSize: '10px',
    lineHeight: '12px',
    fontWeight: 400,
  },

  text7: {
    fontSize: '8px',
    lineHeight: '10px',
    fontWeight: 400,
  },

  caption: {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 400,
  },
  'small-caption': {
    fontSize: '10px',
    lineHeight: '10px',
    fontWeight: 400,
  },

  label: {
    fontSize: '16px',
    lineHeight: '16px',
    fontWeight: 600,
  },
  'field-caption': {
    fontSize: '14px',
    lineHeight: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
  },
}

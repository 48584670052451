import { Alert, AlertIcon, Box, Flex, Text } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { AppContext } from '@/_app/App.context'

export const AppOfflineIndicator = () => {
  const { isOffline } = useContext(AppContext)
  return (
    <div>
      <AnimatePresence>
        {isOffline && (
          <motion.div
            style={{ overflow: 'hidden' }}
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            transition={{ duration: 0.5 }}
            exit={{ height: 0 }}
            key={'AppOfflineContainer'}
          >
            <Box>
              <Alert status="error">
                <AlertIcon />
                <Flex
                  align={'center'}
                  width={'100%'}
                  justify={'space-between'}
                  paddingRight={'16px'}
                >
                  <Text>
                    Приложение оффлайн. Проверьте настройки интернета.
                  </Text>
                </Flex>
              </Alert>
            </Box>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

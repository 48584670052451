import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { createPortal } from 'react-dom'

interface Props {
  children: ReactNode
}

// @ts-ignore
const ContentHeader: FC<Props> = ({ children }) => {
  const [node, setNode] = useState<HTMLElement>()

  useEffect(() => {
    const header = document.getElementById('header')
    if (header) {
      setNode(header as HTMLElement)
    }
  }, [])

  const intervalRef = useRef<NodeJS.Timeout>()

  const setNodeForce = useCallback(() => {
    const header = document.getElementById('header')
    if (header && !node) {
      setNode(header as HTMLElement)
    } else {
      clearInterval(intervalRef.current)
      intervalRef.current = setInterval(setNodeForce, 100)
    }
    if (header && node) {
      clearInterval(intervalRef.current)
    }
  }, [node])

  useLayoutEffect(() => {
    setNodeForce()
    return () => {
      clearInterval(intervalRef.current)
    }
  }, [node, setNodeForce])

  if (node) {
    return (
      // @ts-ignore
      createPortal(children, node as Element)
    )
  }

  return null
}

export default ContentHeader

import memoize from 'lodash/memoize'
import { compile } from 'path-to-regexp'

const memoizedCompilePath = memoize(compile)

/**
 * Этот код для меня загадка.
 */
export function getLink<T = unknown>(
  path: string,
  params: Record<string, T> = {},
): string | never {
  const toPath = memoizedCompilePath(path)

  return toPath(params)
}

export default getLink

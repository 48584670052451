import styled from 'styled-components'
import { colors } from '../../theme/colors'

const Snack = styled.div`
  width: max-content;
  height: 56px;
  display: flex;
  align-items: center;
  color: #ffffff;
  padding: 0 24px;
  font-size: 16px;
  border-radius: 10px;
  max-width: 100%;

  svg {
    margin-right: 16px;
  }
`
export const SuccessSnack = styled(Snack)`
  background-color: ${colors.success['600']};
`
export const ErrorSnack = styled(Snack)`
  background-color: ${colors.error['600']};
`

export const SnackBar = styled.div`
  display: grid;
  grid-gap: 8px;
  position: fixed;
  bottom: 0;
  width: max-content;
  z-index: 9999;
  margin: 12px;
`

import { radioAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { colors } from './colors'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(radioAnatomy.keys)

const baseStyle = definePartsStyle({
  icon: {
    color: '#fff',
    width: '24px',
    height: '24px',
  },

  control: {
    width: '16px',
    height: '16px',
    _checked: {
      background: colors.accent[600],
      borderColor: colors.accent[600],
      color: '#fff',
    },
    _invalid: {
      borderColor: colors.error[600],
    },
  },
})

export const radioTheme = defineMultiStyleConfig({
  baseStyle,
})

// _focus: {
//   color: 'red',
// },

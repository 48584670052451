import * as React from 'react'

function Icon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0_80_910)">
        <path
          d="M11.25 5.417a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0zM20 15.833v-5.55A10.174 10.174 0 0010.695.023 10 10 0 00.023 10.712C.392 16.007 5.068 20 10.902 20h4.93A4.172 4.172 0 0020 15.833zM10.582 1.687a8.5 8.5 0 017.75 8.596v5.55a2.5 2.5 0 01-2.5 2.5h-4.931c-5.029 0-8.903-3.25-9.215-7.736a8.333 8.333 0 018.896-8.91zM11.666 15v-5a1.667 1.667 0 00-1.667-1.667h-.833a.833.833 0 000 1.667h.833v5a.833.833 0 001.667 0z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_80_910">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Icon

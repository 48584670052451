import type { BaseQueryFn } from '@reduxjs/toolkit/query'
import type { AxiosRequestConfig, ParamEncoder } from 'axios'
import pick from 'lodash/pick'
import { QueryBaseError } from '@/api/axiosBaseQuery.types'
import Api from '@/services/api/api'
// @ts-ignore
import { BASE_DLV_API_URL } from '@/constants/env'

export const axiosBaseQueryDlv =
  (
    api: Api,
  ): BaseQueryFn<
    AxiosRequestConfig & { body?: unknown },
    unknown,
    QueryBaseError
  > =>
  async (conf) => {
    const { url, method, params, body: data, headers } = conf

    const baseURL = BASE_DLV_API_URL
    // TODO: здесь эта затея выглядит странно. Убрать после выпиливания Java

    const customEncoder: ParamEncoder = (value) => {
      if (typeof value === 'string') {
        return decodeURIComponent(value) // Используем стандартный кодировщик
      }
      return value
    }
    try {
      const result = await api.axios({
        ...{
          paramsSerializer: {
            encode: customEncoder,
          },
        },
        url,
        method,
        data,
        params,
        headers,
        baseURL,
      })
      return { data: result.data }
    } catch (axiosError) {
      const error = axiosError as QueryBaseError
      return {
        error: {
          ...pick(error, ['message', 'code', 'name']),
          response: JSON.parse(
            JSON.stringify(
              pick(error.response || {}, [
                'data',
                'status',
                'statusText',
                'headers',
              ]),
            ),
          ),
        },
      }
    }
  }

import { defineStyle, defineStyleConfig } from '@chakra-ui/react'
import { colors } from './colors'

const err = defineStyle({
  bgColor: colors.error['600'],
  color: 'white',
  transition: '0.3s ease',
  _hover: {
    bgColor: colors.error['700'],
  },
})

const l = defineStyle({
  fontSize: '14px',
  lineHeight: '16px',
  px: '14px',
  py: '10px',
})

const m = defineStyle({
  fontSize: '14px',
  lineHeight: '16px',
  px: '12px',
  py: '8px',
})

const s = defineStyle({
  fontSize: '10px',
  lineHeight: '12px',
  px: '6px',
  py: '4px',
})

export const tooltipTheme = defineStyleConfig({
  baseStyle: {
    boxSizing: 'border-box;',
  },
  variants: {
    err,
    red: err,
  },
  sizes: {
    l,
    m,
    s,
  },
})

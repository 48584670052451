import { defineStyle, defineStyleConfig } from '@chakra-ui/react'
import { colors } from './colors'

const disabled = {
  cursor: 'auto',
  bgColor: colors.neutral['400'],
  color: colors.neutral['500'],
}

const accent = defineStyle({
  bgColor: colors.accent['600'],
  color: 'white',
  transition: '0.3s ease',
  _hover: {
    color: '#fff',
    bg: colors.accent['700'],
    _disabled: disabled,
  },
  _active: {
    bg: colors.accent['900'],
  },
  _disabled: disabled,
})

const err = defineStyle({
  bgColor: colors.error['600'],
  color: 'white',
  transition: '0.3s ease',
  _hover: {
    bgColor: colors.error['700'],
  },
  _active: {
    bg: colors.accent['900'],
  },
  _disabled: disabled,
})

const secondary = defineStyle({
  bgColor: 'white',
  color: colors.neutral['900'],
  border: `1px solid ${colors.neutral['400']}`,
  transition: '0.3s ease',
  boxSizing: 'border-box',
  _hover: {
    color: colors.neutral['900'],
    bgColor: colors.neutral['200'],
    borderColor: colors.neutral['400'],
  },
  _active: {
    bg: colors.neutral['400'],
    borderColor: '#d4dbe7',
    transform: 'scale(.98)',
    boxShadow: `0 0 1px 1px ${colors.neutral['400']}, inset 0 0 12px 0 #d4dbe7`,
  },
  _disabled: disabled,
})

const text = defineStyle({
  bgColor: 'none',
  color: colors.accent['600'],
  transition: '0.1s ease',
  boxSizing: 'border-box',
  fontWeight: 'normal',
  fontSize: {
    l: '100px',
    m: '100px',
    s: '100px',
  },
  _hover: {
    color: colors.accent['700'],
  },
  _active: {
    color: colors.accent['900'],
    transform: 'scale(0.97)',
  },
  _disabled: disabled,
})

const textButton = defineStyle({
  bgColor: 'none',
  color: colors.accent['600'],
  transition: '0.1s ease',
  boxSizing: 'border-box',
  fontWeight: 'normal',
  borderBottom: '1px dashed',
  borderRadius: 0,
  height: 'auto',
  padding: 0,
  fontSize: {
    l: '100px',
    m: '100px',
    s: '100px',
  },
  _hover: {
    color: colors.accent['700'],
    borderBottomStyle: 'solid',
  },
  _active: {
    color: colors.accent['900'],
    transform: 'scale(0.97)',
  },
  _disabled: disabled,
})

const l = defineStyle({
  fontSize: '14px',
  lineHeight: '16px',
  px: '14px',
  py: '10px',
})

const m = defineStyle({
  fontSize: '14px',
  lineHeight: '16px',
  px: '12px',
  py: '8px',
})

const s = defineStyle({
  fontSize: '14px',
  lineHeight: '16px',
  px: '6px',
  py: '10px',
})

export const buttonTheme = defineStyleConfig({
  baseStyle: {
    boxSizing: 'border-box;',
  },
  variants: {
    accent,
    disabled,
    secondary,
    err,
    red: err,
    text,
    'text-button': textButton,
  },
  sizes: {
    l,
    m,
    s,
  },
})

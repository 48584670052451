import { FC, PropsWithChildren } from 'react'
import { AppContext } from '@/_app/App.context'
import { Detector } from 'react-detect-offline'
import useWindowFocus from 'use-window-focus'

const envVariables = require('@/constants/env')

export const AppProvider: FC<PropsWithChildren> = ({ children }) => {
  const isWindowFocused = useWindowFocus()

  return (
    <Detector
      polling={{
        url: `${envVariables.PHP_API_URL}v1.1/ping`,
        enabled: isWindowFocused,
        interval: 5000,
        timeout: 5000,
      }}
      render={({ online }) => {
        return (
          <AppContext.Provider
            value={{
              isOffline: !online,
              isOnline: online,
              isActiveWindow: isWindowFocused,
              isPassiveWindow: !isWindowFocused,
            }}
          >
            {children}
          </AppContext.Provider>
        )
      }}
    />
  )
}

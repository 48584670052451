import { RouteProps } from 'react-router-dom'
import { KgLazyPage } from '@/_app/pages/kg/KgLazyPage'
import {
  AuthLazy,
  LogoutLazy,
  RegistrationLazy,
  RegistrationSuccessLazy,
  ResetPasswordLazy,
  ResetPasswordSuccessLazy,
  SetPasswordLazy,
} from '@/routes/lazy'
import React from 'react'
import { qubyNav } from '@/navigation/qubyNav'
import { DmLazyPage } from '@/_app/pages/dm/DmLazyPage'

export const publicRoutes: RouteProps[] = [
  {
    path: qubyNav.kg.PATH,
    element: <KgLazyPage />,
    index: true,
  },
  {
    path: qubyNav.dm.PATH,
    element: <DmLazyPage />,
    index: true,
  },
  {
    path: qubyNav.login.PATH,
    element: <AuthLazy />,
    index: true,
  },
  {
    path: qubyNav.logout.PATH,
    element: <LogoutLazy />,
    index: true,
  },
  {
    path: qubyNav.registration.PATH,
    element: <RegistrationLazy />,
  },
  {
    path: qubyNav.registration.success.PATH,
    element: <RegistrationSuccessLazy />,
  },
  {
    path: qubyNav.resetPassword.PATH,
    element: <ResetPasswordLazy />,
  },
  {
    path: qubyNav.resetPassword.success.PATH,
    element: <ResetPasswordSuccessLazy />,
  },
  {
    path: qubyNav.setPassword.PATH,
    element: <SetPasswordLazy />,
  },
]

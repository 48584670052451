export function parseFilenameFromContentDisposition(
  disposition: string | undefined,
) {
  if (!disposition) return ''
  return decodeURIComponent(
    disposition
      .replace(/attachment;.+utf-8''/, '')
      .replace(/^attachment; filename\*=UTF-8''/, '')
      .replace(/^attachment; filename="/, '')
      .replace(/"$/, ''),
  )
}

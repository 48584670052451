import { notificationsStore } from '@/stores/global.store'
import { ErrorDataRes, IError } from '@/types/global'
import { AxiosError } from 'axios'
import { SerializedError } from '@reduxjs/toolkit/src/createAsyncThunk'

function errorHandler(
  _err: AxiosError | SerializedError | undefined,
  pushNotification = true,
) {
  if (!_err) {
    return
  }
  let error: ErrorDataRes['error']
  if ('response' in _err) {
    const err = _err as IError
    error = err.response?.data?.error || 'Error'
  } else {
    error = _err as unknown as ErrorDataRes['error']
  }

  if (Array.isArray(error)) {
    if (pushNotification) {
      notificationsStore.pushError(error)
    }
    return error.map(({ message }) => message).join('\n')
  } else {
    if (error) {
      if (pushNotification) {
        notificationsStore.pushError({ message: String(error) })
      }
      return error
    }
  }
}

export default errorHandler

import type { BaseQueryFn } from '@reduxjs/toolkit/query'
import { AxiosError, AxiosRequestConfig } from 'axios'
import pick from 'lodash/pick'
import { QueryBaseError } from '@/api/axiosBaseQuery.types'
import Api from '@/services/api/api'
import qs from 'qs'
// @ts-ignore
import { BASE_PHP_API_URL } from '@/constants/env'
import { ApiResponseErrorsType } from '@/types/api.types'

type BaseError = Pick<
  AxiosError<ApiResponseErrorsType>,
  'message' | 'code' | 'name' | 'response'
>

export const axiosBaseQueryPhp =
  (
    api: Api,
  ): BaseQueryFn<AxiosRequestConfig & { body?: unknown }, unknown, BaseError> =>
  async (conf) => {
    const { url, method, params, body: data, headers } = conf

    // TODO: здесь эта затея выглядит странно. Убрать после выпиливания Java
    const resultUrl = url?.replace(/^\/v1\.1\//, '/')
    try {
      const result = await api.axios({
        paramsSerializer: {
          serialize: (params) => {
            return qs.stringify(params)
          },
        },
        url: resultUrl,
        method,
        data,
        params,
        headers,
        baseURL: BASE_PHP_API_URL,
      })
      return { data: result.data }
    } catch (axiosError) {
      const error = axiosError as QueryBaseError
      return {
        error: {
          ...pick(error, ['message', 'code', 'name']),
          response: JSON.parse(
            JSON.stringify(
              pick(error.response || {}, [
                'data',
                'status',
                'statusText',
                'headers',
              ]),
            ),
          ),
        },
      }
    }
  }

export function downloadBlob(
  props: BlobPropertyBag = {},
  data: BlobPart,
  downloadName = '',
) {
  const blob = new Blob([data], props as BlobPropertyBag)

  const link = document.createElement('a')
  const url = URL.createObjectURL(blob)
  link.href = url
  link.download = downloadName
  link.click()
  window.URL.revokeObjectURL(url)
  link.remove()
}

import React, { PropsWithChildren, useCallback, useEffect } from 'react'
import * as serviceWorkerRegistration from '@/serviceWorkerRegistration'
import random from 'lodash/random'
import { AppUpdateContext } from '@/_app/AppUpdate.context'

const isEnabledWorker = JSON.parse(process.env.REACT_APP_ENABLE_WORKER || '')

export const AppUpdateProvider = ({ children }: PropsWithChildren) => {
  const [isUpdateAvailable, setIsUpdateAvailable] = React.useState(false)
  const [showReload, setShowReload] = React.useState(false)
  const [waitingWorker, setWaitingWorker] =
    React.useState<ServiceWorker | null>(null)

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    setShowReload(true)
    registration.waiting?.postMessage({ type: 'SKIP_WAITING' })
    setTimeout(
      () => {
        window.location.reload()
      },
      random(1024, 5000, true),
    )

    setWaitingWorker(registration.waiting)
  }

  useEffect(() => {
    if (isEnabledWorker) {
      try {
        serviceWorkerRegistration.register({
          onUpdate: onSWUpdate,
          onUpdateFound: ({ active }) => {
            if (active) {
              setIsUpdateAvailable(true)
            }
          },
        })
      } catch (error) {
        console.log('Не удалась регистрация сервис-воркера', error)
      }
    }
  }, [])

  const reloadPage = useCallback(() => {
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' })
    setShowReload(false)
    setTimeout(() => {
      window.location.reload()
    }, 100)
  }, [waitingWorker])

  return (
    <AppUpdateContext.Provider
      value={{
        reloadPage,
        showReload,
        isUpdateAvailable,
      }}
    >
      {children}
    </AppUpdateContext.Provider>
  )
}

import { emptySplitJavaApi as api } from '../emptyApi'
export const addTagTypes = [
  'Partner warehouses',
  'Vehicle',
  'Users',
  'Shipment',
  'Run request',
  'Quby warehouse',
  'Partner',
  'Marketplace warehouses',
  'Shipment locations',
  'Shipment goods',
  'Driver',
  'Delivery service',
  'Yandex Routing',
  'Tariffs',
  'Payment Transactions',
  'Invoice',
  'Fake data',
  'Dadata API',
  'Calculator Tariffs',
  'Attachment',
  'Yandex Monitoring Proxy',
  'Internal Quby',
] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getWarehouseById: build.query<
        GetWarehouseByIdApiResponse,
        GetWarehouseByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/warehouse/${queryArg['warehouse-id']}`,
        }),
        providesTags: ['Partner warehouses'],
      }),
      updateWarehouse: build.mutation<
        UpdateWarehouseApiResponse,
        UpdateWarehouseApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/warehouse/${queryArg['warehouse-id']}`,
          method: 'PUT',
          body: queryArg.partnerWarehouseRequest,
        }),
        invalidatesTags: ['Partner warehouses'],
      }),
      deleteWarehouseById: build.mutation<
        DeleteWarehouseByIdApiResponse,
        DeleteWarehouseByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/warehouse/${queryArg['warehouse-id']}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Partner warehouses'],
      }),
      getVehicleById: build.query<
        GetVehicleByIdApiResponse,
        GetVehicleByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/vehicle/${queryArg['vehicle-id']}`,
        }),
        providesTags: ['Vehicle'],
      }),
      updateVehicleById: build.mutation<
        UpdateVehicleByIdApiResponse,
        UpdateVehicleByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/vehicle/${queryArg['vehicle-id']}`,
          method: 'PUT',
          body: queryArg.vehicleUpdateRequest,
        }),
        invalidatesTags: ['Vehicle'],
      }),
      deleteVehicleById: build.mutation<
        DeleteVehicleByIdApiResponse,
        DeleteVehicleByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/vehicle/${queryArg['vehicle-id']}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Vehicle'],
      }),
      getUserById: build.query<GetUserByIdApiResponse, GetUserByIdApiArg>({
        query: (queryArg) => ({
          url: `/v1/user`,
          params: { userId: queryArg.userId },
        }),
        providesTags: ['Users'],
      }),
      updateUser: build.mutation<UpdateUserApiResponse, UpdateUserApiArg>({
        query: (queryArg) => ({
          url: `/v1/user`,
          method: 'PUT',
          body: queryArg.userUpdateRequest,
          params: {
            partnerId: queryArg.partnerId,
            userId: queryArg.userId,
          },
        }),
        invalidatesTags: ['Users'],
      }),
      createUser: build.mutation<CreateUserApiResponse, CreateUserApiArg>({
        query: (queryArg) => ({
          url: `/v1/user`,
          method: 'POST',
          body: queryArg.userCreateRequest,
          params: { partnerId: queryArg.partnerId },
        }),
        invalidatesTags: ['Users'],
      }),
      deleteUser: build.mutation<DeleteUserApiResponse, DeleteUserApiArg>({
        query: (queryArg) => ({
          url: `/v1/user`,
          method: 'DELETE',
          params: {
            partnerId: queryArg.partnerId,
            userId: queryArg.userId,
          },
        }),
        invalidatesTags: ['Users'],
      }),
      getShipmentById: build.query<
        GetShipmentByIdApiResponse,
        GetShipmentByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/shipment/${queryArg['shipment-id']}`,
        }),
        providesTags: ['Shipment'],
      }),
      updateShipmentById: build.mutation<
        UpdateShipmentByIdApiResponse,
        UpdateShipmentByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/shipment/${queryArg['shipment-id']}`,
          method: 'PUT',
          body: queryArg.shipmentShipmentUpdateRequest,
        }),
        invalidatesTags: ['Shipment'],
      }),
      updateShipmentStatus: build.mutation<
        UpdateShipmentStatusApiResponse,
        UpdateShipmentStatusApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/shipment/${queryArg['shipment-id']}`,
          method: 'PATCH',
          body: queryArg.shipmentPatchInfoRequest,
        }),
        invalidatesTags: ['Shipment'],
      }),
      acceptCargoPackages: build.mutation<
        AcceptCargoPackagesApiResponse,
        AcceptCargoPackagesApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/shipment/${queryArg['shipment-id']}/warehouse-acceptance`,
          method: 'PUT',
          body: queryArg.shipmentCargoPackagesAcceptRequest,
        }),
        invalidatesTags: ['Shipment'],
      }),
      updatePassword: build.mutation<
        UpdatePasswordApiResponse,
        UpdatePasswordApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/set-password`,
          method: 'PUT',
          body: queryArg.userUpdatePasswordRequest,
        }),
        invalidatesTags: ['Users'],
      }),
      getRunRequestById: build.query<
        GetRunRequestByIdApiResponse,
        GetRunRequestByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/run-request/${queryArg['request-id']}`,
        }),
        providesTags: ['Run request'],
      }),
      updateRunRequest: build.mutation<
        UpdateRunRequestApiResponse,
        UpdateRunRequestApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/run-request/${queryArg['request-id']}`,
          method: 'PUT',
          body: queryArg.runRequestUpdate,
        }),
        invalidatesTags: ['Run request'],
      }),
      updateStatusOfRunRequest: build.mutation<
        UpdateStatusOfRunRequestApiResponse,
        UpdateStatusOfRunRequestApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/run-request/${queryArg['request-id']}`,
          method: 'PATCH',
          body: queryArg.runRequestPatch,
        }),
        invalidatesTags: ['Run request'],
      }),
      getQubyWarehouseById: build.query<
        GetQubyWarehouseByIdApiResponse,
        GetQubyWarehouseByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/quby-warehouse/${queryArg['warehouse-id']}`,
        }),
        providesTags: ['Quby warehouse'],
      }),
      updateQubyWarehouse: build.mutation<
        UpdateQubyWarehouseApiResponse,
        UpdateQubyWarehouseApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/quby-warehouse/${queryArg['warehouse-id']}`,
          method: 'PUT',
          body: queryArg.qubyWarehouseRequest,
        }),
        invalidatesTags: ['Quby warehouse'],
      }),
      deleteQubyWarehouseById: build.mutation<
        DeleteQubyWarehouseByIdApiResponse,
        DeleteQubyWarehouseByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/quby-warehouse/${queryArg['warehouse-id']}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Quby warehouse'],
      }),
      getPartnerById: build.query<
        GetPartnerByIdApiResponse,
        GetPartnerByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/partner`,
          params: { partnerId: queryArg.partnerId },
        }),
        providesTags: ['Partner'],
      }),
      updatePartnerById: build.mutation<
        UpdatePartnerByIdApiResponse,
        UpdatePartnerByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/partner`,
          method: 'PUT',
          body: queryArg.partnerPartnerRequest,
          params: { partnerId: queryArg.partnerId },
        }),
        invalidatesTags: ['Partner'],
      }),
      createPartner: build.mutation<
        CreatePartnerApiResponse,
        CreatePartnerApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/partner`,
          method: 'POST',
          body: queryArg.partnerCreatePartnerRequest,
        }),
        invalidatesTags: ['Partner'],
      }),
      updatePartnerSetting: build.mutation<
        UpdatePartnerSettingApiResponse,
        UpdatePartnerSettingApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/partner/settings`,
          method: 'PUT',
          body: queryArg.partnerPartnerSettingsRequest,
          params: { partnerId: queryArg.partnerId },
        }),
        invalidatesTags: ['Partner'],
      }),
      updateMarketplaceWarehouseById: build.mutation<
        UpdateMarketplaceWarehouseByIdApiResponse,
        UpdateMarketplaceWarehouseByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/marketplace-warehouse/${queryArg['marketplace-warehouse-id']}`,
          method: 'PUT',
          body: queryArg.marketplaceWarehouseRequest,
        }),
        invalidatesTags: ['Marketplace warehouses'],
      }),
      deleteMarketplaceWarehouseById: build.mutation<
        DeleteMarketplaceWarehouseByIdApiResponse,
        DeleteMarketplaceWarehouseByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/marketplace-warehouse/${queryArg['marketplace-warehouse-id']}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Marketplace warehouses'],
      }),
      getLocationById: build.query<
        GetLocationByIdApiResponse,
        GetLocationByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/location/${queryArg['location-id']}`,
        }),
        providesTags: ['Shipment locations'],
      }),
      updateLocationById: build.mutation<
        UpdateLocationByIdApiResponse,
        UpdateLocationByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/location/${queryArg['location-id']}`,
          method: 'PUT',
          body: queryArg.locationRequest,
        }),
        invalidatesTags: ['Shipment locations'],
      }),
      deleteLocationById: build.mutation<
        DeleteLocationByIdApiResponse,
        DeleteLocationByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/location/${queryArg['location-id']}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Shipment locations'],
      }),
      getGoodById: build.query<GetGoodByIdApiResponse, GetGoodByIdApiArg>({
        query: (queryArg) => ({
          url: `/v1/good/${queryArg['good-id']}`,
        }),
        providesTags: ['Shipment goods'],
      }),
      updateGoodById: build.mutation<
        UpdateGoodByIdApiResponse,
        UpdateGoodByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/good/${queryArg['good-id']}`,
          method: 'PUT',
          body: queryArg.goodGoodRequest,
        }),
        invalidatesTags: ['Shipment goods'],
      }),
      deleteGoodById: build.mutation<
        DeleteGoodByIdApiResponse,
        DeleteGoodByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/good/${queryArg['good-id']}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Shipment goods'],
      }),
      getDriverById: build.query<GetDriverByIdApiResponse, GetDriverByIdApiArg>(
        {
          query: (queryArg) => ({
            url: `/v1/driver/${queryArg['driver-id']}`,
          }),
          providesTags: ['Driver'],
        },
      ),
      updateDriverById: build.mutation<
        UpdateDriverByIdApiResponse,
        UpdateDriverByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/driver/${queryArg['driver-id']}`,
          method: 'PUT',
          body: queryArg.driverUpdateRequest,
        }),
        invalidatesTags: ['Driver'],
      }),
      deleteDriver: build.mutation<DeleteDriverApiResponse, DeleteDriverApiArg>(
        {
          query: (queryArg) => ({
            url: `/v1/driver/${queryArg['driver-id']}`,
            method: 'DELETE',
          }),
          invalidatesTags: ['Driver'],
        },
      ),
      getDeliveryServiceById: build.query<
        GetDeliveryServiceByIdApiResponse,
        GetDeliveryServiceByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/delivery-service/${queryArg['delivery-service-id']}`,
        }),
        providesTags: ['Delivery service'],
      }),
      updateDeliveryServiceById: build.mutation<
        UpdateDeliveryServiceByIdApiResponse,
        UpdateDeliveryServiceByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/delivery-service/${queryArg['delivery-service-id']}`,
          method: 'PUT',
          body: queryArg.deliveryServiceRequest,
        }),
        invalidatesTags: ['Delivery service'],
      }),
      deleteDeliveryServiceById: build.mutation<
        DeleteDeliveryServiceByIdApiResponse,
        DeleteDeliveryServiceByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/delivery-service/${queryArg['delivery-service-id']}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Delivery service'],
      }),
      createYandexRoutingTask: build.mutation<
        CreateYandexRoutingTaskApiResponse,
        CreateYandexRoutingTaskApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/yandex-routing-task/create`,
          method: 'POST',
          body: queryArg.yandexRoutingTaskRequest,
        }),
        invalidatesTags: ['Yandex Routing'],
      }),
      createWarehouse: build.mutation<
        CreateWarehouseApiResponse,
        CreateWarehouseApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/warehouse`,
          method: 'POST',
          body: queryArg.partnerWarehouseRequest,
          params: { partnerId: queryArg.partnerId },
        }),
        invalidatesTags: ['Partner warehouses'],
      }),
      createVehicle: build.mutation<
        CreateVehicleApiResponse,
        CreateVehicleApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/vehicle`,
          method: 'POST',
          body: queryArg.vehicleCreateRequest,
        }),
        invalidatesTags: ['Vehicle'],
      }),
      createCommonTariffsFile: build.query<
        CreateCommonTariffsFileApiResponse,
        CreateCommonTariffsFileApiArg
      >({
        query: () => ({ url: `/v1/tariff/delivery` }),
        providesTags: ['Tariffs'],
      }),
      saveCommonTariffs: build.mutation<
        SaveCommonTariffsApiResponse,
        SaveCommonTariffsApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/tariff/delivery`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['Tariffs'],
      }),
      excelReader: build.mutation<ExcelReaderApiResponse, ExcelReaderApiArg>({
        query: (queryArg) => ({
          url: `/v1/shipments/upload-file-multiple-creation`,
          method: 'POST',
          body: queryArg.body,
          params: { partnerId: queryArg.partnerId },
        }),
        invalidatesTags: ['Shipment'],
      }),
      createShipment: build.mutation<
        CreateShipmentApiResponse,
        CreateShipmentApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/shipment`,
          method: 'POST',
          body: queryArg.shipmentShipmentCreateRequest,
          params: { partnerId: queryArg.partnerId },
        }),
        invalidatesTags: ['Shipment'],
      }),
      createCargoPackageForShipment: build.mutation<
        CreateCargoPackageForShipmentApiResponse,
        CreateCargoPackageForShipmentApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/shipment/${queryArg['shipment-ordinal-number']}/cargo-package`,
          method: 'POST',
          body: queryArg.shipmentCargoPackageCreateRequest,
          params: { partnerId: queryArg.partnerId },
        }),
        invalidatesTags: ['Shipment'],
      }),
      createRunRequestPrivateEndpoint: build.mutation<
        CreateRunRequestPrivateEndpointApiResponse,
        CreateRunRequestPrivateEndpointApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/shipment/${queryArg['shipment-id']}/run-requests`,
          method: 'POST',
          body: queryArg.runRequestCreationForShipmentRequest,
        }),
        invalidatesTags: ['Shipment'],
      }),
      createConsolidatedRunRequest: build.mutation<
        CreateConsolidatedRunRequestApiResponse,
        CreateConsolidatedRunRequestApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/run-request`,
          method: 'POST',
          body: queryArg.runRequestCreate,
          params: { partnerId: queryArg.partnerId },
        }),
        invalidatesTags: ['Run request'],
      }),
      generatedPasswordResetLink: build.mutation<
        GeneratedPasswordResetLinkApiResponse,
        GeneratedPasswordResetLinkApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/restore-password`,
          method: 'POST',
          body: queryArg.restorePasswordRequest,
        }),
        invalidatesTags: ['Users'],
      }),
      registration: build.mutation<RegistrationApiResponse, RegistrationApiArg>(
        {
          query: (queryArg) => ({
            url: `/v1/register`,
            method: 'POST',
            body: queryArg.userRegisterRequest,
          }),
          invalidatesTags: ['Users'],
        },
      ),
      getAccessTokenByRefreshToken: build.mutation<
        GetAccessTokenByRefreshTokenApiResponse,
        GetAccessTokenByRefreshTokenApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/refresh`,
          method: 'POST',
          body: queryArg.refreshTokenRequest,
        }),
        invalidatesTags: ['Users'],
      }),
      createQubyWarehouse: build.mutation<
        CreateQubyWarehouseApiResponse,
        CreateQubyWarehouseApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/quby-warehouse`,
          method: 'POST',
          body: queryArg.qubyWarehouseRequest,
        }),
        invalidatesTags: ['Quby warehouse'],
      }),
      courierPaymentOperationResult: build.mutation<
        CourierPaymentOperationResultApiResponse,
        CourierPaymentOperationResultApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/payments/driver-payment-operation-result`,
          method: 'POST',
          body: queryArg.cashOnDeliveryLogsRequest,
        }),
        invalidatesTags: ['Payment Transactions'],
      }),
      addPartnerLogo: build.mutation<
        AddPartnerLogoApiResponse,
        AddPartnerLogoApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/partner/upload-logo`,
          method: 'POST',
          body: queryArg.body,
          params: { partnerId: queryArg.partnerId },
        }),
        invalidatesTags: ['Partner'],
      }),
      createMarketplaceWarehouse: build.mutation<
        CreateMarketplaceWarehouseApiResponse,
        CreateMarketplaceWarehouseApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/marketplace-warehouse`,
          method: 'POST',
          body: queryArg.marketplaceWarehouseRequest,
        }),
        invalidatesTags: ['Marketplace warehouses'],
      }),
      logout: build.mutation<LogoutApiResponse, LogoutApiArg>({
        query: () => ({ url: `/v1/logout`, method: 'POST' }),
        invalidatesTags: ['Users'],
      }),
      login: build.mutation<LoginApiResponse, LoginApiArg>({
        query: (queryArg) => ({
          url: `/v1/login`,
          method: 'POST',
          body: queryArg.userLoginRequest,
        }),
        invalidatesTags: ['Users'],
      }),
      createLocation: build.mutation<
        CreateLocationApiResponse,
        CreateLocationApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/location`,
          method: 'POST',
          body: queryArg.locationRequest,
          params: { partnerId: queryArg.partnerId },
        }),
        invalidatesTags: ['Shipment locations'],
      }),
      createInvoiceFile: build.mutation<
        CreateInvoiceFileApiResponse,
        CreateInvoiceFileApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/invoice`,
          method: 'POST',
          body: queryArg.invoiceCreationRequest,
        }),
        invalidatesTags: ['Invoice'],
      }),
      getListOfGoods: build.query<
        GetListOfGoodsApiResponse,
        GetListOfGoodsApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/goods`,
          params: {
            partnerId: queryArg.partnerId,
            currentPage: queryArg.currentPage,
            query: queryArg.query,
            orderBy: queryArg.orderBy,
            order: queryArg.order,
            perPage: queryArg.perPage,
          },
        }),
        providesTags: ['Shipment goods'],
      }),
      createBulkOfGoods: build.mutation<
        CreateBulkOfGoodsApiResponse,
        CreateBulkOfGoodsApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/goods`,
          method: 'POST',
          body: queryArg.body,
          params: { partnerId: queryArg.partnerId },
        }),
        invalidatesTags: ['Shipment goods'],
      }),
      createGood: build.mutation<CreateGoodApiResponse, CreateGoodApiArg>({
        query: (queryArg) => ({
          url: `/v1/good`,
          method: 'POST',
          body: queryArg.goodGoodRequest,
          params: { partnerId: queryArg.partnerId },
        }),
        invalidatesTags: ['Shipment goods'],
      }),
      createMultipleGoodFromExcel: build.mutation<
        CreateMultipleGoodFromExcelApiResponse,
        CreateMultipleGoodFromExcelApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/good/excel`,
          method: 'POST',
          body: queryArg.body,
          params: {
            partnerId: queryArg.partnerId,
            headerOffset: queryArg.headerOffset,
            offset: queryArg.offset,
          },
        }),
        invalidatesTags: ['Shipment goods'],
      }),
      generateFakeData: build.mutation<
        GenerateFakeDataApiResponse,
        GenerateFakeDataApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/fake`,
          method: 'POST',
          body: queryArg.fakeRequest,
        }),
        invalidatesTags: ['Fake data'],
      }),
      createDriver: build.mutation<CreateDriverApiResponse, CreateDriverApiArg>(
        {
          query: (queryArg) => ({
            url: `/v1/driver`,
            method: 'POST',
            body: queryArg.driverCreateRequest,
          }),
          invalidatesTags: ['Driver'],
        },
      ),
      createDeliveryService: build.mutation<
        CreateDeliveryServiceApiResponse,
        CreateDeliveryServiceApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/delivery-service`,
          method: 'POST',
          body: queryArg.deliveryServiceRequest,
        }),
        invalidatesTags: ['Delivery service'],
      }),
      getListOfAddressSuggestions: build.query<
        GetListOfAddressSuggestionsApiResponse,
        GetListOfAddressSuggestionsApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/dadata/suggest/address`,
          params: { queryAddress: queryArg.queryAddress },
        }),
        providesTags: ['Dadata API'],
      }),
      getListOfAddressSuggestionsDeprecated: build.mutation<
        GetListOfAddressSuggestionsDeprecatedApiResponse,
        GetListOfAddressSuggestionsDeprecatedApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/dadata/suggest/address`,
          method: 'POST',
          body: queryArg.queryAddress,
        }),
        invalidatesTags: ['Dadata API'],
      }),
      savePaymentOperationResult: build.mutation<
        SavePaymentOperationResultApiResponse,
        SavePaymentOperationResultApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/cash-on-delivery-log`,
          method: 'POST',
          body: queryArg.cashOnDeliveryLogsRequest,
        }),
        invalidatesTags: ['Shipment'],
      }),
      getCalculatedDlvShippingTariffs: build.mutation<
        GetCalculatedDlvShippingTariffsApiResponse,
        GetCalculatedDlvShippingTariffsApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/calculator/get-tariffs`,
          method: 'POST',
          body: queryArg.shipmentCalculationRequest,
          params: { partnerId: queryArg.partnerId },
        }),
        invalidatesTags: ['Calculator Tariffs'],
      }),
      getCalculatedShipmentCost: build.mutation<
        GetCalculatedShipmentCostApiResponse,
        GetCalculatedShipmentCostApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/calculator/costs`,
          method: 'POST',
          body: queryArg.shipmentCostRequest,
          params: { partnerId: queryArg.partnerId },
        }),
        invalidatesTags: ['Calculator Tariffs'],
      }),
      getListOfUserFiles: build.query<
        GetListOfUserFilesApiResponse,
        GetListOfUserFilesApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/attachments/${queryArg.model}`,
          params: { modelId: queryArg.modelId },
        }),
        providesTags: ['Attachment'],
      }),
      uploadFile: build.mutation<UploadFileApiResponse, UploadFileApiArg>({
        query: (queryArg) => ({
          url: `/v1/attachments/${queryArg.model}`,
          method: 'POST',
          body: queryArg.body,
          params: { modelId: queryArg.modelId },
        }),
        invalidatesTags: ['Attachment'],
      }),
      deleteFile: build.mutation<DeleteFileApiResponse, DeleteFileApiArg>({
        query: (queryArg) => ({
          url: `/v1/attachments/${queryArg.model}`,
          method: 'DELETE',
          params: { fileId: queryArg.fileId },
        }),
        invalidatesTags: ['Attachment'],
      }),
      getRoutingTaskResult: build.query<
        GetRoutingTaskResultApiResponse,
        GetRoutingTaskResultApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/yandex-routing-task/${queryArg['yandex-routing-task-id']}`,
        }),
        providesTags: ['Yandex Routing'],
      }),
      getChildTasks: build.query<GetChildTasksApiResponse, GetChildTasksApiArg>(
        {
          query: (queryArg) => ({
            url: `/v1/yandex-routing-task/child`,
            params: {
              'yandex-parent-task-id': queryArg['yandex-parent-task-id'],
            },
          }),
          providesTags: ['Yandex Routing'],
        },
      ),
      getVerificationInfo: build.query<
        GetVerificationInfoApiResponse,
        GetVerificationInfoApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/yandex-monitoring/verification`,
          params: {
            date: queryArg.date,
            'yandex-order-number': queryArg['yandex-order-number'],
            'yandex-depot-id': queryArg['yandex-depot-id'],
            'yandex-route-id': queryArg['yandex-route-id'],
          },
        }),
        providesTags: ['Yandex Monitoring Proxy'],
      }),
      getTrackingInfo: build.query<
        GetTrackingInfoApiResponse,
        GetTrackingInfoApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/yandex-monitoring/tracking`,
          params: { 'yandex-order-id': queryArg['yandex-order-id'] },
        }),
        providesTags: ['Yandex Monitoring Proxy'],
      }),
      getOrderPhotos: build.query<
        GetOrderPhotosApiResponse,
        GetOrderPhotosApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/yandex-monitoring/photo`,
          params: {
            page: queryArg.page,
            'per-page': queryArg['per-page'],
            'yandex-route-ids': queryArg['yandex-route-ids'],
            'yandex-order-ids': queryArg['yandex-order-ids'],
            'yandex-photo-ids': queryArg['yandex-photo-ids'],
            'yandex-photo-tag': queryArg['yandex-photo-tag'],
            'yandex-courier-id': queryArg['yandex-courier-id'],
          },
        }),
        providesTags: ['Yandex Monitoring Proxy'],
      }),
      getOrdersInfo: build.query<GetOrdersInfoApiResponse, GetOrdersInfoApiArg>(
        {
          query: (queryArg) => ({
            url: `/v1/yandex-monitoring/orders-info`,
            params: {
              'yandex-route-id': queryArg['yandex-route-id'],
              'yandex-order-number': queryArg['yandex-order-number'],
              page: queryArg.page,
              types: queryArg.types,
            },
          }),
          providesTags: ['Yandex Monitoring Proxy'],
        },
      ),
      getOrderInfo: build.query<GetOrderInfoApiResponse, GetOrderInfoApiArg>({
        query: (queryArg) => ({
          url: `/v1/yandex-monitoring/order/info`,
          params: { 'yandex-order-id': queryArg['yandex-order-id'] },
        }),
        providesTags: ['Yandex Monitoring Proxy'],
      }),
      getOrderNotifications: build.query<
        GetOrderNotificationsApiResponse,
        GetOrderNotificationsApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/yandex-monitoring/notifications`,
          params: {
            'from-date': queryArg['from-date'],
            'to-date': queryArg['to-date'],
            page: queryArg.page,
          },
        }),
        providesTags: ['Yandex Monitoring Proxy'],
      }),
      getListOwWarehouses: build.query<
        GetListOwWarehousesApiResponse,
        GetListOwWarehousesApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/warehouses`,
          params: {
            partnerId: queryArg.partnerId,
            query: queryArg.query,
            currentPage: queryArg.currentPage,
            perPage: queryArg.perPage,
          },
        }),
        providesTags: ['Partner warehouses'],
      }),
      getListOfVehicles: build.query<
        GetListOfVehiclesApiResponse,
        GetListOfVehiclesApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/vehicles`,
          params: {
            currentPage: queryArg.currentPage,
            perPage: queryArg.perPage,
            isActive: queryArg.isActive,
          },
        }),
        providesTags: ['Vehicle'],
      }),
      getV1Users: build.query<GetV1UsersApiResponse, GetV1UsersApiArg>({
        query: (queryArg) => ({
          url: `/v1/users`,
          params: {
            partnerId: queryArg.partnerId,
            currentPage: queryArg.currentPage,
            perPage: queryArg.perPage,
          },
        }),
        providesTags: ['Users'],
      }),
      getListOfShipments: build.query<
        GetListOfShipmentsApiResponse,
        GetListOfShipmentsApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/shipments`,
          params: {
            partnerId: queryArg.partnerId,
            partnerIds: queryArg.partnerIds,
            deliveryServiceIds: queryArg.deliveryServiceIds,
            query: queryArg.query,
            status: queryArg.status,
            type: queryArg['type'],
            createdDateFrom: queryArg.createdDateFrom,
            createdDateTo: queryArg.createdDateTo,
            updatedDateFrom: queryArg.updatedDateFrom,
            updatedDateTo: queryArg.updatedDateTo,
            plannedDeliveryDateFrom: queryArg.plannedDeliveryDateFrom,
            plannedDeliveryDateTo: queryArg.plannedDeliveryDateTo,
            runRequestDeliveryDate: queryArg.runRequestDeliveryDate,
            isExternal: queryArg.isExternal,
            isCashOnDelivery: queryArg.isCashOnDelivery,
            isPaid: queryArg.isPaid,
            orderBy: queryArg.orderBy,
            order: queryArg.order,
            currentPage: queryArg.currentPage,
            perPage: queryArg.perPage,
          },
        }),
        providesTags: ['Shipment'],
      }),
      getBarcodeStickers: build.query<
        GetBarcodeStickersApiResponse,
        GetBarcodeStickersApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/shipment/${queryArg.shipmentOrdinalNumber}/barcodes`,
          params: { partnerId: queryArg.partnerId },
        }),
        providesTags: ['Shipment'],
      }),
      getBarcodeSticker: build.query<
        GetBarcodeStickerApiResponse,
        GetBarcodeStickerApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/shipment/${queryArg.shipmentOrdinalNumber}/barcode/${queryArg.barcode}`,
          params: { partnerId: queryArg.partnerId },
        }),
        providesTags: ['Shipment'],
      }),
      getListOfRunRequests: build.query<
        GetListOfRunRequestsApiResponse,
        GetListOfRunRequestsApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/run-requests`,
          params: {
            partnerIds: queryArg.partnerIds,
            query: queryArg.query,
            status: queryArg.status,
            type: queryArg['type'],
            createdDateFrom: queryArg.createdDateFrom,
            createdDateTo: queryArg.createdDateTo,
            updatedDateFrom: queryArg.updatedDateFrom,
            updatedDateTo: queryArg.updatedDateTo,
            arrivalDateFrom: queryArg.arrivalDateFrom,
            arrivalDateTo: queryArg.arrivalDateTo,
            deliveryServiceId: queryArg.deliveryServiceId,
            orderBy: queryArg.orderBy,
            order: queryArg.order,
            currentPage: queryArg.currentPage,
            perPage: queryArg.perPage,
          },
        }),
        providesTags: ['Run request'],
      }),
      downloadConsignmentNote: build.query<
        DownloadConsignmentNoteApiResponse,
        DownloadConsignmentNoteApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/run-requests/${queryArg.requestId}/consignment-note`,
        }),
        providesTags: ['Run request'],
      }),
      getDeliveryListOnDate: build.query<
        GetDeliveryListOnDateApiResponse,
        GetDeliveryListOnDateApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/run-requests/export/delivery-list`,
          params: {
            routeNumber: queryArg.routeNumber,
            withRoutesAtDate: queryArg.withRoutesAtDate,
            driverId: queryArg.driverId,
          },
        }),
        providesTags: ['Run request'],
      }),
      getListOfQubyWarehouses: build.query<
        GetListOfQubyWarehousesApiResponse,
        GetListOfQubyWarehousesApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/quby-warehouses`,
          params: {
            currentPage: queryArg.currentPage,
            perPage: queryArg.perPage,
          },
        }),
        providesTags: ['Quby warehouse'],
      }),
      getListOfPartners: build.query<
        GetListOfPartnersApiResponse,
        GetListOfPartnersApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/partners`,
          params: {
            query: queryArg.query,
            currentPage: queryArg.currentPage,
            perPage: queryArg.perPage,
          },
        }),
        providesTags: ['Partner'],
      }),
      getPartnerLogo: build.query<
        GetPartnerLogoApiResponse,
        GetPartnerLogoApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/partner/download-logo`,
          params: { partnerId: queryArg.partnerId },
        }),
        providesTags: ['Partner'],
      }),
      getInfoAboutMe: build.query<
        GetInfoAboutMeApiResponse,
        GetInfoAboutMeApiArg
      >({
        query: () => ({ url: `/v1/me` }),
        providesTags: ['Users'],
      }),
      getListOfMarketplaceWarehouses: build.query<
        GetListOfMarketplaceWarehousesApiResponse,
        GetListOfMarketplaceWarehousesApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/marketplace-warehouses`,
          params: {
            query: queryArg.query,
            currentPage: queryArg.currentPage,
            perPage: queryArg.perPage,
            order: queryArg.order,
            orderBy: queryArg.orderBy,
          },
        }),
        providesTags: ['Marketplace warehouses'],
      }),
      getMarketplaceWarehouseById: build.query<
        GetMarketplaceWarehouseByIdApiResponse,
        GetMarketplaceWarehouseByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/marketplace-warehouse/${queryArg['marketplace-id']}`,
        }),
        providesTags: ['Marketplace warehouses'],
      }),
      getListOfLocations: build.query<
        GetListOfLocationsApiResponse,
        GetListOfLocationsApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/locations`,
          params: {
            query: queryArg.query,
            partnerId: queryArg.partnerId,
            orderBy: queryArg.orderBy,
            order: queryArg.order,
            currentPage: queryArg.currentPage,
            perPage: queryArg.perPage,
          },
        }),
        providesTags: ['Shipment locations'],
      }),
      getAllInvoices: build.query<
        GetAllInvoicesApiResponse,
        GetAllInvoicesApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/invoices`,
          params: {
            query: queryArg.query,
            partnerId: queryArg.partnerId,
            createdDateFrom: queryArg.createdDateFrom,
            createdDateTo: queryArg.createdDateTo,
            invoiceStatus: queryArg.invoiceStatus,
            currentPage: queryArg.currentPage,
            perPage: queryArg.perPage,
          },
        }),
        providesTags: ['Invoice'],
      }),
      getInvoiceByShipmentId: build.query<
        GetInvoiceByShipmentIdApiResponse,
        GetInvoiceByShipmentIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/invoice/shipment`,
          params: { shipmentId: queryArg.shipmentId },
        }),
        providesTags: ['Invoice'],
      }),
      versionApp: build.query<VersionAppApiResponse, VersionAppApiArg>({
        query: () => ({ url: `/v1/internal/version` }),
        providesTags: ['Internal Quby'],
      }),
      getAppLogs: build.query<GetAppLogsApiResponse, GetAppLogsApiArg>({
        query: (queryArg) => ({
          url: `/v1/internal/logs`,
          params: {
            from: queryArg['from'],
            query: queryArg.query,
            to: queryArg.to,
            level: queryArg.level,
            logsLocation: queryArg.logsLocation,
            shipmentStatuses: queryArg.shipmentStatuses,
            runRequestStatuses: queryArg.runRequestStatuses,
          },
        }),
        providesTags: ['Internal Quby'],
      }),
      downloadInsuranceReport: build.query<
        DownloadInsuranceReportApiResponse,
        DownloadInsuranceReportApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/internal/insurance-report`,
          params: { date: queryArg.date },
        }),
        providesTags: ['Internal Quby'],
      }),
      downloadShipmentExportFile: build.query<
        DownloadShipmentExportFileApiResponse,
        DownloadShipmentExportFileApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/internal/export/shipmentsRegister`,
          params: {
            partnerIds: queryArg.partnerIds,
            status: queryArg.status,
            type: queryArg['type'],
            createdDateFrom: queryArg.createdDateFrom,
            createdDateTo: queryArg.createdDateTo,
            updatedDateFrom: queryArg.updatedDateFrom,
            updatedDateTo: queryArg.updatedDateTo,
            desiredTimeFrom: queryArg.desiredTimeFrom,
            desiredTimeTo: queryArg.desiredTimeTo,
            actualDateFrom: queryArg.actualDateFrom,
            actualDateTo: queryArg.actualDateTo,
            invoiceId: queryArg.invoiceId,
            desiredColumn: queryArg.desiredColumn,
          },
        }),
        providesTags: ['Internal Quby'],
      }),
      downloadServiceRegisterFile: build.query<
        DownloadServiceRegisterFileApiResponse,
        DownloadServiceRegisterFileApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/internal/export/provided-services-register`,
          params: { partnerId: queryArg.partnerId, to: queryArg.to },
        }),
        providesTags: ['Internal Quby'],
      }),
      downloadRouteListOnDate: build.query<
        DownloadRouteListOnDateApiResponse,
        DownloadRouteListOnDateApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/internal/export/delivery-list`,
          params: {
            routeNumber: queryArg.routeNumber,
            withRoutesAtDate: queryArg.withRoutesAtDate,
            driverId: queryArg.driverId,
          },
        }),
        providesTags: ['Internal Quby'],
      }),
      downloadActOfAcceptanceDelivery: build.query<
        DownloadActOfAcceptanceDeliveryApiResponse,
        DownloadActOfAcceptanceDeliveryApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/internal/export/act-of-acceptance-delivery`,
          params: {
            runRequestId: queryArg.runRequestId,
            shipmentIds: queryArg.shipmentIds,
          },
        }),
        providesTags: ['Internal Quby'],
      }),
      getReadableShipmentStatuses: build.query<
        GetReadableShipmentStatusesApiResponse,
        GetReadableShipmentStatusesApiArg
      >({
        query: () => ({ url: `/v1/get-shipment-statuses` }),
        providesTags: ['Shipment'],
      }),
      getListOfDrivers: build.query<
        GetListOfDriversApiResponse,
        GetListOfDriversApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/drivers`,
          params: {
            query: queryArg.query,
            withRoutesAtDate: queryArg.withRoutesAtDate,
            orderBy: queryArg.orderBy,
            order: queryArg.order,
            currentPage: queryArg.currentPage,
            perPage: queryArg.perPage,
          },
        }),
        providesTags: ['Driver'],
      }),
      downloadImportTemplate: build.query<
        DownloadImportTemplateApiResponse,
        DownloadImportTemplateApiArg
      >({
        query: () => ({ url: `/v1/download/import-template` }),
        providesTags: ['Shipment'],
      }),
      getDlvPrintForm: build.query<
        GetDlvPrintFormApiResponse,
        GetDlvPrintFormApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/dlv-print-form`,
          params: { shipmentId: queryArg.shipmentId },
        }),
        providesTags: ['Run request'],
      }),
      getListOfDeliveryServices: build.query<
        GetListOfDeliveryServicesApiResponse,
        GetListOfDeliveryServicesApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/delivery-services`,
          params: {
            currentPage: queryArg.currentPage,
            perPage: queryArg.perPage,
          },
        }),
        providesTags: ['Delivery service'],
      }),
      downloadFile: build.query<DownloadFileApiResponse, DownloadFileApiArg>({
        query: (queryArg) => ({
          url: `/v1/attachments/download`,
          params: { fileId: queryArg.fileId },
        }),
        providesTags: ['Attachment'],
      }),
      deletePartnerById: build.mutation<
        DeletePartnerByIdApiResponse,
        DeletePartnerByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/partner/${queryArg['partner-id']}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Partner'],
      }),
    }),
    overrideExisting: false,
  })
export { injectedRtkApi as qubyApiJava }
export type GetWarehouseByIdApiResponse =
  /** status 200 Successful getting the warehouse */ PartnerPartnerWarehouseResponse
export type GetWarehouseByIdApiArg = {
  /** Concrete partner warehouse ID */
  'warehouse-id': string
}
export type UpdateWarehouseApiResponse =
  /** status 202 Successful updating a warehouse */ PartnerPartnerWarehouseResponse
export type UpdateWarehouseApiArg = {
  /** Concrete partner warehouse ID */
  'warehouse-id': string
  partnerWarehouseRequest: PartnerWarehouseRequest
}
export type DeleteWarehouseByIdApiResponse =
  /** status 204 Successful delete warehouse */ void
export type DeleteWarehouseByIdApiArg = {
  /** Concrete partner warehouse ID */
  'warehouse-id': string
}
export type GetVehicleByIdApiResponse =
  /** status 200 Successful getting a vehicle by ID */ VehicleResponse
export type GetVehicleByIdApiArg = {
  /** Concrete vehicle ID */
  'vehicle-id': string
}
export type UpdateVehicleByIdApiResponse =
  /** status 202 Successful updating vehicle info */ VehicleResponse
export type UpdateVehicleByIdApiArg = {
  /** Concrete vehicle ID */
  'vehicle-id': string
  vehicleUpdateRequest: VehicleUpdateRequest
}
export type DeleteVehicleByIdApiResponse =
  /** status 204 Successful deleting a vehicle */ void
export type DeleteVehicleByIdApiArg = {
  /** Concrete vehicle ID */
  'vehicle-id': string
}
export type GetUserByIdApiResponse =
  /** status 200 Successfully getting user for the partner */ UserResponse
export type GetUserByIdApiArg = {
  userId: string
}
export type UpdateUserApiResponse =
  /** status 202 Successfully update user by the partner id */ UserResponse
export type UpdateUserApiArg = {
  partnerId?: string
  userId: string
  userUpdateRequest: UserUpdateRequest
}
export type CreateUserApiResponse =
  /** status 201 Successfully creating user for the partner */ UserResponse
export type CreateUserApiArg = {
  partnerId: string
  userCreateRequest: UserCreateRequest
}
export type DeleteUserApiResponse =
  /** status 204 Successfully Delete user by the partner id */ void
export type DeleteUserApiArg = {
  partnerId?: string
  userId: string
}
export type GetShipmentByIdApiResponse =
  /** status 200 Successful getting the shipment by ID */ ShipmentFullStructuredShipment
export type GetShipmentByIdApiArg = {
  /** Concrete shipment ID */
  'shipment-id': string
}
export type UpdateShipmentByIdApiResponse =
  /** status 202 Successful update shipment */ ShipmentFullStructuredShipment
export type UpdateShipmentByIdApiArg = {
  /** Concrete shipment ID */
  'shipment-id': string
  shipmentShipmentUpdateRequest: ShipmentShipmentUpdateRequest
}
export type UpdateShipmentStatusApiResponse =
  /** status 202 Successful update shipment status */ ShipmentFullStructuredShipment
export type UpdateShipmentStatusApiArg = {
  /** Concrete shipment ID */
  'shipment-id': string
  shipmentPatchInfoRequest: ShipmentPatchInfoRequest
}
export type AcceptCargoPackagesApiResponse =
  /** status 202 Successful sccept cargo packages */ ShipmentFullStructuredShipment
export type AcceptCargoPackagesApiArg = {
  /** Concrete shipment ID */
  'shipment-id': string
  shipmentCargoPackagesAcceptRequest: ShipmentCargoPackagesAcceptRequest
}
export type UpdatePasswordApiResponse =
  /** status 204 Successful user password update */ void
export type UpdatePasswordApiArg = {
  userUpdatePasswordRequest: UserUpdatePasswordRequest
}
export type GetRunRequestByIdApiResponse =
  /** status 200 Successful getting the run request */ RunRequestFullStructuredRequest
export type GetRunRequestByIdApiArg = {
  /** Concrete run request ID */
  'request-id': string
}
export type UpdateRunRequestApiResponse =
  /** status 202 Successful updating the run request by run request */ RunRequestFullStructuredRequest
export type UpdateRunRequestApiArg = {
  /** Concrete run request ID */
  'request-id': string
  runRequestUpdate: RunRequestUpdate
}
export type UpdateStatusOfRunRequestApiResponse =
  /** status 202 Successful updating status of the run request */ RunRequestFullStructuredRequest
export type UpdateStatusOfRunRequestApiArg = {
  /** Concrete run request ID */
  'request-id': string
  runRequestPatch: RunRequestPatch
}
export type GetQubyWarehouseByIdApiResponse =
  /** status 200 Successful getting a Quby warehouse by ID */ QubyWarehouseResponse
export type GetQubyWarehouseByIdApiArg = {
  /** Concrete QUBY warehouse ID */
  'warehouse-id': string
}
export type UpdateQubyWarehouseApiResponse =
  /** status 202 Successful updating a Quby warehouse */ QubyWarehouseResponse
export type UpdateQubyWarehouseApiArg = {
  /** Concrete QUBY warehouse ID */
  'warehouse-id': string
  qubyWarehouseRequest: QubyWarehouseRequest
}
export type DeleteQubyWarehouseByIdApiResponse =
  /** status 204 Successful deleting a Quby warehouse */ void
export type DeleteQubyWarehouseByIdApiArg = {
  /** Concrete QUBY warehouse ID */
  'warehouse-id': string
}
export type GetPartnerByIdApiResponse =
  /** status 200 Successful getting the Partner */ PartnerPartnerResponse
export type GetPartnerByIdApiArg = {
  partnerId?: string
}
export type UpdatePartnerByIdApiResponse =
  /** status 202 Successful update the partner */ PartnerPartnerResponse
export type UpdatePartnerByIdApiArg = {
  partnerId?: string
  partnerPartnerRequest: PartnerPartnerRequest
}
export type CreatePartnerApiResponse =
  /** status 201 Successful create new Partner */ PartnerCreatePartnerRequest
export type CreatePartnerApiArg = {
  partnerCreatePartnerRequest: PartnerCreatePartnerRequest
}
export type UpdatePartnerSettingApiResponse =
  /** status 202 Successful changing the partner settings */ PartnerPartnerSettingsResponse
export type UpdatePartnerSettingApiArg = {
  partnerId?: string
  partnerPartnerSettingsRequest: PartnerPartnerSettingsRequest
}
export type UpdateMarketplaceWarehouseByIdApiResponse =
  /** status 202 Successful updating a marketplace warehouse */ MarketplaceWarehouseResponse
export type UpdateMarketplaceWarehouseByIdApiArg = {
  /** Concrete marketplace ID */
  'marketplace-warehouse-id': string
  marketplaceWarehouseRequest: MarketplaceWarehouseRequest
}
export type DeleteMarketplaceWarehouseByIdApiResponse =
  /** status 204 Successful deleting a warehouse */ void
export type DeleteMarketplaceWarehouseByIdApiArg = {
  /** Concrete marketplace ID */
  'marketplace-warehouse-id': string
}
export type GetLocationByIdApiResponse =
  /** status 200 Successful getting a location by location ID */ ReceiverReceiverResponse
export type GetLocationByIdApiArg = {
  /** Location ID */
  'location-id': string
}
export type UpdateLocationByIdApiResponse =
  /** status 202 Successful update a location of partner */ ReceiverReceiverResponse
export type UpdateLocationByIdApiArg = {
  /** Location ID */
  'location-id': string
  locationRequest: LocationRequest
}
export type DeleteLocationByIdApiResponse =
  /** status 204 Successful delete a location */ void
export type DeleteLocationByIdApiArg = {
  /** Location ID */
  'location-id': string
}
export type GetGoodByIdApiResponse =
  /** status 200 Successful getting the good by partner ID and goods ID, */ GoodGoodResponse
export type GetGoodByIdApiArg = {
  /** Concrete good ID */
  'good-id': string
}
export type UpdateGoodByIdApiResponse =
  /** status 200 Successful update good for the partner */ GoodGoodResponse
export type UpdateGoodByIdApiArg = {
  /** Concrete good ID */
  'good-id': string
  goodGoodRequest: GoodGoodRequest
}
export type DeleteGoodByIdApiResponse = unknown
export type DeleteGoodByIdApiArg = {
  /** Concrete good ID */
  'good-id': string
}
export type GetDriverByIdApiResponse =
  /** status 200 Successful getting a driver by Id */ DriverResponse
export type GetDriverByIdApiArg = {
  /** Concrete driver ID */
  'driver-id': string
}
export type UpdateDriverByIdApiResponse =
  /** status 202 Successful updating a driver info */ DriverResponse
export type UpdateDriverByIdApiArg = {
  /** Concrete driver ID */
  'driver-id': string
  driverUpdateRequest: DriverUpdateRequest
}
export type DeleteDriverApiResponse =
  /** status 204 Successful deleting a driver */ void
export type DeleteDriverApiArg = {
  /** Concrete driver ID */
  'driver-id': string
}
export type GetDeliveryServiceByIdApiResponse =
  /** status 200 Successful getting a delivery service by ID */ DeliveryServiceResponse
export type GetDeliveryServiceByIdApiArg = {
  /** Concrete delivery service ID */
  'delivery-service-id': string
}
export type UpdateDeliveryServiceByIdApiResponse =
  /** status 202 Successful updating delivery service */ DeliveryServiceResponse
export type UpdateDeliveryServiceByIdApiArg = {
  /** Concrete delivery service ID */
  'delivery-service-id': string
  deliveryServiceRequest: DeliveryServiceRequest
}
export type DeleteDeliveryServiceByIdApiResponse =
  /** status 204 Successful deleting a delivery service */ void
export type DeleteDeliveryServiceByIdApiArg = {
  /** Concrete delivery service ID */
  'delivery-service-id': string
}
export type CreateYandexRoutingTaskApiResponse =
  /** status 201 Successful creating Yandex routing task */ YandexRoutingTaskResponse
export type CreateYandexRoutingTaskApiArg = {
  yandexRoutingTaskRequest: YandexRoutingTaskRequestWrite
}
export type CreateWarehouseApiResponse =
  /** status 201 Successful creating a new warehouse */ PartnerPartnerWarehouseResponse
export type CreateWarehouseApiArg = {
  partnerId?: string
  partnerWarehouseRequest: PartnerWarehouseRequest
}
export type CreateVehicleApiResponse =
  /** status 201 Successful create a vehicle info */ VehicleResponse
export type CreateVehicleApiArg = {
  vehicleCreateRequest: VehicleCreateRequest
}
export type CreateCommonTariffsFileApiResponse =
  /** status 200 Successfully get file with common tariffs */ void
export type CreateCommonTariffsFileApiArg = void
export type SaveCommonTariffsApiResponse =
  /** status 201 Successfully create delivery tariffs */ void
export type SaveCommonTariffsApiArg = {
  body: {
    file: Blob
  }
}
export type ExcelReaderApiResponse =
  /** status 201 Successful creating multiple shipments */ ShipmentExportResponse
export type ExcelReaderApiArg = {
  partnerId?: string
  body: {
    file: Blob
  }
}
export type CreateShipmentApiResponse =
  /** status 201 Successful create new shipment */ ShipmentFullStructuredShipment
export type CreateShipmentApiArg = {
  partnerId?: string
  shipmentShipmentCreateRequest: ShipmentShipmentCreateRequest
}
export type CreateCargoPackageForShipmentApiResponse =
  /** status 201 Successful create new Cargo Package */ ShipmentCargoPackageResponse
export type CreateCargoPackageForShipmentApiArg = {
  /** Concrete ordinal number of shipment */
  'shipment-ordinal-number': string
  partnerId?: string
  shipmentCargoPackageCreateRequest: ShipmentCargoPackageCreateRequest
}
export type CreateRunRequestPrivateEndpointApiResponse =
  /** status 201 Successful create new run request */ RunRequestFullStructuredRequest[]
export type CreateRunRequestPrivateEndpointApiArg = {
  /** Shipment ID */
  'shipment-id': string
  runRequestCreationForShipmentRequest: RunRequestCreationForShipmentRequest
}
export type CreateConsolidatedRunRequestApiResponse =
  /** status 201 Successful creating the run request */ RunRequestFullStructuredRequest
export type CreateConsolidatedRunRequestApiArg = {
  partnerId?: string
  runRequestCreate: RunRequestCreate
}
export type GeneratedPasswordResetLinkApiResponse =
  /** status 204 Successfully sending a password reset link to the user email */ void
export type GeneratedPasswordResetLinkApiArg = {
  restorePasswordRequest: RestorePasswordRequest
}
export type RegistrationApiResponse =
  /** status 201 Successful user registration */ UserRegisterResponse
export type RegistrationApiArg = {
  userRegisterRequest: UserRegisterRequest
}
export type GetAccessTokenByRefreshTokenApiResponse =
  /** status 200 Successful receipt access user token */ RefreshTokenResponse
export type GetAccessTokenByRefreshTokenApiArg = {
  refreshTokenRequest: RefreshTokenRequest
}
export type CreateQubyWarehouseApiResponse =
  /** status 201 Successful adding a Quby warehouse */ QubyWarehouseResponse
export type CreateQubyWarehouseApiArg = {
  qubyWarehouseRequest: QubyWarehouseRequest
}
export type CourierPaymentOperationResultApiResponse =
  /** status 204 Successfully save payment operation result */ void
export type CourierPaymentOperationResultApiArg = {
  cashOnDeliveryLogsRequest: CashOnDeliveryLogsRequest
}
export type AddPartnerLogoApiResponse =
  /** status 201 Successful adding a logo for the partner */ void
export type AddPartnerLogoApiArg = {
  partnerId?: string
  body: {
    file: Blob
  }
}
export type CreateMarketplaceWarehouseApiResponse =
  /** status 201 Successful adding a marketplace warehouse */ MarketplaceWarehouseResponse
export type CreateMarketplaceWarehouseApiArg = {
  marketplaceWarehouseRequest: MarketplaceWarehouseRequest
}
export type LogoutApiResponse =
  /** status 204 Successful revoke of user access token and refresh token */ void
export type LogoutApiArg = void
export type LoginApiResponse =
  /** status 200 Successful receipt of access and refresh user token's */ UserTokensResponse
export type LoginApiArg = {
  userLoginRequest: UserLoginRequest
}
export type CreateLocationApiResponse =
  /** status 201 Successful create a location for the partner */ ReceiverReceiverResponse
export type CreateLocationApiArg = {
  partnerId?: string
  locationRequest: LocationRequest
}
export type CreateInvoiceFileApiResponse =
  /** status 201 Successful create invoice for shipments from document */ InvoiceResponse
export type CreateInvoiceFileApiArg = {
  invoiceCreationRequest: InvoiceCreationRequest
}
export type GetListOfGoodsApiResponse =
  /** status 200 Successful getting a collection of the goods for the partner by partner ID */ CommonCollectionResponseGoodGoodResponse
export type GetListOfGoodsApiArg = {
  partnerId?: string
  currentPage?: number
  query?: string
  orderBy?: GoodOrderBy
  order?: Direction
  perPage?: number
}
export type CreateBulkOfGoodsApiResponse =
  /** status 201 Successful create the batch of goods for the partner by partner ID */ GoodGoodResponse
export type CreateBulkOfGoodsApiArg = {
  partnerId?: string
  body: GoodGoodRequest[]
}
export type CreateGoodApiResponse =
  /** status 201 Successful create the good for the partner by partner ID */ GoodGoodResponse
export type CreateGoodApiArg = {
  partnerId?: string
  goodGoodRequest: GoodGoodRequest
}
export type CreateMultipleGoodFromExcelApiResponse = unknown
export type CreateMultipleGoodFromExcelApiArg = {
  partnerId?: string
  headerOffset: number
  offset: number
  body: {
    file: Blob
  }
}
export type GenerateFakeDataApiResponse = unknown
export type GenerateFakeDataApiArg = {
  fakeRequest: FakeRequest
}
export type CreateDriverApiResponse =
  /** status 201 Successful create a record about the driver */ DriverResponse
export type CreateDriverApiArg = {
  driverCreateRequest: DriverCreateRequest
}
export type CreateDeliveryServiceApiResponse =
  /** status 201 Successful create a delivery service */ DeliveryServiceResponse
export type CreateDeliveryServiceApiArg = {
  deliveryServiceRequest: DeliveryServiceRequest
}
export type GetListOfAddressSuggestionsApiResponse =
  /** status 200 Request processed successfully */ DadataSuggestionResponse
export type GetListOfAddressSuggestionsApiArg = {
  queryAddress: string
}
export type GetListOfAddressSuggestionsDeprecatedApiResponse =
  /** status 200 Request processed successfully */ DadataSuggestionResponse
export type GetListOfAddressSuggestionsDeprecatedApiArg = {
  queryAddress: QueryAddress
}
export type SavePaymentOperationResultApiResponse =
  /** status 204 Successfully save payment operation result */ void
export type SavePaymentOperationResultApiArg = {
  cashOnDeliveryLogsRequest: CashOnDeliveryLogsRequest
}
export type GetCalculatedDlvShippingTariffsApiResponse =
  /** status 202 Successful getting shipping tariffs */ ShipmentCalculationResponse
export type GetCalculatedDlvShippingTariffsApiArg = {
  partnerId?: string
  shipmentCalculationRequest: ShipmentCalculationRequest
}
export type GetCalculatedShipmentCostApiResponse =
  /** status 202 Successful getting cost for shipment */ ShipmentCostResponse
export type GetCalculatedShipmentCostApiArg = {
  partnerId?: string
  shipmentCostRequest: ShipmentCostRequest
}
export type GetListOfUserFilesApiResponse =
  /** status 200 Successful getting the list of files */ AttachmentResponse[]
export type GetListOfUserFilesApiArg = {
  /** Concrete attachment's recipient */
  model: string
  modelId: string
}
export type UploadFileApiResponse =
  /** status 201 Successful upload file */ AttachmentCreateResponse
export type UploadFileApiArg = {
  /** Concrete attachment's recipient */
  model: string
  /** Concrete recipient's ID */
  modelId: string
  body: {
    /** Attachment file */
    file: Blob
  }
}
export type DeleteFileApiResponse =
  /** status 204 Successful delete file */ void
export type DeleteFileApiArg = {
  /** Concrete attachment's recipient */
  model: string
  fileId: string
}
export type GetRoutingTaskResultApiResponse =
  /** status 200 Successful getting Yandex routing task */ YandexRoutingTaskCalculationResponse
export type GetRoutingTaskResultApiArg = {
  /** Id for yandex routing task */
  'yandex-routing-task-id': string
}
export type GetChildTasksApiResponse =
  /** status 200 Successful getting a list of yandex child tasks */ YandexChildTaskResponse[]
export type GetChildTasksApiArg = {
  /** Id for yandex parent task */
  'yandex-parent-task-id': string
}
export type GetVerificationInfoApiResponse =
  /** status 200 Successful getting a info about order on some date */ YandexVerificationInfoResponse[]
export type GetVerificationInfoApiArg = {
  date: string
  'yandex-order-number'?: string
  'yandex-depot-id'?: string
  'yandex-route-id'?: string
}
export type GetTrackingInfoApiResponse =
  /** status 200 Successful getting a yandex tracking info */ YandexTrackingInfoResponse
export type GetTrackingInfoApiArg = {
  /** Concrete yandex order ID */
  'yandex-order-id': string
}
export type GetOrderPhotosApiResponse =
  /** status 200 Successful getting a list of photo on the orders */ YandexGettingPhotoResponse[]
export type GetOrderPhotosApiArg = {
  page?: number
  'per-page'?: number
  'yandex-route-ids'?: number[]
  'yandex-order-ids'?: number[]
  'yandex-photo-ids'?: string[]
  'yandex-photo-tag'?: string
  'yandex-courier-id'?: string
}
export type GetOrdersInfoApiResponse =
  /** status 200 Successful getting a info about orders */ YandexOrderInfoResponse[]
export type GetOrdersInfoApiArg = {
  'yandex-route-id'?: string
  'yandex-order-number'?: string
  page?: number
  types?: string[]
}
export type GetOrderInfoApiResponse =
  /** status 200 Successful getting a yandex order info */ YandexOrderInfoResponse
export type GetOrderInfoApiArg = {
  'yandex-order-id': number
}
export type GetOrderNotificationsApiResponse =
  /** status 200 Successful getting notifications on the orders */ YandexOrderNotificationResponse[]
export type GetOrderNotificationsApiArg = {
  'from-date': string
  'to-date': string
  page?: number
}
export type GetListOwWarehousesApiResponse =
  /** status 200 Successful getting a list of the warehouses */ CommonCollectionResponsePartnerPartnerWarehouseResponse
export type GetListOwWarehousesApiArg = {
  partnerId?: string
  query?: string
  currentPage?: number
  perPage?: number
}
export type GetListOfVehiclesApiResponse =
  /** status 200 Successful getting a list of the vehicles */ CommonCollectionResponseVehicleResponse
export type GetListOfVehiclesApiArg = {
  currentPage?: number
  perPage?: number
  isActive?: boolean
}
export type GetV1UsersApiResponse =
  /** status 200 Successfully getting  all users by the partner id */ CommonCollectionResponseUserResponse
export type GetV1UsersApiArg = {
  partnerId?: string
  currentPage?: number
  perPage?: number
}
export type GetListOfShipmentsApiResponse =
  /** status 200 Successful getting a list of the shipments */ CommonCollectionResponseShipmentLightweightShipment
export type GetListOfShipmentsApiArg = {
  partnerId?: string
  partnerIds?: string[]
  deliveryServiceIds?: string[]
  query?: string
  status?: ShipmentStatus[]
  type?: ShipmentType[]
  createdDateFrom?: string
  createdDateTo?: string
  updatedDateFrom?: string
  updatedDateTo?: string
  plannedDeliveryDateFrom?: string
  plannedDeliveryDateTo?: string
  runRequestDeliveryDate?: string
  isExternal?: boolean
  isCashOnDelivery?: boolean
  isPaid?: boolean
  orderBy?: string
  order?: Direction
  currentPage?: number
  perPage?: number
}
export type GetBarcodeStickersApiResponse =
  /** status 200 Successful getting barcode stickers PDF-file */ void
export type GetBarcodeStickersApiArg = {
  /** Concrete ordinal number of shipment */
  shipmentOrdinalNumber: string
  partnerId?: string
}
export type GetBarcodeStickerApiResponse =
  /** status 200 Successful getting barcode sticker PDF-file */ void
export type GetBarcodeStickerApiArg = {
  /** Concrete ordinal number of shipment */
  shipmentOrdinalNumber: string
  /** Barcode number */
  barcode: string
  partnerId?: string
}
export type GetListOfRunRequestsApiResponse =
  /** status 200 Successful getting a list of the run-requests */ CommonCollectionResponseRunRequestLightweightRunRequest
export type GetListOfRunRequestsApiArg = {
  partnerIds?: string[]
  query?: string
  status?: RunRequestStatus[]
  type?: RunRequestType[]
  createdDateFrom?: string
  createdDateTo?: string
  updatedDateFrom?: string
  updatedDateTo?: string
  arrivalDateFrom?: string
  arrivalDateTo?: string
  deliveryServiceId?: string
  orderBy?: RunRequestOrderBy
  order?: Direction
  currentPage?: number
  perPage?: number
}
export type DownloadConsignmentNoteApiResponse = unknown
export type DownloadConsignmentNoteApiArg = {
  /** Concrete run request ID */
  requestId: string
}
export type GetDeliveryListOnDateApiResponse = unknown
export type GetDeliveryListOnDateApiArg = {
  routeNumber?: string
  withRoutesAtDate?: string
  driverId?: string
}
export type GetListOfQubyWarehousesApiResponse =
  /** status 200 Successful getting a list of the Quby warehouses */ CommonCollectionResponseQubyWarehouseResponse
export type GetListOfQubyWarehousesApiArg = {
  currentPage?: number
  perPage?: number
}
export type GetListOfPartnersApiResponse =
  /** status 200 Successful getting a list of the partners */ CommonCollectionResponsePartnerPartnerResponse
export type GetListOfPartnersApiArg = {
  query?: string
  currentPage?: number
  perPage?: number
}
export type GetPartnerLogoApiResponse =
  /** status 200 Successful getting the list of files */ AttachmentResponse
export type GetPartnerLogoApiArg = {
  partnerId?: string
}
export type GetInfoAboutMeApiResponse =
  /** status 200 Successful receipt user ID and partnerId */ SelfInfoResponse
export type GetInfoAboutMeApiArg = void
export type GetListOfMarketplaceWarehousesApiResponse =
  /** status 200 Successful getting a list of the marketplace warehouses */ CommonCollectionResponseMarketplaceWarehouseResponse
export type GetListOfMarketplaceWarehousesApiArg = {
  query?: string
  currentPage?: number
  perPage?: number
  order?: Direction
  orderBy?: MarketplaceOrderBy
}
export type GetMarketplaceWarehouseByIdApiResponse =
  /** status 200 Successful getting a marketplace warehouse by Id */ MarketplaceWarehouseResponse
export type GetMarketplaceWarehouseByIdApiArg = {
  /** Concrete marketplace warehouse ID */
  'marketplace-id': string
}
export type GetListOfLocationsApiResponse =
  /** status 200 Successful getting a collection locations of one partner */ CommonCollectionResponseReceiverReceiverResponse
export type GetListOfLocationsApiArg = {
  /** You may search a location by his name */
  query?: string
  partnerId?: string
  orderBy?: LocationOrderBy
  order?: Direction
  currentPage?: number
  perPage?: number
}
export type GetAllInvoicesApiResponse =
  /** status 200 Successful getting invoice  */ CommonCollectionResponseInvoiceResponse
export type GetAllInvoicesApiArg = {
  query?: string
  partnerId?: string[]
  createdDateFrom?: string
  createdDateTo?: string
  invoiceStatus?: InvoiceStatus[]
  currentPage?: number
  perPage?: number
}
export type GetInvoiceByShipmentIdApiResponse =
  /** status 200 Successful getting invoice */ InvoiceResponse
export type GetInvoiceByShipmentIdApiArg = {
  shipmentId: string
}
export type VersionAppApiResponse = /** status 200 current version */ string
export type VersionAppApiArg = void
export type GetAppLogsApiResponse =
  /** status 200 Successful getting an logs file */ void
export type GetAppLogsApiArg = {
  from: string
  query?: string
  to: string
  level?: LoggerLevel[]
  logsLocation?: LogUploadLocation
  shipmentStatuses?: ShipmentStatus[]
  runRequestStatuses?: RunRequestStatus[]
}
export type DownloadInsuranceReportApiResponse = unknown
export type DownloadInsuranceReportApiArg = {
  date: string
}
export type DownloadShipmentExportFileApiResponse = unknown
export type DownloadShipmentExportFileApiArg = {
  partnerIds?: string[]
  status?: ShipmentStatus[]
  type?: ShipmentType[]
  createdDateFrom?: string
  createdDateTo?: string
  updatedDateFrom?: string
  updatedDateTo?: string
  desiredTimeFrom?: string
  desiredTimeTo?: string
  actualDateFrom?: string
  actualDateTo?: string
  invoiceId?: string
  desiredColumn: ShipmentExportColumn[]
}
export type DownloadServiceRegisterFileApiResponse = unknown
export type DownloadServiceRegisterFileApiArg = {
  partnerId: string
  to: string
}
export type DownloadRouteListOnDateApiResponse = unknown
export type DownloadRouteListOnDateApiArg = {
  routeNumber?: string
  withRoutesAtDate?: string
  driverId?: string
}
export type DownloadActOfAcceptanceDeliveryApiResponse =
  /** status 200 Successful act of acceptance delivery in XLSX-format */ void
export type DownloadActOfAcceptanceDeliveryApiArg = {
  runRequestId?: string
  shipmentIds?: string[]
}
export type GetReadableShipmentStatusesApiResponse =
  /** status 200 Successful get using enums and their readable description */ DescriptionEnumValue[]
export type GetReadableShipmentStatusesApiArg = void
export type GetListOfDriversApiResponse =
  /** status 200 Successful getting a list of the drivers */ CommonCollectionResponseDriverResponse
export type GetListOfDriversApiArg = {
  query?: string
  withRoutesAtDate?: string
  orderBy?: DriverOrderBy
  order?: Direction
  currentPage?: number
  perPage?: number
}
export type DownloadImportTemplateApiResponse = unknown
export type DownloadImportTemplateApiArg = void
export type GetDlvPrintFormApiResponse =
  /** status 200 Successful get DLV stickers file PDF */ void
export type GetDlvPrintFormApiArg = {
  shipmentId: string
}
export type GetListOfDeliveryServicesApiResponse =
  /** status 200 Successful getting a list of the delivery services */ CommonCollectionResponseDeliveryServiceResponse
export type GetListOfDeliveryServicesApiArg = {
  currentPage?: number
  perPage?: number
}
export type DownloadFileApiResponse =
  /** status 200 Successful download file */ void
export type DownloadFileApiArg = {
  fileId: string
}
export type DeletePartnerByIdApiResponse =
  /** status 204 Successful delete partner */ void
export type DeletePartnerByIdApiArg = {
  /** Concrete partner ID */
  'partner-id': string
}
export type Void = object
export type MetroData = {
  name?: string
  line?: string
  distance?: number
}
export type AddressOutputData = {
  /** Postal code */
  postalCode?: string
  country?: string
  countryIsoCode?: string
  federalDistrict?: string
  regionFiasId?: string
  regionKladrId?: string
  regionIsoCode?: string
  regionWithType?: string
  regionType?: string
  regionTypeFull?: string
  region?: string
  areaFiasId?: string
  areaKladrId?: string
  areaWithType?: string
  areaType?: string
  areaTypeFull?: string
  area?: string
  cityFiasId?: string
  cityKladrId?: string
  cityWithType?: string
  cityType?: string
  cityTypeFull?: string
  city?: string
  cityArea?: string
  cityDistrictFiasId?: string
  cityDistrictKladrId?: string
  cityDistrictWithType?: string
  cityDistrictType?: string
  cityDistrictTypeFull?: string
  cityDistrict?: string
  settlementFiasId?: string
  settlementKladrId?: string
  settlementWithType?: string
  settlementType?: string
  settlementTypeFull?: string
  settlement?: string
  streetFiasId?: string
  streetKladrId?: string
  streetWithType?: string
  streetType?: string
  streetTypeFull?: string
  street?: string
  steadFiasId?: string
  steadCadnum?: string
  steadType?: string
  steadTypeFull?: string
  stead?: string
  houseFiasId?: string
  houseKladrId?: string
  houseCadnum?: string
  houseType?: string
  houseTypeFull?: string
  house?: string
  blockType?: string
  blockTypeFull?: string
  block?: string
  entrance?: string
  floor?: string
  flatFiasId?: string
  flatCadnum?: string
  flatType?: string
  flatTypeFull?: string
  flat?: string
  flatArea?: string
  squareMeterPrice?: string
  flatPrice?: string
  roomFiasId?: string
  roomCadnum?: string
  roomType?: string
  roomTypeFull?: string
  room?: string
  postalBox?: string | null
  fiasId?: string
  fiasCode?: string
  fiasLevel?: string | Void
  fiasActualityState?: string
  kladrId?: string
  geonameId?: string
  capitalMarker?: string
  okato?: string
  oktmo?: string
  taxOffice?: string
  taxOfficeLegal?: string
  timezone?: string
  latitude?: string
  longitude?: string
  beltwayHit?: string
  beltwayDistance?: string
  metro?: MetroData[]
  divisions?: string
  qcGeo?: string
  qcComplete?: string
  qcHouse?: string
  history_values?: object[]
  unparsedParts?: object[]
  source?: string
  qc?: string
}
export type AddressResponse = {
  id: string
  /** Short address */
  value: string
  /** Detailed address */
  unrestrictedValue: string
  data: AddressOutputData
}
export type PartnerPartnerWarehouseResponse = {
  /** Partner warehouse ID */
  id: string
  /** Warehouse name */
  warehouseName: string
  /** Phone number */
  phoneNumber?: string
  /** Left border of arrival time interval of the working time of warehouse */
  workingTimeFrom: string
  /** Right border of arrival time interval of the working time of warehouse */
  workingTimeTo: string
  address?: AddressResponse
}
export type ErrorItem = {
  message?: string
}
export type ErrorMessage = {
  error?: ErrorItem[]
}
export type InputAddressData = {
  /** Postal code */
  postalCode?: string
  country?: string
  countryIsoCode?: string
  federalDistrict?: string
  regionFiasId?: string
  regionKladrId?: string
  regionIsoCode?: string
  regionWithType?: string
  regionType?: string
  regionTypeFull?: string
  region?: string
  areaFiasId?: string
  areaKladrId?: string
  areaWithType?: string
  areaType?: string
  areaTypeFull?: string
  area?: string
  cityFiasId?: string
  cityKladrId?: string
  cityWithType?: string
  cityType?: string
  cityTypeFull?: string
  city?: string
  cityArea?: string
  cityDistrictFiasId?: string
  cityDistrictKladrId?: string
  cityDistrictWithType?: string
  cityDistrictType?: string
  cityDistrictTypeFull?: string
  cityDistrict?: string
  settlementFiasId?: string
  settlementKladrId?: string
  settlementWithType?: string
  settlementType?: string
  settlementTypeFull?: string
  settlement?: string
  streetFiasId?: string
  streetKladrId?: string
  streetWithType?: string
  streetType?: string
  streetTypeFull?: string
  street?: string
  steadFiasId?: string
  steadCadnum?: string
  steadType?: string
  steadTypeFull?: string
  stead?: string
  houseFiasId?: string
  houseKladrId?: string
  houseCadnum?: string
  houseType?: string
  houseTypeFull?: string
  house?: string
  blockType?: string
  blockTypeFull?: string
  block?: string
  entrance?: string
  floor?: string
  flatFiasId?: string
  flatCadnum?: string
  flatType?: string
  flatTypeFull?: string
  flat?: string
  flatArea?: string
  squareMeterPrice?: string
  flatPrice?: string
  roomFiasId?: string
  roomCadnum?: string
  roomType?: string
  roomTypeFull?: string
  room?: string
  postalBox?: string | null
  fiasId?: string
  fiasCode?: string
  fiasLevel?: string | Void
  fiasActualityState?: string
  kladrId?: string
  geonameId?: string
  capitalMarker?: string
  okato?: string
  oktmo?: string
  taxOffice?: string
  taxOfficeLegal?: string
  timezone?: string
  latitude?: string
  longitude?: string
  beltwayHit?: string
  beltwayDistance?: string
  metro?: MetroData[]
  divisions?: string
  qcGeo?: string
  qcComplete?: string
  qcHouse?: string
  history_values?: object[]
  unparsedParts?: object[]
  source?: string
  qc?: string
}
export type AddressRequest = {
  /** Short address */
  value: string
  /** Detailed address */
  unrestrictedValue: string
  data: InputAddressData
}
export type PartnerWarehouseRequest = {
  phoneNumber?: string
  warehouseName: string
  /** Left border of arrival time interval of the working time of warehouse */
  workingTimeFrom: string
  /** Left border of arrival time interval of working time of warehouse */
  workingTimeTo: string
  address: AddressRequest
}
export type Volume = {
  height?: number
  width?: number
  depth?: number
}
export type Capacity = {
  weight?: number
  volume?: Volume
}
export type VehicleResponse = {
  id?: string
  /** Brand of the car */
  brand: string
  /** Plate number of the car */
  plateNumber: string
  /** Description of the car */
  description?: string
  capacity: Capacity
  numberSts?: string
  numberVin?: string
  /** Creation date of the record of the vehicle */
  createdAt: string
  active?: boolean
}
export type VehicleUpdateRequest = {
  /** Brand of the car */
  brand?: string
  /** Plate number of the car */
  plateNumber?: string
  /** Description of the car */
  description?: string
  capacity?: Capacity
  numberSts?: string
  numberVin?: string
  active?: boolean
}
export type UserResponse = {
  id?: string
  email: string
  fullName: string
  tgChatId?: string
  tgUserId?: string
  partnerId?: string
}
export type UserUpdateRequest = {
  email: string
  fullName: string
  apiKey?: string
  tgChatId?: string
  tgUserId?: string
}
export type UserCreateRequest = {
  email: string
  password: string
  fullName: string
  tgChatId?: string
  tgUserId?: string
}
export type DeliveryServiceResponse = {
  id: string
  /** Delivery service name */
  name: string
  legalName?: string
  /** Delivery service phone */
  phoneNumber?: string
  /** Delivery service's url */
  url?: string
  dlvContractId?: string
  isActive: boolean
  createdDate: string
  createdBy: string
}
export type ShipmentStatus =
  | 'AT_SENDER_PARSE_ERROR'
  | 'AT_AGENT'
  | 'AT_SENDER_NEW'
  | 'AT_SENDER_PACKED'
  | 'AT_SENDER_AWAITING_SELF_PICKUP'
  | 'AT_SENDER_AWAITING_QUBY_PICKUP'
  | 'QUBY_VEHICLE_2_QUBY'
  | 'AT_QUBY_AWAITING_CONFIRMATION'
  | 'AT_QUBY_AWAITING_CORRECTION'
  | 'AT_QUBY_AWAITING_ROUTING'
  | 'AT_QUBY_AWAITING_DELIVERY'
  | 'QUBY_VEHICLE_2_RECEIVER'
  | 'RETURN_AT_QUBY_AWAITING_CONFIRMATION'
  | 'RETURN_AT_QUBY_AWAITING_RETURN'
  | 'RETURN_QUBY_VEHICLE_2_SENDER'
  | 'AT_RECEIVER_DONE'
  | 'RETURN_QUBY_VEHICLE_2_QUBY'
  | 'AT_SENDER_RETURNED'
  | 'AT_SENDER_CANCELLED'
  | 'AGENT_VEHICLE_2_AGENT'
  | 'AGENT_VEHICLE_2_RECEIVER'
  | 'AGENT_ERROR'
  | 'AT_AGENT_LOST'
  | 'RETURN_AGENT_VEHICLE_2_QUBY'
  | 'RETURN_AGENT_VEHICLE_2_SENDER'
export type ReasonCancelRunRequest =
  | 'DEFECTIVE_GOOD'
  | 'PARTITIAL_CONTENT'
  | 'DAMAGED_PARSEL'
  | 'NOT_SUITABLE_GOOD'
  | 'TOO_LONG_WAITING'
  | 'RECEIVER_NOT_ACCESSIBLE'
  | 'RECEIVER_RETHINK'
  | 'RECEIVER_CANCEL_DELIVERY'
export type ShipmentType =
  | 'UNKNOWN'
  | 'TO_THE_DOOR'
  | 'POSTAMAT'
  | 'FBO'
  | 'REVERSE_FBO'
  | 'DOOR_TO_DOOR'
  | 'FROM_THE_DOOR'
  | 'DOOR_WAREHOUSE_DOOR'
export type PackageType = 'BOX' | 'PALLET' | 'PILE' | 'DOCUMENTS' | 'UNKNOWN'
export type CargoPackageStatus = 'UNPROCESSED' | 'ACCEPTED' | 'DAMAGED' | 'LOST'
export type VatRate = 'VAT_20' | 'VAT_10' | 'VAT_0' | 'WITHOUT_VAT'
export type Good = {
  id?: string
  vendorCode?: string
  name?: string
  externalId?: string
  vatRate?: VatRate
  price?: number
  createDate?: string
  updateDate?: string
  selfRepresentation?: string
  logIdentifier?: string
  logName?: string
}
export type ShipmentCargoPackageResponseGoodsCargoPackagesResponse = {
  good?: Good
  amount?: number
  price?: number
}
export type ShipmentCargoPackageResponse = {
  /** ID of cargo package */
  id?: string
  packageType: PackageType
  status: CargoPackageStatus
  /** Packing width. Unit of measurement millimeter */
  width: number
  /** Packing height. Unit of measurement millimeter */
  height: number
  /** Packing length. Unit of measurement millimeter */
  length: number
  /** Weight of the package. Measurement unit is grams */
  weight: number
  /** Cost of the package. Measurement unit is rubles */
  estimatedCost?: number
  /** barcode of the cargo package. If not send, barcode will be auto created in format: number of partner-number of shipment-ordinal number cargo package in shipmentFor example, 001-001-001.Barcode must be unique in the shipment */
  barcode: string
  goods?: ShipmentCargoPackageResponseGoodsCargoPackagesResponse[]
}
export type GoodGoodResponse = {
  id: string
  vendorCode: string
  name: string
  externalId: string
  vatRate: VatRate
  price: number
  /** Creation time of the good */
  createdAt: string
  /** Update time of the good */
  updatedAt: string
}
export type GoodGoodSummaryResponse = {
  good: GoodGoodResponse
  /** Amount of the good */
  amount: number
  price: number
}
export type MarketplaceWarehouseResponse = {
  id: string
  marketplaceName: string
  /** Left border of arrival time interval of the working time of marketplace */
  workingTimeFrom: string
  /** Right border of arrival time interval of the working time of marketplace */
  workingTimeTo: string
  name: string
  address: AddressResponse
  costPallet?: number
  costBox?: number
  costMinimum?: number
}
export type ReceiverReceiverResponse = {
  id: string
  fullName: string
  phoneNumber: string
  additionalPhoneNumber?: string
  comment?: string
  address: AddressResponse
  floor: number
}
export type ShipmentLogResponse = {
  status: ShipmentStatus
  /** User comment */
  comment?: string
  /** Technical log for changed status */
  technicalLog: string
  /** Initiator of create/update log */
  updateSource: string
  /** Date of the log creation */
  createdDate: string
  createdBy: string
}
export type QubyWarehouseResponse = {
  id: string
  name: string
  phoneNumber?: string
  /** Left border of arrival time interval of the working time of warehouse */
  workingTimeFrom: string
  /** Left border of arrival time interval of  working time of warehouse */
  workingTimeTo: string
  address: AddressResponse
}
export type PartnerLightweightPartnerSettingsResponse = {
  /** Partner's settings ID */
  id: string
  defaultQubyWarehouse?: QubyWarehouseResponse
  defaultPartnerWarehouse?: PartnerPartnerWarehouseResponse
}
export type PartnerLightweightPartnerResponse = {
  id?: string
  legalForm?: string
  legalName?: string
  partnerSettings?: PartnerLightweightPartnerSettingsResponse
}
export type RunRequestType =
  | 'PICKUP'
  | 'LOCATION_PICKUP'
  | 'DELIVERY'
  | 'RETURN'
export type RunRequestStatus =
  | 'NEW'
  | 'CANCELLED'
  | 'IN_PROGRESS'
  | 'DONE'
  | 'ERROR_SETTING_STATUS'
  | 'ERROR'
export type CouplingRunRequestInfoResponse = {
  runRequestId?: string
  pairOfOrdinalNumber?: string
  type?: RunRequestType
  runRequestStatus?: RunRequestStatus
  showPrintExternalStickersMenu?: boolean
}
export type CouplingShipmentInfosResponse = {
  shipmentId: string
  combinedOrdinalNumber: string
}
export type AttachmentResponse = {
  id?: string
  fileName?: string
  filePath?: string
  createDate?: string
  updateDate?: string
}
export type ShipmentDlvSelectedServiceResponse = {
  service?: string
  price?: number
  label?: string
}
export type ShipmentAdditionalServices =
  | 'PICKUP_MANUAL_LIFT'
  | 'PICKUP_ELEVATOR_LIFT'
  | 'DELIVERY_MANUAL_LIFT'
  | 'DELIVERY_ELEVATOR_LIFT'
  | 'CARGO_INSURANCE'
  | 'CASH_ON_DELIVERY_CASH'
  | 'CASH_ON_DELIVERY_CARD'
  | 'DOCUMENTS_RETURN'
  | 'REVERSE_SHIPMENT'
  | 'SHIPMENT_CONFIRMATION'
  | 'PALLET_FORMATION'
  | 'PALLET_SELLING'
  | 'ONE_TIME_SERVICES'
  | 'SCANS'
  | 'CRATING'
export type SenderDestinationType =
  | 'FROM_WAREHOUSE'
  | 'FROM_ADDRESS'
  | 'SELF_DELIVERY'
  | 'FROM_WAREHOUSE_MARKETPLACE'
export type ReceiverDestinationType =
  | 'MY_WAREHOUSE'
  | 'EXACT_ADDRESS'
  | 'WAREHOUSE_MARKETPLACE'
export type LocalTime = {
  hour?: number
  minute?: number
  second?: number
  nano?: number
}
export type ShipmentArrivalInfoResponse = {
  arrivalDate?: string
  arrivalTimeFrom?: LocalTime
  arrivalTimeTo?: LocalTime
  arrivalComment?: string
}
export type ShipmentFullStructuredShipment = {
  id: string
  /** External shipment identifier */
  externalId?: string
  deliveryService?: DeliveryServiceResponse
  /** If shipment sent through external delivery service, field will be filled track number which assigned external DS */
  deliveryServiceTrackNumber?: string
  status: ShipmentStatus
  /** DLV contract ID. Used when shipment will be send through external delivery service.Assigned when shipment will be send to DLV service */
  dlvContractId?: string
  /** Shipment for pickup */
  pickupComment?: string
  /** Shipment for delivery */
  deliveryComment?: string
  /** Human-readable representation of shipment status */
  readableStatus: string
  reasonOfCancel?: ReasonCancelRunRequest
  /** Time when status was changed */
  changeStatusDate?: string
  type: ShipmentType
  /** Insurance sum of shipment */
  insuranceSum: number
  /** Ordinal number of the shipment for one partner */
  shipmentOrdinalNumber: number
  /** Ordinal number of the partner */
  partnerOrdinalNumber: number
  /** Delivery number of the shipment if chosen a delivery through FBO.Required for FBO shipment type */
  fboDeliveryNumber?: string
  /** Desired date of the delivery.Required for creation shipment if shipment type = FBO */
  deliveryDate?: string
  /** Desired left border of interval ot the time of the delivery.Required for creation shipment if shipment type = FB */
  deliveryTimeFrom: string
  /** Desired right border of interval ot the time of the delivery if chosen delivery through FBO */
  deliveryTimeTo: string
  /** Delivery costUsed when shipment will be send through external delivery service. */
  deliveryCost?: number
  /** Flag which means direct FBO shipping when shipment doesn't locate on Quby warehouse.Only for FBO shipping. */
  directRun?: boolean
  cargoPackages?: ShipmentCargoPackageResponse[]
  /** The goods in the shipment */
  goods?: GoodGoodSummaryResponse[]
  partnerWarehouse?: PartnerPartnerWarehouseResponse
  fboMarketplaceWarehouse?: MarketplaceWarehouseResponse
  receiver?: ReceiverReceiverResponse
  sender?: ReceiverReceiverResponse
  /** List of logs about shipment */
  logs: ShipmentLogResponse[]
  /** ID of the user which was create the shipment */
  userId: string
  partner?: PartnerLightweightPartnerResponse
  /** Information list about the coupling run requests */
  couplingRunRequestInfos?: CouplingRunRequestInfoResponse[]
  /** Pairs of return shipments */
  couplingShipmentInfos?: CouplingShipmentInfosResponse[]
  /** Shipment's files */
  attachments?: AttachmentResponse[]
  /** The date of creation of the shipment */
  createdAt: string
  /** Desired date of the pickup */
  pickupDate?: string
  /** Desired left border of interval ot the time of the pickup */
  pickupTimeFrom?: string
  /** Desired right border of interval ot the time of the pickup */
  pickupTimeTo?: string
  /** Additional services for tariffs */
  dlvSelectedServices?: ShipmentDlvSelectedServiceResponse[]
  /** Additional services for calculate tariffs */
  additionalServices?: ShipmentAdditionalServices[]
  senderDestinationType?: SenderDestinationType
  receiverDestinationType?: ReceiverDestinationType
  /** Shipment physical weight */
  physicalWeight?: number
  /** Shipment volume weight */
  volumeWeight?: number
  closedAt?: string
  arrivalInfo?: ShipmentArrivalInfoResponse
  qubyWarehouseId?: string
  cashOnDeliveryPaid?: boolean
  markedForCancel?: boolean
}
export type ShipmentGoodShipmentRequest = {
  /** Good ID. If exists, good will be extracted from database.Fields vendorCode,name,externalId,vatRate must be empty. */
  id?: string
  /** Vendor code of the good */
  vendorCode?: string
  /** Name of the good */
  name?: string
  /** Code of good in the partner system */
  externalId?: string
  vatRate?: VatRate
  /** Price of the good */
  price: number
  /** Amount of good */
  amount: number
}
export type LocationForShipment = {
  /** Existing receiver's ID */
  id?: string
  fullName?: string
  phoneNumber?: string
  additionalPhoneNumber?: string
  comment?: string
  address?: AddressRequest
  floor?: number
}
export type DlvSelectedServicesRequest = {
  service?: string
  price?: number
  label?: string
}
export type NotificationType = 'TELEGRAM' | 'EMAIL'
export type PartnerNotificationsDestinationsRequest = {
  type?: NotificationType
  address?: string
  recipients?: string[]
}
export type NotificationInfoRequest = {
  statuses?: string[]
  partnerNotificationsDestinationsRequest?: PartnerNotificationsDestinationsRequest[]
}
export type ShipmentCargoPackageUpdateRequest = {
  packageType: PackageType
  /** Packing width. Unit of measurement millimeter */
  width: number
  /** Packing width. Unit of measurement millimeter */
  height: number
  /** Packing width. Unit of measurement millimeter */
  length: number
  /** Weight of the package. Measurement unit is grams */
  weight: number
  /** A pair good and its amount in that cargo package */
  goods?: ShipmentGoodShipmentRequest[]
  /** Cost of the cargo package. Measurement unit is rubles */
  estimatedCost?: number
  /** barcode of the cargo package. If not send, barcode will be auto created in format: number of partner-number of shipment-ordinal number cargo package in shipmentFor example, 001-001-001.Barcode must be unique in the shipment */
  barcode?: string
  /** ID of cargo package */
  id?: string
  status?: PackageType
}
export type ShipmentShipmentUpdateRequest = {
  /** Shipment's id from an external service */
  externalId?: string
  /** Delivery service ID */
  deliveryServiceId?: string
  /** Delivery service track number */
  deliveryServiceTrackNumber?: string
  type?: ShipmentType
  /** Insurance sum of shipment. If not sent,value will be calculated automatically through sum of prices of the good,if goods exist.Otherwise, insurance sum will be equal the sum of estimated costs of cargo packages  */
  insuranceSum?: number
  status?: ShipmentStatus
  reasonOfCancel?: ReasonCancelRunRequest
  /** "Delivery number of the shipment if chosen a delivery through FBO.Required for FBO shipment type
   */
  fboDeliveryNumber?: string
  /** Desired date of the delivery. Required for creation shipment if shipment type = FBO */
  deliveryDate?: string
  /** Desired left border of interval ot the time of the delivery.Required for creation shipment if shipment type = FB */
  deliveryTimeFrom?: string
  /** Desired right border of interval ot the time of the delivery. Required for creation shipment if shipment type = FB */
  deliveryTimeTo?: string
  /** DLV contract ID. Used when shipment will be send through external delivery service.Assigned when shipment will be send to DLV service */
  dlvContractId?: string
  /** Delivery cost. Used when shipment will be send through external delivery service. */
  deliveryCost?: number
  /** Shipment for pickup */
  pickupComment?: string
  /** Shipment for delivery */
  deliveryComment?: string
  /** Flag which means direct FBO shipping when shipment doesn't locate on Quby warehouse.Only for FBO shipping. */
  directRun?: boolean
  /** Cost of all goods which assigns explicitly */
  cashOnDelivery?: number
  /** If the value of the isCashOnDelivery field is 'true', the sum of the cost of all goods should
    be indicated in the cashOnDelivery field. Default value 'false'
     */
  isCashOnDelivery?: boolean
  goods?: ShipmentGoodShipmentRequest
  /** Id of the warehouse from which will be delivered a shipment */
  warehouseId?: string
  /** Id of the marketplace warehouse for which will be delivered a shipment if chosen delivery through FBO */
  fboMarketplaceWarehouseId?: string
  receiver?: LocationForShipment
  sender?: LocationForShipment
  /** Desired date of the pickup */
  pickupDate?: string
  /** Desired left border of interval ot the time of the pickup */
  pickupTimeFrom?: string
  /** Desired right border of interval ot the time of the pickup */
  pickupTimeTo?: string
  /** Additional services for tariffs */
  dlvSelectedServices?: DlvSelectedServicesRequest[]
  /** Additional services for calculate tariffs */
  additionalServices?: ShipmentAdditionalServices[]
  senderDestinationType?: SenderDestinationType
  receiverDestinationType?: ReceiverDestinationType
  notificationInfo?: NotificationInfoRequest
  /** List of cargo packages for updating operation */
  cargoPackages?: ShipmentCargoPackageUpdateRequest[]
  /** Reason why cash on delivery was changed */
  cashOnDeliveryChangeReason?: string
  markedForCancel?: boolean
}
export type ShipmentPatchInfoRequest = {
  status: ShipmentStatus
  /** Comment for the shipment log */
  comment: string
}
export type ShipmentCargoPackageAcceptRequest = {
  /** ID of cargo package */
  id: string
  packageType: PackageType
  /** Packing width. Unit of measurement millimeter */
  width: number
  /** Packing height. Unit of measurement millimeter */
  height: number
  /** Packing length. Unit of measurement millimeter */
  length: number
  /** Weight of the package. Measurement unit is grams */
  weight: number
  /** Cost of the package. Measurement unit is rubles */
  estimatedCost?: number
  status: CargoPackageStatus
}
export type ShipmentCargoPackagesAcceptRequest = {
  cargoPackages?: ShipmentCargoPackageAcceptRequest[]
}
export type UserUpdatePasswordRequest = {
  token?: string
  password: string
}
export type ArrivalMethod = 'QUBY_CAR' | 'SELF_DELIVERY' | 'DELIVERY_SERVICE'
export type CargoVolume = 'SMALL' | 'MEDIUM' | 'LARGE'
export type CargoType = 'BOX' | 'BULKY' | 'OVERSIZED'
export type DriverResponse = {
  id?: string
  /** Full name of driver */
  fullName: string
  /** Driver licence of driver */
  driverLicence: string
  phoneNumber?: string
  apiKey?: string
  createdAt: string
  /** ID of user which create this data */
  createdBy: string
  fired?: boolean
}
export type RunRequestLogResponse = {
  id: string
  status: RunRequestStatus
  /** Comment for changed status */
  comment: string
  /** Technical log for changed status */
  technicalLog?: string
  updateSource: string
  /** Creation log's date */
  createdDate: string
  /** User's ID */
  createdBy: string
}
export type RunRequestFullStructuredRequest = {
  /** Run request ID */
  id: string
  /** Ordinal number of run request for one partner */
  runRequestOrdinalNumber: number
  /** External ID from Yandex routing service */
  externalYandexRoutingTaskId?: string
  yandexOrderId?: number
  /** External ID from Yandex monitoring service */
  orderNumberFromYandexMonitoring?: string
  /** Ordinal number of the partner */
  partnerOrdinalNumber: number
  type: RunRequestType
  status: RunRequestStatus
  arrivalMethod: ArrivalMethod
  /** ID of delivery service */
  deliveryServiceId?: string
  deliveryServiceTrackNumber?: string
  deliveryService?: DeliveryServiceResponse
  /** Arrival date of the run request */
  arrivalDate: string
  /** Left border of arrival time interval of the run request */
  arrivalTimeFrom: string
  /** Right border of arrival time interval of the run request */
  arrivalTimeTo: string
  packageType?: PackageType
  cargoVolume?: CargoVolume
  /** Cargo number of pallets of the run request */
  cargoNumberOfPallets?: number
  cargoType?: CargoType
  /** Comment for run request */
  comment?: string
  destinationAddress: AddressResponse
  qubyWarehouse?: QubyWarehouseResponse
  partnerWarehouse?: PartnerPartnerWarehouseResponse
  /** ID of the user which was create the run request */
  user: string
  /** ID of the partner */
  partnerId: string
  partner?: PartnerLightweightPartnerResponse
  /** Route number which created transport system */
  routeNumber?: string
  driver?: DriverResponse
  vehicle?: VehicleResponse
  /** List of the run request's shipments */
  shipments?: ShipmentFullStructuredShipment[]
  /** List of the run request's files */
  attachments?: AttachmentResponse[]
  /** List of the run request's logs */
  logs: RunRequestLogResponse[]
  /** The date of creation of the run request */
  createdAt: string
  /** The date of update of the run request */
  updatedAt: string
  /** Field represents coupled run request.It's filled only for type DOOR_TO_DOOR and in creating moment
   */
  couplingRunRequestId?: string
  closedAt?: string
  showPrintExternalStickersMenu?: boolean
}
export type RunRequestUpdate = {
  type?: RunRequestType
  /** External ID from Yandex routing service */
  externalYandexId?: string
  /** ID of delivery service */
  deliveryServiceId?: string
  arrivalMethod?: ArrivalMethod
  /** Date when run request must be shipped */
  arrivalDate?: string
  arrivalTimeFrom?: LocalTime
  arrivalTimeTo?: LocalTime
  packageType?: PackageType
  cargoVolume?: CargoVolume
  /** CargoVolume. Required if run request is anonymous(without shipments) and CargoType has an OVERSIZED valueOtherwise, field must be empty */
  cargoNumberOfPallets?: number
  cargoType?: CargoType
  /** Comment for run request */
  comment?: string
  destinationAddress?: AddressRequest
  /** Quby warehouse's ID */
  qubyWarehouseId?: string
  /** Partner warehouse's ID. Used if a run request will delivery from some partner warehouse */
  partnerWarehouseId?: string
  /** Driver which will delivery a run request */
  driverId?: string
  /** Vehicle on which will be delivered the run request */
  vehicleId?: string
  /** Shipments for the run request */
  shipmentIds?: string[]
}
export type RunRequestPatch = {
  requestStatus: RunRequestStatus
  /** Comment for the run request log */
  comment: string
}
export type QubyWarehouseRequest = {
  name?: string
  phoneNumber?: string
  /** Left border of arrival time interval of the working time of warehouse */
  workingTimeFrom: string
  /** Left border of arrival time interval of  working time of warehouse */
  workingTimeTo: string
  address?: AddressRequest
}
export type TariffLevelLocalization = 'REGION' | 'SETTLEMENT' | 'CITY'
export type CostsForDeliveryOperationsResponse = {
  id?: string
  regionId?: string
  detalizationLocationId?: string
  minWeightIncluded?: number
  maxWeightExcluded?: number
  fixCost?: number
  perKiloCost?: number
}
export type CostsForLoadOperationsResponse = {
  id?: string
  minWeightIncluded?: number
  maxWeightExcluded?: number
  cost?: number
}
export type ExcelReportTemplate = 'ATEX' | 'QUBY'
export type DeliveryResponse = {
  id: string
  /** Name of external delivery service */
  serviceName: string
}
export type PartnerPartnerSettingsResponse = {
  /** Partner's settings ID */
  id: string
  shipmentAutoConfirmationEnabled?: boolean
  /** Value of an insurance */
  cargoInsuranceDefaultPercent: number
  cargoInsuranceRegionalPercent?: number
  returnPercent?: number
  shipmentConfirmationCost?: number
  cashOnDeliveryPercent?: number
  cashOnDeliveryCardPercent?: number
  volumeWeightValue?: number
  cashOnDeliveryMinCharge?: number
  documentReturningCost?: number
  isDeliveryServiceVisible?: boolean
  tariffLevelLocalization?: TariffLevelLocalization
  tariffDeliveries?: CostsForDeliveryOperationsResponse[]
  tariffCargoHandling?: CostsForLoadOperationsResponse[]
  /** Default locale */
  priorityLocale: string
  /** Partner demo status */
  demo?: boolean
  cargoType: CargoType
  packageType: PackageType
  defaultShipmentStatus: ShipmentStatus
  excelReportTemplate?: ExcelReportTemplate
  /** Excluded deliveries */
  excludeDeliveries?: DeliveryResponse[]
  defaultQubyWarehouse?: QubyWarehouseResponse
  defaultPartnerWarehouse?: PartnerPartnerWarehouseResponse
  /** Discount percent for tarification */
  discountPercent?: number
  documentReturnShipmentAutoCreation?: boolean
}
export type PartnerStatus = 'DEMO' | 'PENDING' | 'ACTIVE'
export type PartnerPartnerResponse = {
  id: string
  legalForm?: string
  legalName?: string
  legalAddress?: string
  actualAddress?: string
  phoneNumber?: string
  ordinalNumber?: number
  director?: string
  pathToLogo?: string
  inn?: string
  ogrn?: string
  kpp?: string
  okpo?: string
  bankName?: string
  bic?: string
  correspondentAccount?: string
  checkingAccount?: string
  contractNumber?: string
  contractDate?: string
  partnerSettings?: PartnerPartnerSettingsResponse
  partnerStatus?: PartnerStatus
  changedStatusDate?: string
  partnerWarehouses?: PartnerPartnerWarehouseResponse[]
  users?: string[]
  attachments?: AttachmentResponse[]
  qubySupportChat?: string
}
export type PartnerLegalForm = 'LLC' | 'SOLE_PROP'
export type PartnerPartnerRequest = {
  legalForm: PartnerLegalForm
  legalName: string
  legalAddress: string
  actualAddress: string
  phoneNumber: string
  director: string
  pathToLogo?: string
  inn: string
  ogrn: string
  kpp: string
  okpo: string
  bankName: string
  bic: string
  correspondentAccount: string
  checkingAccount: string
  contractNumber?: string
  contractDate?: string
  partnerStatus: string
  qubySupportChat?: string
}
export type Role =
  | 'USER'
  | 'PARTNER'
  | 'QUBY_WAREHOUSE_EMPLOYEE'
  | 'QUBY_MANAGER'
  | 'QUBY_DISPATCHER'
  | 'QUBY_DRIVER'
  | 'QUBY_ADMIN'
  | 'QUBY_SUPPORT'
export type RoleModel = {
  roleId?: string
  role?: Role
}
export type PartnerUserCreateRequest = {
  email: string
  password: string
  fullName: string
  tgChatId?: string
  tgUserId?: string
  /** Role model */
  roleModels?: RoleModel[]
}
export type ExcludedDeliveryServicesCreateRequest = {
  id?: string
  serviceName?: string
}
export type PartnerSettingsCreateRequest = {
  shipmentAutoConfirmationEnabled?: boolean
  cargoInsuranceDefaultPercent?: number
  cargoInsuranceRegionalPercent?: number
  returnPercent?: number
  shipmentConfirmationCost?: number
  cashOnDeliveryPercent?: number
  cashOnDeliveryCardPercent?: number
  documentReturningCost?: number
  volumeWeightValue?: number
  cashOnDeliveryMinCharge?: number
  isDeliveryServiceVisible?: boolean
  priorityLocale?: string
  demo?: boolean
  cargoType?: CargoType
  packageType?: PackageType
  defaultQubyWarehouseId?: string
  excelReportTemplate?: ExcelReportTemplate
  defaultShipmentStatus?: ShipmentStatus
  tariffLevelLocalization?: TariffLevelLocalization
  isVolumeWeightEnabled?: boolean
  isCargoPackageBasedLoadOperations?: boolean
  discountPercent?: number
  documentReturnShipmentAutoCreation?: boolean
  excludedDeliveries?: ExcludedDeliveryServicesCreateRequest[]
  notifyEmail?: string
}
export type PartnerWarehouseCreateRequest = {
  warehouseName: string
  address?: AddressRequest
  phoneNumber: string
  workingTimeFrom?: string
  workingTimeTo?: string
  defaultWarehouse?: boolean
}
export type PartnerCreatePartnerRequest = {
  legalForm: PartnerLegalForm
  legalName: string
  legalAddress: string
  actualAddress: string
  phoneNumber: string
  director: string
  pathToLogo?: string
  inn?: string
  ogrn?: string
  kpp?: string
  okpo?: string
  bankName: string
  bic?: string
  correspondentAccount?: string
  checkingAccount?: string
  contractNumber?: string
  /** Date of signing the contract */
  contractDate?: string
  partnerStatus: string
  /** Users */
  users: PartnerUserCreateRequest[]
  partnerSettings: PartnerSettingsCreateRequest
  partnerWarehouses: PartnerWarehouseCreateRequest[]
  qubySupportChat?: string
}
export type DeliveryRequest = {
  /** Name of external delivery service */
  serviceName: string
}
export type CostsForDeliveryOperationsRequest = {
  /** Fias of region */
  regionId: string
  /** Fias of city or settlement */
  detalizationLocationId?: string
  /** Left border of weight range inclusively */
  minWeightIncluded?: number
  /** Right border of weight range exclusively.May be null. */
  maxWeightExcluded?: number
  /** Minimal cost for the weight range */
  fixCost?: number
  /** Cost step for 1 kg over a min weight in the range */
  perKiloCost?: number
}
export type CostsForLoadOperationsRequest = {
  minWeightIncluded?: number
  maxWeightExcluded?: number
  cost?: number
}
export type PartnerPartnerSettingsRequest = {
  shipmentAutoConfirmationEnabled?: boolean
  /** Value of default insurance percent */
  cargoInsuranceDefaultPercent?: number
  cargoInsuranceRegionalPercent?: number
  returnPercent?: number
  shipmentConfirmationCost?: number
  cashOnDeliveryPercent?: number
  cashOnDeliveryCardPercent?: number
  documentReturningCost?: number
  volumeWeightValue?: number
  cashOnDeliveryMinCharge?: number
  isDeliveryServiceVisible?: boolean
  /** Default locale */
  priorityLocale?: string
  /** Is a partner has demo status */
  demo?: boolean
  cargoType: CargoType
  packageType: PackageType
  defaultShipmentStatus: ShipmentStatus
  /** Excluded deliveries */
  excludeDeliveries?: DeliveryRequest[]
  /** Default partner warehouse */
  defaultPartnerWarehouseId?: string
  /** Default QUBY warehouse */
  defaultQubyWarehouseId?: string
  tariffLevelLocalization?: TariffLevelLocalization
  excelReportTemplate?: ExcelReportTemplate
  tariffDeliveries?: CostsForDeliveryOperationsRequest[]
  tariffCargoHandling?: CostsForLoadOperationsRequest[]
  isVolumeWeightEnabled?: boolean
  isCargoPackageBasedLoadOperations?: boolean
  discountPercent?: number
  documentReturnShipmentAutoCreation?: boolean
}
export type MarketplaceWarehouseRequest = {
  marketplaceName: string
  name: string
  /** Left border of arrival time interval of the working time of marketplace */
  workingTimeFrom: string
  /** Left border of arrival time interval of  working time of marketplace */
  workingTimeTo: string
  address: AddressRequest
  costPallet?: number
  costBox?: number
  costMinimum?: number
}
export type LocationRequest = {
  fullName: string
  phoneNumber?: string
  additionalPhoneNumber?: string
  comment?: string
  address: AddressRequest
  floor?: number
}
export type GoodGoodRequest = {
  /** Vendor code of good */
  vendorCode?: string
  /** Name of good */
  name: string
  /** Code of good in partner system */
  externalId?: string
  vatRate?: VatRate
  /** Price of good */
  price: number
}
export type DriverUpdateRequest = {
  /** Full name of driver */
  fullName?: string
  /** Driver licence of driver */
  driverLicence?: string
  phoneNumber: string
  fired?: boolean
}
export type DeliveryServiceRequest = {
  name: string
  phoneNumber?: string
  url?: string
  dlvContractId?: string
  isActive?: boolean
}
export type YandexStatusResponse = {
  /** The task is queued for execution */
  queued?: string
  /** Task execution started */
  started?: string
  matrixDownloaded?: string
  calculated?: string
  /** The task completed successfully or with an error */
  completed?: string
  /** The task was canceled by the user or the system due to an error or timeout */
  cancelled?: string
  /** Estimated time to solve the problem. The score may change during the decision process. The estimate is correct approximately 75% of the time (problems are solved 5-15 seconds before the estimate). For some problems, the actual solution time may be longer than estimated (usually no more than 1 minute) */
  estimate?: string
}
export type YandexRoutingTaskResponse = {
  /** Task id for routing received from Yandex */
  yandexRoutingTaskId?: string
  message?: string
  status?: YandexStatusResponse
}
export type YandexRoutingTaskLocationRequest = {
  runRequestId: string
  /** Required vehicle properties */
  requiredTags?: string[]
}
export type YandexRoutingTaskDepotRequest = {
  /** Default value false */
  flexibleStartTime?: boolean
  /** Default value true */
  hardWindow?: boolean
  /** Default value 1800 seconds */
  serviceDurationS?: number
}
export type YandexRoutingTaskOptionsRequest = {
  /** Date of calculation of the routing request */
  date: string
  /** Default value 'true' */
  hardWindow?: boolean
  /** Service time at the recipient. Default value 1200 sec */
  sharedServiceDurationS?: number
}
export type YandexRoutingTaskVehicleRequest = {
  vehicleId: string
  driverId: string
  /** Amount of routes of the driver per a day */
  maxRuns: number
  returnToDepot: boolean
  /** Work shift end time */
  shiftTimeFrom: string
  /** Work shift start time */
  shiftTimeTo: string
  /** Vehicle properties */
  tags?: string[]
}
export type YandexRoutingTaskRequest = {
  routingTaskLocations: YandexRoutingTaskLocationRequest[]
  depot?: YandexRoutingTaskDepotRequest
  options: YandexRoutingTaskOptionsRequest
  vehicles: YandexRoutingTaskVehicleRequest[]
}
export type YandexRoutingTaskRequestWrite = {
  routingTaskLocations: YandexRoutingTaskLocationRequest[]
  depot?: YandexRoutingTaskDepotRequest
  options: YandexRoutingTaskOptionsRequest
  vehicles: YandexRoutingTaskVehicleRequest[]
  depotReadyTime?: string
  depotExpiringTime?: string
  deliveryDeadline?: string
}
export type VehicleCreateRequest = {
  /** Brand of the car */
  brand: string
  /** Plate number of the car */
  plateNumber: string
  /** Description of the car */
  description?: string
  capacity: Capacity
  numberSts?: string
  numberVin?: string
  active?: boolean
}
export type ShipmentExportResponse = {
  exportingResult: string
  createdShipments?: ShipmentFullStructuredShipment[]
  alreadyExistingShipments?: ShipmentFullStructuredShipment[]
}
export type ShipmentCargoPackageCreateRequest = {
  packageType: PackageType
  /** Packing width. Unit of measurement millimeter */
  width: number
  /** Packing width. Unit of measurement millimeter */
  height: number
  /** Packing width. Unit of measurement millimeter */
  length: number
  /** Weight of the package. Measurement unit is grams */
  weight: number
  /** A pair good and its amount in that cargo package */
  goods?: ShipmentGoodShipmentRequest[]
  /** Cost of the cargo package. Measurement unit is rubles */
  estimatedCost?: number
  /** barcode of the cargo package. If not send, barcode will be auto created in format: number of partner-number of shipment-ordinal number cargo package in shipmentFor example, 001-001-001.Barcode must be unique in the shipment */
  barcode?: string
}
export type ShipmentShipmentCreateRequest = {
  /** Shipment's id from an external service */
  externalId?: string
  /** Delivery service ID */
  deliveryServiceId?: string
  /** Delivery service track number */
  deliveryServiceTrackNumber?: string
  type?: ShipmentType
  /** Insurance sum of shipment. If not sent,value will be calculated automatically through sum of prices of the good,if goods exist.Otherwise, insurance sum will be equal the sum of estimated costs of cargo packages  */
  insuranceSum?: number
  status?: ShipmentStatus
  reasonOfCancel?: ReasonCancelRunRequest
  /** "Delivery number of the shipment if chosen a delivery through FBO.Required for FBO shipment type
   */
  fboDeliveryNumber?: string
  /** Desired date of the delivery. Required for creation shipment if shipment type = FBO */
  deliveryDate?: string
  /** Desired left border of interval ot the time of the delivery.Required for creation shipment if shipment type = FB */
  deliveryTimeFrom?: string
  /** Desired right border of interval ot the time of the delivery. Required for creation shipment if shipment type = FB */
  deliveryTimeTo?: string
  /** DLV contract ID. Used when shipment will be send through external delivery service.Assigned when shipment will be send to DLV service */
  dlvContractId?: string
  /** Delivery cost. Used when shipment will be send through external delivery service. */
  deliveryCost?: number
  /** Shipment for pickup */
  pickupComment?: string
  /** Shipment for delivery */
  deliveryComment?: string
  /** Flag which means direct FBO shipping when shipment doesn't locate on Quby warehouse.Only for FBO shipping. */
  directRun?: boolean
  /** Cost of all goods which assigns explicitly */
  cashOnDelivery?: number
  /** If the value of the isCashOnDelivery field is 'true', the sum of the cost of all goods should
    be indicated in the cashOnDelivery field. Default value 'false'
     */
  isCashOnDelivery?: boolean
  goods?: ShipmentGoodShipmentRequest
  /** Id of the warehouse from which will be delivered a shipment */
  warehouseId?: string
  /** Id of the marketplace warehouse for which will be delivered a shipment if chosen delivery through FBO */
  fboMarketplaceWarehouseId?: string
  receiver?: LocationForShipment
  sender?: LocationForShipment
  /** Desired date of the pickup */
  pickupDate?: string
  /** Desired left border of interval ot the time of the pickup */
  pickupTimeFrom?: string
  /** Desired right border of interval ot the time of the pickup */
  pickupTimeTo?: string
  /** Additional services for tariffs */
  dlvSelectedServices?: DlvSelectedServicesRequest[]
  /** Additional services for calculate tariffs */
  additionalServices?: ShipmentAdditionalServices[]
  senderDestinationType?: SenderDestinationType
  receiverDestinationType?: ReceiverDestinationType
  notificationInfo?: NotificationInfoRequest
  /** List of cargo packages for creating operation */
  cargoPackages: ShipmentCargoPackageCreateRequest[]
  markedForCancel?: boolean
}
export type RunRequestRoutingAggregationInfoRequest = {
  date: string
  /** Desired left border of interval ot the time of the delivery */
  timeFrom?: string
  /** Desired right border of interval ot the time of the delivery */
  timeTo?: string
  comment?: string
  destinationAddress: AddressRequest
}
export type RunRequestCreationForShipmentRequest = {
  pickup?: RunRequestRoutingAggregationInfoRequest
  delivery?: RunRequestRoutingAggregationInfoRequest
  /** Shipment's IDs. These shipments will be put in the run request */
  shipmentIds?: string[]
}
export type RunRequestCreate = {
  type: RunRequestType
  arrivalMethod?: ArrivalMethod
  /** ID of delivery service */
  deliveryServiceId?: string
  /** Date when run request must be shipped */
  arrivalDate: string
  /** Left border of arrival time interval of the run request */
  arrivalTimeFrom: string
  /** Right border of arrival time interval of the run request */
  arrivalTimeTo: string
  packageType?: PackageType
  cargoVolume?: CargoVolume
  /** CargoVolume. Required if run request is anonymous(without shipments) and CargoType has an OVERSIZED valueOtherwise, field must be empty */
  cargoNumberOfPallets?: number
  cargoType?: CargoType
  /** Comment for run request */
  comment?: string
  destinationAddress?: AddressRequest
  /** Quby warehouse's ID. */
  qubyWarehouseId?: string
  /** Partner warehouse's ID. Used if a run request will delivery from some partner warehouse */
  partnerWarehouseId?: string
  /** Shipment's IDs. These shipments will be put in the run request */
  shipmentIds?: string[]
  /** ID of driver. As usual, field is empty,because driver will be assigned from transport service */
  driverId?: string
  /** ID of vehicle. As usual, field is empty,because vehicle will be assigned from transport service */
  vehicleId?: string
}
export type RestorePasswordRequest = {
  email: string
}
export type UserRegisterResponse = {
  id?: string
  partnerId?: string
  apiKey?: string
}
export type UserRegisterRequest = {
  email: string
  password: string
}
export type RefreshTokenResponse = {
  type?: string
  accessToken: string
  refreshToken: string
}
export type RefreshTokenRequest = {
  refreshToken: string
}
export type CashOnDeliveryLogsType = 'PAYMENT' | 'REVERSE_PAYMENT'
export type CashOnDeliveryLogsStatus = 'SUCCESS' | 'FAIL'
export type CashOnDeliveryLogsRequest = {
  shipmentId: string
  operationType: CashOnDeliveryLogsType
  operationStatus: CashOnDeliveryLogsStatus
  responseBody?: string
}
export type UserTokensResponse = {
  accessToken: string
  refreshToken: string
  expiresAt?: number
  refreshExpiresAt?: number
}
export type UserLoginRequest = {
  email: string
  password: string
}
export type InvoiceStatus = 'NEW' | 'SENT' | 'PARTIALLY_PAID' | 'PAID'
export type InvoiceResponse = {
  id: string
  partnerId: string
  partnerName: string
  name?: string
  dateFrom: string
  dateTo: string
  sum: number
  status: InvoiceStatus
  amountPaid?: number
  comment?: string
  createdDate: string
  updatedDate: string
  createdBy: string
  updatedBy: string
  shipments?: ShipmentFullStructuredShipment[]
}
export type InvoiceCreationRequest = {
  partnerId: string
  from: string
  to: string
  comment?: string
  file: Blob
}
export type OrderBy = object
export type Meta = {
  currentPage?: number
  totalPages?: number
  perPage?: number
  count?: number
  orderBy?: OrderBy
}
export type CommonCollectionResponseGoodGoodResponse = {
  meta: Meta
  data?: GoodGoodResponse[]
}
export type GoodOrderBy =
  | 'EXTERNAL_ID'
  | 'NAME'
  | 'VENDOR_CODE'
  | 'PRICE'
  | 'UPDATED_DATE'
export type Direction = 'ASC' | 'DESC'
export type FakeRequest = {
  /** Email for the fake user */
  email?: string
  /** Password for the fake user */
  password: string
  /** Amount fake receivers for the fake partner */
  receiverAmount?: number
  /** Amount fake goods for the fake partner */
  goodAmount?: number
  /** Amount fake shipment for the fake partner */
  shipmentAmount?: number
}
export type DriverCreateRequest = {
  email: string
  password: string
  fullName: string
  driverLicence: string
  phoneNumber: string
}
export type DadataSuggestionResponse = {
  suggestions?: AddressResponse[]
}
export type QueryAddress = {
  /** москва хабар */
  query?: string
}
export type CourierServices = {
  service?: string
  price?: number
  label?: string
}
export type TariffsResponse = {
  avia?: boolean
  minDeliveryTime?: number
  maxDeliveryTime?: number
  selectedServicesPrice?: number
  courier?: string
  price?: number
  contractId?: string
  courierProduct?: string
  courierServices?: CourierServices[]
  paymentType?: string
  insurancePrice?: string
  pvz?: boolean
}
export type ShipmentCalculationResponse = {
  success?: boolean
  tariffs?: TariffsResponse[]
}
export type ShipmentCalculationRequestSenderRequest = {
  /** Optional field */
  zipcode?: number
  /** Optional field with default value = Россия */
  country?: string
  /** Optional field with default value = Москва */
  region?: string
  /** Optional field with default value = Москва */
  city?: string
  /** Sender field 'area' is indicated if it exists */
  area?: string
  /** Sender field 'settlement' is indicated if it exists */
  settlement?: string
  regionFiasId?: string
  cityFiasId?: string
  settlementFiasId?: string
  fiasId?: string
}
export type ShipmentCalculationRequestSenderRequestRead = {
  /** Optional field */
  zipcode?: number
  /** Optional field with default value = Россия */
  country?: string
  /** Optional field with default value = Москва */
  region?: string
  /** Optional field with default value = Москва */
  city?: string
  /** Sender field 'area' is indicated if it exists */
  area?: string
  /** Sender field 'settlement' is indicated if it exists */
  settlement?: string
  regionFiasId?: string
  cityFiasId?: string
  settlementFiasId?: string
  fiasId?: string
}
export type ShipmentCalculationRequestReceiverRequest = {
  zipcode?: number
  country?: string
  region?: string
  city?: string
  area?: string
  settlement?: string
  regionFiasId?: string
  cityFiasId?: string
  settlementFiasId?: string
  fiasId?: string
}
export type ShipmentCalculationRequestParamsRequest = {
  /** Document return option */
  docReturn?: boolean
  /** Option of delivery of the item directly to receiver hands */
  byHand?: boolean
  /** Your dlvLogin created by the method: Create a client account, details here:
    
    https://wiki.kak2c.ru/books/dlv-vnutrenniy/page/accountapi-avtorizatsiya-sozdanie-akkauntov-proektov
    
    default value = 'quby'
     */
  dlvLogin?: string
  /** Amount of insurance value */
  insuranceSum?: string
  /** Cash on delivery amount */
  codSum?: string
}
export type ShipmentCalculationRequestDimensionRequest = {
  weight?: number
  width?: number
  height?: number
  depth?: number
}
export type ShipmentCalculationRequest = {
  sender?: ShipmentCalculationRequestSenderRequest
  receiver?: ShipmentCalculationRequestReceiverRequest
  params: ShipmentCalculationRequestParamsRequest
  dimensions: ShipmentCalculationRequestDimensionRequest[]
  type?: ShipmentType
}
export type ShipmentCalculationRequestRead = {
  sender?: ShipmentCalculationRequestSenderRequestRead
  receiver?: ShipmentCalculationRequestReceiverRequest
  params: ShipmentCalculationRequestParamsRequest
  dimensions: ShipmentCalculationRequestDimensionRequest[]
  type?: ShipmentType
}
export type Interval = {
  from?: number
  to?: number
  cost?: number
  success?: boolean
}
export type AdditionalService = {
  percent?: number
  cost?: number
}
export type AdditionalServices = {
  cargoInsurance?: AdditionalService
  cashOnDelivery?: AdditionalService
  oversized?: AdditionalService
  documentsReturn?: AdditionalService
  shipmentConfirmation?: AdditionalService
  scans?: AdditionalService
  crating?: AdditionalService
}
export type ShipmentCostResponse = {
  weight?: number
  volume?: number
  volumeWeight?: number
  targetWeight?: number
  transportation?: Interval
  pickupLoadOperations?: Interval
  deliveryLoadOperations?: Interval
  additionalServices?: AdditionalServices
  totalExcludingVat?: number
  totalIncludingVat?: number
  vat?: number
  discountPercent?: number
}
export type CargoPackageCostRequest = {
  packageType: PackageType
  /** Packing width. Unit of measurement millimeter */
  width: number
  /** Packing width. Unit of measurement millimeter */
  height: number
  /** Packing width. Unit of measurement millimeter */
  length: number
  /** Weight of the package. Measurement unit is grams */
  weight: number
  /** Cost of the cargo package. Measurement unit is rubles */
  cargoPackageInsuranceSum?: number
}
export type ShipmentCostRequest = {
  shipmentType: ShipmentType
  senderId?: string
  receiverId?: string
  warehouseId?: string
  fboMarketplaceWarehouseId?: string
  cargoPackages: CargoPackageCostRequest[]
  additionalServices?: ShipmentAdditionalServices[]
}
export type AttachmentCreateResponse = {
  id?: string
  fileName?: string
  filePath?: string
  createdDate?: string
}
export type YandexPointResponse = {
  /** Latitude of the point of the delivery */
  lat?: number
  /** Longitude of the point of the delivery */
  lon?: number
}
export type YandexShipmentSizeVolumeType = 'bulk' | 'rigid' | 'fixed_bottom'
export type YandexVolumeResponse = {
  depthM?: number
  heightM?: number
  type?: YandexShipmentSizeVolumeType
  widthM?: number
}
export type YandexShipmentSizeResponse = {
  units?: number
  volume?: YandexVolumeResponse
  volumeCbm?: number
  /** Weight of the shipment */
  weightKg?: number
}
export type YandexTimeWindowResponse = {
  /** Allowed hard window to visit the destination, in the format [DD.]HH[:MM[:SS]] - [D.]HH[:MM[:SS]] or ISO 8601 */
  hardTimeWindow?: string
  /** Permitted window to visit the destination, in the format [D.]HH[:MM[:SS]] - [D.]HH[:MM[:SS]] or ISO 8601 */
  timeWindow?: string
}
export type YandexLocationType =
  | 'delivery'
  | 'pickup'
  | 'drop_off'
  | 'rest_place'
export type YandexDroppedLocationResponse = {
  /** Address of the location */
  address?: string
  /** Order may be a part of the multiorder */
  canBeMerged?: boolean
  /** Service duration at destination, shared with other orders from the same customer */
  clientServiceDurationS?: number
  /** Comments */
  comments?: string
  /** Explanation of the reason for deleting a destination from a route */
  dropReason?: string
  /** Permitted hard window to visit the destination, in the format [D.]HH[:MM[:SS]] - [D.]HH[:MM[:SS]] or ISO 8601 */
  hardTimeWindow?: string
  hardWindow?: boolean
  /** Recipient's phone number upon delivery */
  phone?: string
  point?: YandexPointResponse
  /** Footnote with information about the place */
  ref?: string
  sequenceOrder?: number
  /** Duration of service at the destination. */
  serviceDurationS?: number
  shipmentSize?: YandexShipmentSizeResponse
  /** Permitted window to visit the destination, in the format [D.]HH[:MM[:SS]] - [D.]HH[:MM[:SS]] or ISO 8601 */
  timeWindow?: string
  /** List of allowed time windows. Time windows cannot overlap.The timeWindow and timeWindows fields are mutually exclusive */
  timeWindows?: YandexTimeWindowResponse[]
  /** Name of the place */
  title?: string
  type?: YandexLocationType
}
export type StopLack = {
  fixed?: number
  perStop?: number
}
export type StopExcess = {
  fixed?: number
  perStop?: number
}
export type EarlyPenalty = {
  fixed?: number
  minute?: number
}
export type LatePenalty = {
  fixed?: number
  minute?: number
}
export type YandexPenaltyResponse = {
  stopLack?: StopLack
  stopExcess?: StopExcess
  early?: EarlyPenalty
  late?: LatePenalty
}
export type YandexRouteAttractionPointResponse = {
  /** Specifies the identifier of the destination */
  deliveryTo?: string
  /** List of tags that a car serving this destination should have */
  requiredTags?: string[]
  penalty?: YandexPenaltyResponse
}
export type YandexRouteType = 'depot' | 'location' | 'break'
export type YandexRouteNodeValueResponse = {
  /** Destination ID */
  id?: string
  /** Additional address service time */
  addedSharedServiceDurationS?: number
  /** Location address */
  address?: string
  /** The order can be part of a multi-order */
  canBeMerged?: boolean
  /** The order can be divided into parts between different machines */
  canBeSplit?: boolean
  /** Service duration at destination, shared with other orders from the same customer */
  clientServiceDurationS?: number
  /** Destination ID */
  finishServiceDurationS?: number
  /** If this parameter is set to true, the vehicle may start earlier or later than the default time */
  flexibleStartTime?: boolean
  /** Duration of service at destination */
  serviceDurationS?: number
  /** Comments */
  comments?: string
  /** Destination description */
  description?: string
  /** Recipient's phone number upon delivery */
  phone?: string
  /** List of order IDs picked up at cross-dock or warehouse */
  pickedOrders?: string[]
  point?: YandexPointResponse
  /** Footnote with information about the place */
  ref?: string
  /** Permitted window to visit the destination, in the format [D.]HH[:MM[:SS]] - [D.]HH[:MM[:SS]] or ISO 8601 */
  timeWindow?: string
  /** Place name */
  title?: string
  /** Total duration of service at destination */
  totalServiceDurationS?: number
  type?: YandexLocationType
}
export type YandexRouteNodeResponse = {
  type?: YandexRouteType
  usedProjection?: YandexPointResponse
  /** The window selected to visit the destination or warehouse, in the format [D.]HH[:MM[:SS]] - [D.]HH[:MM[:SS]] or ISO 8601 */
  usedTimeWindow?: string
  value?: YandexRouteNodeValueResponse
}
export type YandexRouteResponse = {
  /** Arrival time at destination */
  arrivalTimeS?: number
  /** Departure time from destination */
  departureTimeS?: number
  node?: YandexRouteNodeResponse
  /** Distance to destination, in meters */
  transitDistanceM?: number
  /** Travel time to destination, in seconds */
  transitDurationS?: number
  /** Waiting time, time spent at the destination after arrival and before the start of the time window */
  waitingDurationS?: number
}
export type YandexRouteSummaryResponse = {
  globalProximityAttractionPoint?: YandexRouteAttractionPointResponse
  /** Sequence of nodes that make up a route */
  route?: YandexRouteResponse[]
  /** Serial flight number of the vehicle */
  runNumber?: number
  /** Vehicle ID */
  vehicleId?: string
}
export type YandexSolverStatus = 'SOLVED' | 'PARTIAL_SOLVED' | 'UNFEASIBLE'
export type YandexVehicleShiftResponse = {
  /** Unique ID of the shift */
  id?: string
  /** Limits the possibility of violating time windows when planning routes */
  hardWindow?: boolean
  /** Maximum shift duration in seconds */
  maxDurationS?: number
  /** Permitted window to visit the destination, in the format [D.]HH[:MM[:SS]] - [D.]HH[:MM[:SS]] or ISO 8601 */
  timeWindow?: string
}
export type YandexVehicleCapacityResponse = {
  volume?: Volume
  /** Weight of the order in kg */
  weightKg?: number
}
export type YandexVehicleResponse = {
  /** ID of the vehicle */
  id?: string
  /** ID of the depot */
  depotId?: string
  /** Maximum number of vehicle trips in all shifts per day */
  maxRuns?: number
  /** Vehicle reference number */
  ref?: string
  /** If set to true, then the vehicle will return to the warehouse after the last item.Else the last trip and shift ends at the last destination of the route. */
  returnToDepot?: boolean
  /** List of transport shifts */
  shifts?: YandexVehicleShiftResponse[]
  capacity?: YandexVehicleCapacityResponse
  tags?: string[]
}
export type YandexRoutingTaskResultResponse = {
  /** List of destinations excluded (removed) from the route due to lack of vehicles or other
    violation of the specified restrictions
     */
  droppedLocations?: YandexDroppedLocationResponse[]
  /** List of the calculated routes */
  routes?: YandexRouteSummaryResponse[]
  solverStatus?: YandexSolverStatus
  /** List of vehicles available for route planning */
  vehicles?: YandexVehicleResponse[]
}
export type YandexRoutingTaskCalculationResponse = {
  /** Id of the routing task */
  yandexRoutingTaskId?: string
  /** Description of the state of the routing task */
  message?: string
  status?: YandexStatusResponse
  result?: YandexRoutingTaskResultResponse
}
export type YandexChildTaskResponse = {
  /** Time of creation of the task */
  addedAt?: string
  /** ID of the task */
  taskId?: string
}
export type StatusLogResponse = {
  point?: YandexPointResponse
  /** Status of the order */
  status?: string
  /** UNIX timestamp of the changing status time */
  timestamp?: string
}
export type YandexVerificationInfoResponse = {
  /** Address of the order's delivery point */
  address?: string
  /** Comments for the order */
  comments?: string
  /** The time in which the order was confirmed */
  confirmedAt?: string
  /** Courier name */
  courierName?: string
  /** Courier ID */
  courierNumber?: string
  /** Customer name */
  customerName?: string
  /** The time in which the order was delivered */
  deliveredAt?: string
  /** Depot ID in Yandex system */
  depotId?: string
  /** Depot ID in company system */
  depotNumber?: string
  /** Description of the order */
  description?: string
  /** Latitude of the point of the delivery */
  lat?: number
  /** Longitude of the point of the delivery */
  lon?: number
  /** Order ID in the Yandex system */
  orderId?: number
  /** Order ID in the company system */
  orderNumber?: string
  /** Phone of the receiver */
  phone?: string
  /** Order ID in the company system */
  routeNumber?: string
  /** List of changing statuses */
  statusLog?: StatusLogResponse[]
  /** Desired time window of the delivery */
  timeInterval?: string
}
export type YandexLocationUpdateAtResponse = {
  /** Time of the last known position of the courier in ISO 8601 format */
  text?: string
  /** Timestamp of the last known position of the courier */
  value?: string
}
export type YandexCourierResponse = {
  location?: YandexPointResponse
  locationUpdateAt?: YandexLocationUpdateAtResponse
  /** Name of the courier */
  name?: string
  /** ID of the courier */
  number?: string
}
export type YandexEstimatedDeliveryResponse = {
  /** Calculated time of the order delivery in ISO 8601 format */
  text?: string
  /** Calculated timestamp of the order delivery */
  value?: string
}
export type YandexTrackingInfoResponse = {
  courier?: YandexCourierResponse
  /** Current position of the order in the route */
  deliveryQueuePosition?: number
  estimatedDeliveryTime?: YandexEstimatedDeliveryResponse
  /** Current status of the order */
  status?: string
  /** Vehicle tracking URL */
  trackingUrl?: string
}
export type YandexGettingPhotoResponse = {
  /** Comment to the photo */
  comment?: string
  /** ID of the courier */
  courierId?: string
  /** Date and time of loading the photo in format ISO 8601 */
  createdAt?: string
  /** ID of the photo */
  id?: string
  /** Url where the photo's file was stored */
  link?: string
  /** Order ID in the Yandex system */
  orderId?: number
  point?: YandexPointResponse
  /** Route ID in the Yandex system */
  routeId?: number
  /** Photo's tags */
  tags?: string[]
  /** Time of guaranteed storage of information about the photo in seconds. */
  ttlS?: number
}
export type YandexOrderEvent =
  | 'ORDER_CREATED'
  | 'START'
  | 'ORDER_BECAME_NEXT'
  | 'STATUS_UPDATE'
  | 'INTERVAL_UPDATE'
  | 'ARRIVAL'
  | 'ORDER_VISIT'
  | 'DEPARTURE'
export type OrderHistoryPosition = {
  /** Latitude of the point */
  lat?: number
  /** Longitude of the point */
  lon?: number
  /** Time of the arrival in the point */
  time?: string
}
export type YandexOrderSourceInitiator = 'yandex' | 'app' | 'user_api'
export type SourceEvent = {
  initiator?: YandexOrderSourceInitiator
}
export type YandexOrderHistoryResponse = {
  event?: YandexOrderEvent
  position?: OrderHistoryPosition
  source?: SourceEvent
  /** Time of event in ISO 8601 format */
  time?: string
  /** Timestamp of event */
  timestamp?: string
  /** Value of the distance which used for marked order as finished. Only for event ARRIVAL, ORDER_VISIT, DEPARTURE */
  usedMarkDeliveredRadius?: number
}
export type YandexOrderStatus =
  | 'not_in_monitoring'
  | 'new'
  | 'confirmed'
  | 'finished'
  | 'partially_finished'
  | 'cancelled'
  | 'postponed'
export type YandexOrderStatusCommentsResponse = {
  /** Comment for the updating event for order status */
  comment?: string
  /** ID of the updating event for order status */
  id?: string
  status?: YandexOrderStatus
}
export type YandexPaymentType = 'cash' | 'card' | 'prepaid'
export type YandexStatusLogResponse = {
  point?: YandexPointResponse
  status?: YandexOrderStatus
  /** Time of the changing of the status */
  timestamp?: string
}
export type YandexOrderTimeWindowResponse = {
  /** Right border of the accessible time window */
  end?: string
  /** Left border of the accessible time window */
  start?: string
}
export type YandexOrderInfoResponse = {
  /** Address of the order's delivery point */
  address?: string
  /** Cost of the number. May be null */
  amount?: number
  /** Comments for the order */
  comments?: string
  /** The time in which the order was confirmed */
  confirmedAt?: string
  /** Customer name */
  customerName?: string
  /** The time in which the order was delivered */
  deliveredAt?: string
  /** Description of the order */
  description?: string
  /** History of tracking of the order */
  history?: YandexOrderHistoryResponse[]
  /** Order ID in the Yandex system */
  id?: number
  /** Latitude of the point of the delivery */
  lat?: number
  /** Longitude of the point of the delivery */
  lon?: number
  /** Number of order/depot in company system */
  number?: string
  /** History of comments for changing of the order statuses */
  orderStatusComments?: YandexOrderStatusCommentsResponse[]
  paymentType?: YandexPaymentType
  /** Phone of the receiver */
  phone?: string
  /** Latitude of the precising point of the delivery */
  refinedLat?: number
  /** Longitude of the precising point of the delivery */
  refinedLon?: number
  /** Route ID in the Yandex system */
  routeId?: number
  /** Estimated time spent by the courier to ship the goods to the recipient */
  serviceDurationS?: number
  /** Duration of service at the delivery point, which can be shared with other orders at the same location */
  sharedServiceDurationS?: number
  status?: YandexOrderStatus
  /** Current order status */
  statusLog?: YandexStatusLogResponse[]
  /** Desired time window for the delivery */
  timeInterval?: string
  timeWindow?: YandexOrderTimeWindowResponse
  /** Total order volume in m3 */
  volume?: number
  /** Total weight volume in kg */
  weight?: number
}
export type YandexOrderNotificationResponse = {
  /** Estimated time to destination at the time the notification was created, in ISO 8601 format */
  eta?: string
  /** ID of the notification */
  id?: string
  /** Number of order in Yandex system */
  orderId?: number
  /** Number of order in company system */
  orderNumber?: string
  /** Route ID in the Yandex system */
  routeId?: number
  /** Route ID in the company system */
  routeNumber?: string
  /** Time on the server at the time the notification object was created, in ISO 8601 format */
  time?: string
  /** Notification type. Possible values: shift_start, nearby */
  type?: string
  /** URL of the vehicle tracking widget. */
  widgetUrl?: string
}
export type CommonCollectionResponsePartnerPartnerWarehouseResponse = {
  meta: Meta
  data?: PartnerPartnerWarehouseResponse[]
}
export type CommonCollectionResponseVehicleResponse = {
  meta: Meta
  data?: VehicleResponse[]
}
export type CommonCollectionResponseUserResponse = {
  meta: Meta
  data?: UserResponse[]
}
export type ShipmentLightweightShipment = {
  id: string
  /** External shipment identifier */
  externalId?: string
  deliveryService?: DeliveryServiceResponse
  /** If shipment sent through external delivery service, field will be filled track number which assigned external DS */
  deliveryServiceTrackNumber?: string
  status: ShipmentStatus
  /** DLV contract ID. Used when shipment will be send through external delivery service.Assigned when shipment will be send to DLV service */
  dlvContractId?: string
  /** Comment for shipment */
  comment?: string
  /** Human-readable representation of shipment status */
  readableStatus: string
  /** Time when status was changed */
  changeStatusDate?: string
  type: ShipmentType
  /** Insurance sum of shipment */
  insuranceSum: number
  /** Ordinal number of the shipment for one partner */
  shipmentOrdinalNumber: number
  /** Ordinal number of the partner */
  partnerOrdinalNumber: number
  /** Delivery number of the shipment if chosen a delivery through FBO.Required for FBO shipment type */
  fboDeliveryNumber?: string
  /** Desired date of the delivery.Required for creation shipment if shipment type = FBO */
  deliveryDate?: string
  /** Desired left border of interval ot the time of the delivery.Required for creation shipment if shipment type = FB */
  deliveryTimeFrom: string
  /** Desired right border of interval ot the time of the delivery if chosen delivery through FBO */
  deliveryTimeTo: string
  cargoPackages?: ShipmentCargoPackageResponse[]
  /** The goods in the shipment */
  goods?: GoodGoodSummaryResponse[]
  partnerWarehouse?: PartnerPartnerWarehouseResponse
  /** Flag which means direct FBO shipping when shipment doesn't locate on Quby warehouse.Only for FBO shipping. */
  directRun?: boolean
  fboMarketplaceWarehouse?: MarketplaceWarehouseResponse
  receiver?: ReceiverReceiverResponse
  sender?: ReceiverReceiverResponse
  /** ID of the user which was create the shipment */
  userId: string
  partner?: PartnerLightweightPartnerResponse
  /** Information list about the coupling run requests */
  couplingRunRequestInfos?: CouplingRunRequestInfoResponse[]
  /** Shipment's files */
  attachments?: AttachmentResponse[]
  /** The date of creation of the shipment */
  createdAt: string
  /** Desired date of the pickup */
  pickupDate?: string
  /** Desired left border of interval ot the time of the pickup */
  pickupTimeFrom?: string
  /** Desired right border of interval ot the time of the pickup */
  pickupTimeTo?: string
  /** Pairs of return shipments */
  couplingShipmentInfos?: CouplingShipmentInfosResponse[]
  closedAt?: string
  /** Shipping cost */
  shippingCost?: number
  /** Shipment physical weight */
  physicalWeight?: number
}
export type CommonCollectionResponseShipmentLightweightShipment = {
  meta: Meta
  data?: ShipmentLightweightShipment[]
}
export type ShortShipmentResponse = {
  shipmentId?: string
  ordinalNumber?: string
}
export type RunRequestLightweightRunRequest = {
  /** Run request ID */
  id: string
  /** Ordinal number of run request for one partner */
  runRequestOrdinalNumber: number
  /** External ID from Yandex routing service */
  externalYandexRoutingTaskId?: string
  yandexOrderId?: number
  /** Name of delivery service */
  deliveryServiceId?: string
  deliveryServiceTrackNumber?: string
  deliveryService?: DeliveryServiceResponse
  /** External ID from Yandex monitoring service */
  orderNumberFromYandexMonitoring?: string
  /** Ordinal number of the partner */
  partnerOrdinalNumber: number
  type: RunRequestType
  status: RunRequestStatus
  arrivalMethod: ArrivalMethod
  /** Arrival date of the run request */
  arrivalDate: string
  /** Left border of arrival time interval of the run request */
  arrivalTimeFrom: string
  /** Right border of arrival time interval of the run request */
  arrivalTimeTo: string
  packageType?: PackageType
  cargoVolume?: CargoVolume
  /** Cargo number of pallets of the run request */
  cargoNumberOfPallets?: number
  cargoType?: CargoType
  /** Summary of all cargo package in the run request */
  cargoPackagesAmount?: number
  /** Comment for run request */
  comment?: string
  destinationAddress: AddressResponse
  qubyWarehouse?: QubyWarehouseResponse
  /** ID of the user which was create the run request */
  user: string
  /** ID of the partner */
  partnerId: string
  partner?: PartnerLightweightPartnerResponse
  /** Route number which created transport system */
  routeNumber?: string
  driver?: DriverResponse
  vehicle?: VehicleResponse
  /** List of the run request's shipment IDs */
  shipments?: ShortShipmentResponse[]
  /** List of the run request's files */
  attachments?: AttachmentResponse[]
  /** List of the run request's logs */
  logs: RunRequestLogResponse[]
  /** The date of creation of the run request */
  createdAt: string
  /** The date of update of the run request */
  updatedAt: string
  closedAt?: string
  showPrintExternalStickersMenu?: boolean
}
export type CommonCollectionResponseRunRequestLightweightRunRequest = {
  meta: Meta
  data?: RunRequestLightweightRunRequest[]
}
export type RunRequestOrderBy =
  | 'STATUS'
  | 'TYPE'
  | 'ORDINAL_NUMBER'
  | 'ARRIVAL_DATE'
  | 'ARRIVAL_METHOD'
  | 'UPDATED_DATE'
  | 'DELIVERY_SERVICE_ID'
export type CommonCollectionResponseQubyWarehouseResponse = {
  meta: Meta
  data?: QubyWarehouseResponse[]
}
export type CommonCollectionResponsePartnerPartnerResponse = {
  meta: Meta
  data?: PartnerPartnerResponse[]
}
export type SelfInfoResponse = {
  id?: string
  partnerId?: string
  email?: string
  roles?: Role[]
}
export type CommonCollectionResponseMarketplaceWarehouseResponse = {
  meta: Meta
  data?: MarketplaceWarehouseResponse[]
}
export type MarketplaceOrderBy = 'NAME'
export type CommonCollectionResponseReceiverReceiverResponse = {
  meta: Meta
  data?: ReceiverReceiverResponse[]
}
export type LocationOrderBy = 'FULL_NAME' | 'PHONE_NUMBER' | 'UPDATED_DATE'
export type CommonCollectionResponseInvoiceResponse = {
  meta: Meta
  data?: InvoiceResponse[]
}
export type LoggerLevel = 'INFO' | 'WARN' | 'ERROR'
export type LogUploadLocation = 'APP' | 'SHIPMENT' | 'RUN_REQUEST'
export type ShipmentExportColumn =
  | 'INNER_NUMBER'
  | 'PARTNER_NAME'
  | 'EXTERNAL_ID'
  | 'RECEIVER'
  | 'SENDER'
  | 'STATUS'
  | 'CREATED_DATE'
  | 'REASON_OF_CANCEL'
  | 'TYPE'
  | 'INSURANCE_SUM'
  | 'CASH_ON_DELIVERY_SUM'
  | 'CASH_ON_DELIVERY_PAY_DATE'
  | 'DELIVERY_SERVICE'
  | 'DELIVERY_SERVICE_TRACK_NUMBER'
  | 'INVOICE'
  | 'CARGO_PACKAGES_AMOUNT'
  | 'WEIGHT'
  | 'VOLUME_WEIGHT'
  | 'GOODS'
  | 'SHIPMENT_COMMENT'
  | 'DELIVERY_COST'
  | 'CARGO_PACKAGES_TYPE'
  | 'DESIRED_DELIVERY_INTERVAL'
  | 'PLANNED_DELIVERY_INTERVAL'
  | 'ACTUAL_DELIVERY_TIME'
  | 'DESIRED_PICKUP_INTERVAL'
  | 'PLANNED_PICKUP_INTERVAL'
  | 'ACTUAL_PICKUP_TIME'
  | 'RELATED_RUN_REQUESTS'
export type DescriptionEnumValue = {
  enumValue?: string
  readableDescription?: string
}
export type CommonCollectionResponseDriverResponse = {
  meta: Meta
  data?: DriverResponse[]
}
export type DriverOrderBy = 'UPDATED_DATE' | 'NAME'
export type CommonCollectionResponseDeliveryServiceResponse = {
  meta: Meta
  data?: DeliveryServiceResponse[]
}

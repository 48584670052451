import React from 'react'
import SnackbarProvider from '@/components/SnackbarProvider'
import Router from '../routes'
import GlobalStyle from '../styles/GlobalStyle'
import '../styles/fonts.css'
import '../styles/reset.css'
import { Favicon } from '@/components/atoms/Favicon/Favicon'
import { Helmet } from 'react-helmet-async'
import { AppUserProvider } from '@/_app/AppUser.provider'
import { AppUpdateIndicator } from '@/_app/AppUpdateIndicator'
import { AppUpdateProvider } from '@/_app/AppUpdate.provider'
import { AppProvider } from '@/_app/App.provider'
import { AppOfflineIndicator } from '@/_app/AppOfflineIndicator'

function App() {
  return (
    <div>
      <Helmet titleTemplate="Quby • %s"></Helmet>
      <Favicon />
      <GlobalStyle />
      <SnackbarProvider />
      <AppProvider>
        <AppUpdateProvider>
          <AppUserProvider>
            <AppOfflineIndicator />
            <AppUpdateIndicator />
            <Router />
          </AppUserProvider>
        </AppUpdateProvider>
      </AppProvider>
    </div>
  )
}

export default App

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import ru from './ru'
import * as process from 'process'

const resources = {
  ru: { translation: ru },
} as const

i18n
  .use(initReactI18next)
  .init({
    resources,
    debug: process.env.NODE_ENV !== 'production',
    lng: 'ru',
    fallbackLng: 'ru',
  })
  .then()

export { i18n }

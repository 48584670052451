import axios from 'axios'
// @ts-ignore
import { BASE_JAVA_API_URL } from '@/constants/env'
import Api from './api'

const api = new Api(axios, {
  baseURL: BASE_JAVA_API_URL,
  headers: {
    Accept: '*/*',
    'Content-Type': 'application/json',
  },
})

export * from './types'
export { api }
export default api

import { action, makeObservable, observable, runInAction } from 'mobx'
import api from '../services/api'
import { IResetPasswordReq, ISetPasswordReq } from '@/types/auth'
import { ICallback, IError } from '@/types/global'
import { parseJwt } from '@/utils/auth'
import errorHandler from '../utils/errorHandler'
import history from '../utils/history'
import { PartnerStore } from './partners.store'
import { AxiosError } from 'axios'
import { UserLoginRequest } from '@/api/generated/qubyApiJava'

export class AuthStore {
  @observable loading = false
  @observable isAuth = false
  @observable partnerId: string | undefined
  @observable partnerName: string | undefined
  @observable email?: string = ''
  partnerStore: PartnerStore

  constructor() {
    this.partnerStore = new PartnerStore()
    makeObservable(this)
  }

  isTokenExist = (tokenName: string) => Boolean(this.getToken(tokenName))

  setToken = (token: string, tokenName: string) => {
    try {
      if (token) {
        localStorage.setItem(tokenName, token)
      }
    } catch (error) {
      errorHandler(error as AxiosError)
    }
  }

  isNotExpireToken = (tokenName: string) => {
    const token = this.getToken(tokenName)
    if (token) {
      const parsedToken = parseJwt(token)
      return Boolean(
        parsedToken.exp && parsedToken.exp > Math.floor(Date.now() / 1000),
      )
    }
  }

  getToken = (tokenName: string) => {
    return localStorage.getItem(tokenName)
  }

  removeTokens = () => {
    try {
      localStorage.removeItem('access_token')
      localStorage.removeItem('refresh_token')

      api.deleteAuthToken()
      history.push('/auth')
    } catch (error) {
      // lol
    }
  }

  @action setAuth(accessToken: string, refreshToken: string) {
    runInAction(() => {
      this.isAuth = true

      api.setAuthToken(accessToken)

      this.setToken(accessToken, 'access_token')
      this.setToken(refreshToken, 'refresh_token')
    })
  }

  @action createUser = async (user: UserLoginRequest, callback?: ICallback) => {
    try {
      runInAction(() => {
        this.loading = true
      })
      await api.legacy.createUser(user)
      if (callback) {
        callback()
      }
    } catch (error) {
      errorHandler(error as IError)
    }
    runInAction(() => {
      this.loading = false
    })
  }

  @action setPassword = async (body: ISetPasswordReq, callback?: ICallback) => {
    try {
      runInAction(() => {
        this.loading = true
      })
      await api.legacy.setPassword(body)
      if (callback) callback()
    } catch (error) {
      errorHandler(error as AxiosError)
    }
    runInAction(() => {
      this.loading = false
    })
  }

  @action resetPassword = async (
    body: IResetPasswordReq,
    callback?: ICallback,
  ) => {
    try {
      runInAction(() => {
        this.loading = true
      })
      await api.legacy.resetPassword(body)
      if (callback) callback()
    } catch (error) {
      errorHandler(error as AxiosError)
    }
    runInAction(() => {
      this.loading = false
    })
  }
}

import React from 'react'
import { AnimatePresence, motion as m } from 'framer-motion'
import { useGlobalStores } from '@/stores/global.store'
import SnackNotification from './SnackNotification'
import { SnackBar } from './style'
import variants from './animation'
import { observer } from 'mobx-react-lite'

const SnackbarProvider = () => {
  const {
    notificationsStore: { list },
  } = useGlobalStores()

  return (
    <AnimatePresence mode="popLayout">
      <SnackBar layout="position" variants={variants.snackbar} as={m.div}>
        {list.map((notification, i) => (
          <SnackNotification key={`notification.id_${i}`} {...notification} />
        ))}
      </SnackBar>
    </AnimatePresence>
  )
}

export default observer(SnackbarProvider)

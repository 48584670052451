import compact from 'lodash/compact'

export default urlPath
type input = string | number | undefined | null

function urlPath(...args: input[]): string {
  const result = compact(args).map((urlPart, index) =>
    index ?
      String(urlPart).replace(/(^\/)|(\/$)/, '')
    : String(urlPart).replace(/\/$/, ''),
  )

  return result.join('/')
}

export function urlRootPath(...args: input[]): string {
  return `/${urlPath(...args)
    .replace(/(^\/)/, '')
    .replace(/(\/$)/, '')}`
}

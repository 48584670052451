import { useEffect } from 'react'
import * as process from 'process'

export const Favicon = () => {
  useEffect(() => {
    const nodeEnv = process.env.NODE_ENV
    const apiMode = process.env.REACT_APP_API_MODE
    if (nodeEnv === 'production' && apiMode === 'production') {
      return
    }
    if (apiMode === 'production') {
      document.body.style.background = 'red'
    }
    const canvasElement = document.createElement('canvas')
    canvasElement.width = 32
    canvasElement.height = 32
    const ctx = canvasElement?.getContext('2d')

    if (canvasElement && ctx) {
      const img = new Image()
      img.src = '/favicon-32x32.png'
      img.onload = function () {
        ctx.drawImage(img, 0, 0)
        ctx.fillStyle = 'rgba(0, 255, 0, .6)'
        ctx.fillRect(0, 26, apiMode === 'dev' ? 32 : 16, 32)
        if (nodeEnv === 'development') {
          if (apiMode === 'production' || apiMode === 'stage') {
            if (apiMode === 'production') {
              ctx.fillStyle = 'rgba(255, 0, 0, 1)'
            } else if (apiMode === 'stage') {
              ctx.fillStyle = 'rgba(255, 255, 0, 1)'
            }
            ctx.fillRect(16, 26, 16, 32)
          }
        } else {
          if (apiMode === 'dev') {
            ctx.fillStyle = 'rgba(0, 108, 255, 0.8)'
          } else if (apiMode === 'stage') {
            ctx.fillStyle = 'rgba(255, 255, 0, .6)'
          }
          ctx.fillRect(0, 26, 32, 32)
        }

        const link = document.querySelector<HTMLLinkElement>(
          'link[rel="shortcut icon"]',
        )
        if (link) {
          link.href = canvasElement.toDataURL('image/x-icon')
        }
      }
    }
  }, [])
  return null
}

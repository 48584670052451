import { createGlobalStyle } from 'styled-components'
import { colors } from '@/theme/colors'

const GlobalStyle = createGlobalStyle`

  body {
    background-color: ${colors.neutral['200']};
  }

  #root {
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr;
  }

  #filter {
    position: relative;
    z-index: 1;
  }

  #date-picker {
    z-index: 9999;
    position: relative;
  }


  svg {
    display: block;
  }

  .small-shadow {
    box-shadow: 0px 0px 20px rgba(154, 171, 215, 0.12);
  }

  .cursor-pointer {
    cursor: pointer;
    user-select: none;
  }

  .link {
    color: ${colors.accent[600]};
    text-underline-offset: 2px;
    transition: 0.3s ease;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`

export default GlobalStyle

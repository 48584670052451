import { qubyNav } from '@/navigation/qubyNav'

const LOGIN = qubyNav.login.PATH
const LOGOUT = qubyNav.logout.PATH

function safePath(path: string) {
  return path.replace(/^[^/]/, '/')
}

export const appUserDoRedirectToFromPage = () => {
  const params = new URLSearchParams(window.location.search)
  const redirectTo = params.get('redirectTo')
  if (redirectTo && redirectTo !== LOGIN) {
    window.location.href =
      window.location.origin + safePath(decodeURIComponent(redirectTo))
  } else {
    window.location.href = window.location.origin
  }
}

export const appUserDoRedirectToLogin = () => {
  const currentPathName = window.location.pathname
  const params = new URLSearchParams(window.location.search)
  const paramsRedirectTo = params.get('redirectTo')

  if (
    currentPathName &&
    currentPathName !== LOGIN &&
    currentPathName !== LOGOUT
  ) {
    window.location.href =
      window.location.origin +
      LOGIN +
      '?redirectTo=' +
      encodeURIComponent(currentPathName)
  } else {
    if (
      (currentPathName === LOGIN || currentPathName === LOGOUT) &&
      paramsRedirectTo
    ) {
      window.location.href =
        window.location.origin +
        LOGIN +
        '?redirectTo=' +
        encodeURIComponent(decodeURIComponent(paramsRedirectTo))
    } else {
      window.location.href = window.location.origin + LOGIN
    }
  }
}

import { createContext } from 'react'

export const AppContext = createContext<{
  isOnline: boolean
  isOffline: boolean
  isActiveWindow: boolean
  isPassiveWindow: boolean
}>({
  isOffline: true,
  isOnline: true,
  isActiveWindow: true,
  isPassiveWindow: true,
})
